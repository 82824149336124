import { useEffect, useState, useContext } from 'react';
import PropTypes from 'prop-types';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import Grid from '@mui/material/Grid';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import PublicIcon from '@mui/icons-material/Public';
import GroupIcon from '@mui/icons-material/Group';
import FormControl from '@mui/material/FormControl';
import { Context } from 'store/Store';
import { useQuery } from 'react-query';
import { STALE_TIME } from 'config';
import { useTranslation } from 'react-i18next';
import MDBox from '../../../components/MDBox';
import { fetchUserProfile } from '../../../api';

const VISIBILITY_FRIENDS = 'FRIENDS';
const VISIBILITY_ALL = 'ALL';

function PostChoice({ setVisibility, visibility }) {
  const { t } = useTranslation();
  const [friendCount, setFriendCount] = useState([]);
  const { state } = useContext(Context);
  const { user } = state;
  const { id: userId } = user;

  const { data } = useQuery(['profile', userId], () => fetchUserProfile(userId), {
    staleTime: STALE_TIME.ONE_HOUR,
  });

  const visibilityOptions = [
    {
      key: VISIBILITY_ALL,
      description: t('posts.visibility.all'),
    },
    {
      key: VISIBILITY_FRIENDS,
      description: t('posts.visibility.friends'),
    },
  ];

  useEffect(() => {
    if (data) {
      const approvedFriends = data.data.friends.filter((item) => item.approvedFriend === true);
      setFriendCount(approvedFriends.length);
    }
  }, [data]);

  const handleChange = (e) => {
    setVisibility(e.target.value);
  };
  return (
    <MDBox display="flex">
      <FormControl fullWidth display="flex" size="medium">
        <Select
          value={visibility}
          onChange={handleChange}
          display="flex"
          IconComponent={ExpandMoreIcon}
          sx={{
            '.MuiOutlinedInput-notchedOutline': {
              borderColor: '#6797B4',
              borderRadius: '8px',
            },
            '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
              borderColor: '#6797B4',
              borderRadius: '8px',
            },
            '&:hover .MuiOutlinedInput-notchedOutline': {
              borderColor: '#6797B4',
              borderRadius: '8px',
            },
            '.MuiSvgIcon-root ': {
              fill: '#6797B4 !important',
            },
          }}
        >
          <MenuItem disabled color="rgba(21, 36, 43, 0.5)" fontSize={16} fontWeight={400}>
            {t('posts.visibility.audience')}
          </MenuItem>
          {visibilityOptions.map((el) => (
            <MenuItem value={el.key} display="flex" key={el.key}>
              <Grid
                container
                display="flex"
                color={el.key === visibility ? '#6797B4' : '#15242B'}
                alignItems="center"
              >
                <Grid item md={1} xs={1} display="flex">
                  <MDBox
                    bgColor="rgba(21, 36, 43, 0.1)"
                    padding="10px"
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                    color={el.key === visibility ? '#6797B4' : '#15242B'}
                    borderRadius="50%"
                  >
                    {el.key === VISIBILITY_ALL ? (
                      <PublicIcon sx={{ fontWeight: 'bold', width: '20px', height: '20px' }} />
                    ) : (
                      <GroupIcon sx={{ fontWeight: 'bold', width: '20px', height: '20px' }} />
                    )}
                  </MDBox>
                </Grid>
                <Grid
                  item
                  md={10}
                  xs={10}
                  display="flex"
                  flexDirection="column"
                  alignItems="flex-start"
                  justifyContent="center"
                  pl={4}
                >
                  <MDBox
                    color={el.key === visibility ? '#6797B4' : '#15242B'}
                    fontSize={16}
                    fontWeight={el.key === visibility ? 600 : 400}
                  >
                    {el.description}
                  </MDBox>
                  <MDBox
                    display={el.key === VISIBILITY_FRIENDS ? 'flex' : 'none'}
                    color="rgba(0, 0, 0, 0.5)"
                    fontSize={12}
                  >
                    {t('posts.visibility.friends.count', { count: friendCount })}
                  </MDBox>
                </Grid>
              </Grid>
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </MDBox>
  );
}
PostChoice.defaultProps = {
  visibility: VISIBILITY_FRIENDS,
};

PostChoice.propTypes = {
  setVisibility: PropTypes.func.isRequired,
  visibility: PropTypes.oneOf([VISIBILITY_ALL, VISIBILITY_FRIENDS]),
};

export default PostChoice;
