import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import { Box, Typography } from '@mui/material';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';
import CircleIcon from '@mui/icons-material/Circle';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { fetchDailyGoal } from '../../../api';
import MDTypography from '../../../components/MDTypography';

const ImageBackground = {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
};

function DailyGoal({ user }) {
  const { t } = useTranslation();
  const [days, setDays] = useState([]);
  const doFetchDailyGoal = async () => {
    const response = await fetchDailyGoal(user.id);
    setDays(response.data);
  };

  useEffect(async () => {
    await doFetchDailyGoal();
  }, []);

  const renderDay = ({ status, month, day }) => {
    if (status === 'PASS') {
      return (
        <Grid item xs={4} sm={1} key={`${month}${day}`}>
          <Box sx={ImageBackground}>
            <CheckCircleIcon color="success" sx={{ fontSize: 40 }} />
            <Typography variant="h6" color="text">
              {day}
              /
              {month}
            </Typography>
          </Box>
        </Grid>
      );
    }

    if (status === 'FAIL') {
      return (
        <Grid item xs={4} sm={1} key={`${month}${day}`}>
          <Box sx={ImageBackground}>
            <CancelIcon color="error" sx={{ fontSize: 40 }} />
            <Typography variant="h6" color="text">
              {day}
              /
              {month}
            </Typography>
          </Box>
        </Grid>
      );
    }

    if (status === 'NA') {
      return (
        <Grid item xs={4} sm={1} key={`${month}${day}`}>
          <Box sx={ImageBackground}>
            <CircleIcon color="error" sx={{ fontSize: 40 }} />
            <Typography variant="h6" color="text">
              {day}
              /
              {month}
            </Typography>
          </Box>
        </Grid>
      );
    }

    return null;
  };

  return (
    <Box mb={2}>
      <Card>
        <Box pt={2} pl={2}>
          <MDTypography variant="h5">{t('statistics.dailyGoal.title')}</MDTypography>
        </Box>
        <Box padding={2}>
          <Grid container spacing={1} display="flex" justifyContent="space-between">
            {days.map((day) => renderDay(day))}
          </Grid>
        </Box>
      </Card>
    </Box>
  );
}

DailyGoal.propTypes = {
  user: PropTypes.objectOf(
    PropTypes.oneOfType([PropTypes.array, PropTypes.object, PropTypes.number, PropTypes.string, PropTypes.bool]),
  ).isRequired,
};

export default DailyGoal;
