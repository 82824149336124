import { useContext } from 'react';

import Grid from '@mui/material/Grid';
import MDBox from 'components/MDBox';
import Card from '@mui/material/Card';
import DashboardLayout from 'LayoutContainers/DashboardLayout';
import MDTypography from 'components/MDTypography';
import { useTranslation } from 'react-i18next';

import LanguageTimezone from 'pages/settings/languageTimezone';
import ChangePassword from 'pages/settings/changePassword';
import Notifications from 'pages/settings/notifications';
import Tracker from 'pages/settings/tracker';

import Footer from 'comps/Footer';
import { Context } from 'store/Store';
import { Avatar } from '@mui/material';
import { updateUser } from '../../api';
import { stringAvatar } from '../../utils';
import Header from '../header';

function Settings() {
  const { dispatch, state } = useContext(Context);
  const { user } = state;
  const { t, i18n } = useTranslation();
  const {
    firstName, lastName, fullName, singleSignOn, avatar, team,
  } = user;

  const savePassword = async (currentPassword, newPassword) => {
    try {
      const result = await updateUser({ currentPassword, newPassword });
      dispatch({ type: 'SET_USER', payload: result.data });
      return dispatch({
        type: 'SET_SUCCESS',
        payload: {
          title: t('settings.password.notification.success'),
          content: '',
        },
      });
    } catch (error) {
      return dispatch({
        type: 'SET_ERROR',
        payload: {
          title: t('settings.password.notification.error'),
          content: `${error.response.data.message}`,
        },
      });
    }
  };

  const saveLanguage = async (lang) => {
    const result = await updateUser({ language: lang.id });
    await i18n.changeLanguage(lang.id);
    dispatch({ type: 'SET_USER', payload: result.data });
    return dispatch({
      type: 'SET_SUCCESS',
      payload: {
        title: t('settings.language.notification.success'),
        content: '',
      },
    });
  };

  const saveTimezone = async (tz) => {
    const result = await updateUser({ timezone: tz.timezone });
    dispatch({ type: 'SET_USER', payload: result.data });
    return dispatch({
      type: 'SET_SUCCESS',
      payload: {
        title: t('settings.language.notification.success'),
        content: '',
      },
    });
  };

  const saveNotifications = async (data) => {
    const {
      receiveWeeklySummary,
      pushPostComment,
      pushPostLike,
      pushWorkoutComment,
      pushCommentLike,
      pushWorkoutLike,
      pushFriendRequest,
      pushFriendRequestApproved,
      pushChatNewMessage,
    } = data;
    const result = await updateUser({
      receiveWeeklySummary,
      pushPostComment,
      pushPostLike,
      pushWorkoutComment,
      pushCommentLike,
      pushWorkoutLike,
      pushFriendRequest,
      pushFriendRequestApproved,
      pushChatNewMessage,
    });
    dispatch({ type: 'SET_USER', payload: result.data });
    return dispatch({
      type: 'SET_SUCCESS',
      payload: {
        title: t('settings.notifications.notification.success'),
        content: '',
      },
    });
  };

  return (
    <DashboardLayout>
      <Header challenge={team.challenge} />
      <MDBox>
        <Grid container>
          <MDBox mb={3}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Card id="profile">
                  <MDBox p={2}>
                    <Grid container spacing={2} alignItems="center">
                      <Grid item>
                        <Avatar {...stringAvatar(fullName, 'xl')} src={avatar} />
                      </Grid>
                      <Grid item>
                        <MDBox height="100%" mt={0.5} lineHeight={1}>
                          <MDTypography variant="h5" fontWeight="medium">
                            {firstName}
                            {' '}
                            {lastName}
                          </MDTypography>
                          <MDTypography variant="button" color="text" fontWeight="medium">
                            {team.teamName}
                          </MDTypography>
                        </MDBox>
                      </Grid>
                    </Grid>
                  </MDBox>
                </Card>
              </Grid>
              <Grid item xs={12}>
                <Tracker user={user} />
              </Grid>
              <Grid item xs={12}>
                <LanguageTimezone
                  user={user}
                  onSaveLanguage={saveLanguage}
                  onSaveTimezone={saveTimezone}
                />
              </Grid>
              { !singleSignOn ? (
                <Grid item xs={12}>
                  <Card>
                    <MDBox pb={2} pt={3} pl={3} pr={3}>
                      <ChangePassword
                        onSavePassword={savePassword}
                      />
                    </MDBox>
                  </Card>
                </Grid>
              ) : null}
              <Grid item xs={12}>
                <Notifications
                  user={user}
                  onSubmit={saveNotifications}
                />
              </Grid>
            </Grid>
          </MDBox>
        </Grid>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}

export default Settings;
