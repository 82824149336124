import MDBox from 'components/MDBox';
import Card from '@mui/material/Card';
import { useEffect, useState } from 'react';
import Tooltip from '@mui/material/Tooltip';
import { fetchChallengeDistance } from 'api';
import aroundTheWorldImg from 'assets/challengize/images/achievements/around-the-world.png';
import everestClimbsImg from 'assets/challengize/images/achievements/everest.png';
import marathonsImg from 'assets/challengize/images/achievements/marathon.png';
import {
  Grid, Paper, Stack, Typography,
} from '@mui/material';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

const Line = {
  background: '#9A93BE',
  color: '#9A93BE',
  border: 0,
  height: '2px',
  width: '100%',
  marginTop: '6px',
};

const ImageBackground = {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  paddingTop: '10px',
  paddingLeft: '10px',
  paddingRight: '10px',
  backgroundColor: '#BAF5B5',
};

function TotalDistance({ user }) {
  const { t } = useTranslation();
  const [data, setData] = useState([]);

  const {
    lapsAroundTheWorld = 0, everestClimbs = 0, marathonRuns = 0, totalDistance = 0,
  } = data;

  const doFetchChallengeDistance = async (id) => {
    const response = await fetchChallengeDistance(id);
    setData(response.data);
  };

  useEffect(async () => {
    await doFetchChallengeDistance(user.team.challenge.id);
  }, [user]);

  return (
    <MDBox mb={2}>
      <Card style={{ backgroundColor: '#9A93BE' }}>
        <MDBox p={3}>
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            spacing={2}
          >
            <Typography variant="h5" color="#FFFFFF">{t('distance.title')}</Typography>
            <Typography variant="h5" color="#FFFFFF">
              {(totalDistance / 1000).toFixed(0)}
              {' '}
              km
            </Typography>
          </Stack>
          <Grid container spacing={1} mt={1}>
            <Grid item xs={12} md={4}>
              <Paper sx={ImageBackground}>
                <Tooltip placement="top" title={t('distance.world')}>
                  <img src={aroundTheWorldImg} width="100%" alt="Around the world" />
                </Tooltip>
                <hr style={Line} />
                <Typography variant="h4" color="#9A93BE">{lapsAroundTheWorld.toFixed(2)}</Typography>
              </Paper>
            </Grid>

            <Grid item xs={12} md={4}>
              <Paper sx={ImageBackground}>
                <Tooltip placement="top" title={t('distance.everest')}>
                  <img src={everestClimbsImg} width="100%" alt="Mount Everest" />
                </Tooltip>
                <hr style={Line} />
                <Typography variant="h4" color="#9A93BE">{everestClimbs}</Typography>
              </Paper>
            </Grid>

            <Grid item xs={12} md={4}>
              <Paper sx={ImageBackground}>
                <Tooltip placement="top" title={t('distance.marathon')}>
                  <img src={marathonsImg} width="100%" alt="Marathon" />
                </Tooltip>
                <hr style={Line} />
                <Typography variant="h4" color="#9A93BE">{marathonRuns}</Typography>
              </Paper>
            </Grid>

          </Grid>
        </MDBox>
      </Card>
    </MDBox>
  );
}

TotalDistance.propTypes = {
  user: PropTypes.objectOf(
    PropTypes.oneOfType([PropTypes.array, PropTypes.object, PropTypes.number, PropTypes.string, PropTypes.bool]),
  )
    .isRequired,
};

export default TotalDistance;
