/* eslint-disable react/jsx-no-duplicate-props */
import Stack from '@mui/material/Stack';
import { Controller } from 'react-hook-form';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import MDTypography from '../../../components/MDTypography';
import MDInput from '../../../components/MDInput';

function Distance({ workout, control, isDisabled }) {
  const { t } = useTranslation();
  const handleDistanceBlur = (value, onChange) => {
    if (!value) {
      return onChange(0);
    }
    if (Number.isNaN(value)) {
      return onChange(0);
    }
    const val = parseFloat(value, 10);
    if (val > 100) {
      return onChange(100);
    }
    if (val < 0) {
      return onChange(0);
    }
    return onChange(val);
  };

  return (
    <>
      <MDTypography variant="button" fontWeight="regular" color="text">{t('general.distance')}</MDTypography>
      <Stack direction="row" spacing={2}>
        <Controller
          name="distance"
          control={control}
          defaultValue={workout ? (workout.distance / 1000).toFixed(1) : 0}
          render={({
            field: { onChange, value },
            fieldState: { error, invalid },
          }) => (
            <MDInput
              helperText={invalid ? error.message : null}
              value={value}
              variant="outlined"
              onBlur={() => handleDistanceBlur(value, onChange)}
              onChange={(event) => {
                onChange(event.target.value);
              }}
              style={{ width: 150 }}
              inputProps={{
                min: 0,
                max: 100,
                type: 'number',
              }}
              label={t('km')}
              fullWidth
              error={invalid}
              disabled={isDisabled}
            />
          )}
        />
      </Stack>
    </>
  );
}
Distance.defaultProps = {
  workout: null,
  isDisabled: false,
};

Distance.propTypes = {
  workout: PropTypes.objectOf(
    PropTypes.oneOfType([PropTypes.array, PropTypes.object, PropTypes.number, PropTypes.string, PropTypes.bool]),
  ),
  control: PropTypes.oneOfType([PropTypes.object]).isRequired,
  isDisabled: PropTypes.bool,
};

export default Distance;
