import { useContext, useState } from 'react';
import MDBox from 'components/MDBox';
import Card from '@mui/material/Card';
import { styled } from '@mui/material/styles';
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';
import MuiAccordion from '@mui/material/Accordion';
import MuiAccordionSummary from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import Footer from 'comps/Footer';
import { useTranslation } from 'react-i18next';
import MDTypography from '../components/MDTypography';
import DashboardLayout from '../LayoutContainers/DashboardLayout';
import Header from './header';
import { Context } from '../store/Store';

const Accordion = styled((props) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  border: `1px solid ${theme.palette.divider}`,
  '&:not(:last-child)': {
    borderBottom: 0,
  },
  '&:before': {
    display: 'none',
  },
}));

const AccordionSummary = styled((props) => (
  <MuiAccordionSummary
    expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: '0.9rem' }} />}
    {...props}
  />
))(({ theme }) => ({
  backgroundColor:
    theme.palette.mode === 'dark'
      ? 'rgba(255, 255, 255, .05)'
      : 'rgba(0, 0, 0, .03)',
  flexDirection: 'row-reverse',
  '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
    transform: 'rotate(90deg)',
  },
  '& .MuiAccordionSummary-content': {
    marginLeft: theme.spacing(1),
  },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(2),
  borderTop: '1px solid rgba(0, 0, 0, .125)',
}));

const renderItems = (expanded, handleChange, t) => Array.from({ length: 29 }, (item, index) => (
  <Accordion expanded={expanded === `panel${index}`} onChange={handleChange(`panel${index}`)} key={index}>
    <AccordionSummary aria-controls={`panel${index}d-content`} id={`panel${index}d-header`}>
      <MDTypography variant="h5">{t(`faq.q${index}`)}</MDTypography>
    </AccordionSummary>
    <AccordionDetails>
      <MDTypography variant="body2" style={{ whiteSpace: 'pre-line' }}>{t(`faq.a${index}`)}</MDTypography>
    </AccordionDetails>
  </Accordion>
));

function Faq() {
  const { t } = useTranslation();
  const { state } = useContext(Context);
  const { user } = state;
  const [expanded, setExpanded] = useState(false);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  return (
    <DashboardLayout>
      <Header challenge={user.team.challenge} />
      <Card>
        <MDBox p={3}>
          <MDTypography variant="h4">
            {t('faq.title')}
          </MDTypography>
          <MDTypography variant="subtitle2">
            {t('faq.subtitle')}
          </MDTypography>
          <MDBox mt={3} mb={3}>
            {renderItems(expanded, handleChange, t)}
          </MDBox>
        </MDBox>
      </Card>
      <Footer />
    </DashboardLayout>
  );
}

export default Faq;
