import { useEffect } from 'react';
import MDBox from 'components/MDBox';

import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import {
  Avatar, Box, Button, Paper,
} from '@mui/material';
import { formatDistanceToNow } from 'date-fns';
import { utcToZonedTime } from 'date-fns-tz';
import PropTypes from 'prop-types';
import { useQuery, useQueryClient } from 'react-query';
import FavoriteIcon from '@mui/icons-material/Favorite';
import Grid from '@mui/material/Grid';
import { findAndTranslateActivity, getLocale, stringAvatar } from '../../../utils';
import { fetchActivities, fetchNotifications } from '../../../api';
import MDTypography from '../../../components/MDTypography';
import { STALE_TIME } from '../../../config';

function Notifications({ user }) {
  const { id, timezone, language } = user;
  const { t } = useTranslation();
  const locale = getLocale(language);
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  const showPost = (notification) => navigate(`/social/${notification.postId}`);

  const { data: notifications } = useQuery(
    ['notifications'],
    () => fetchNotifications(id),
    {
      enabled: !!user,
    },
  );

  const { data: activityData } = useQuery(
    ['activities', user.language],
    () => fetchActivities(),
    {
      staleTime: STALE_TIME.ONE_HOUR,
    },
  );
  const { activities } = activityData || [];

  useEffect(async () => {
    if (user) {
      queryClient.setQueryData('notificationCount', 0);
    }
  }, []);

  const renderRow = (n) => {
    const {
      id: notificationId, workoutId, postId, commentId, friendsId,
      like, message, workoutActivity, fromUserFullName, fromUserAvatar, created, markedAsRead,
    } = n;
    const workoutLike = workoutId !== null && commentId === null && like;
    const workoutComment = commentId !== null && workoutId !== null && !like;
    const postComment = commentId !== null && workoutId === null && !like;
    const commentLike = commentId !== null && like;
    const postLike = postId !== null && commentId === null && workoutId === null && like;

    const bgColor = markedAsRead ? 'rgb(248, 248, 248)' : 'rgb(225,240,255)';
    return (
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        mt={2}
        key={notificationId}
      >
        <Avatar {...stringAvatar(fromUserFullName)} src={fromUserAvatar} />
        <Paper
          elevation={0}
          sx={{
            marginLeft: '16px',
            padding: '10px',
            backgroundColor: bgColor,
            width: '100%',
          }}
        >
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
          >
            <Box
              display="flex"
              alignItems="center"
            >
              <MDTypography variant="button" fontWeight="medium">{fromUserFullName}</MDTypography>
              {like ? (
                <FavoriteIcon
                  sx={{ width: 20, height: 20, marginLeft: 1 }}
                  color="error"
                />
              ) : null}
            </Box>
            <MDTypography variant="caption" fontWeight="regular" color="text">
              {formatDistanceToNow(utcToZonedTime(created, timezone), { locale })}
              {t('social.ago')}
            </MDTypography>
          </Box>

          <Box display="flex" justifyContent="space-between">
            {workoutComment ? (
              <MDTypography variant="caption" fontWeight="regular" color="text" component="div">
                {t('profile.notifications.activity.comment')}
                <Box component="span" variant="body2" fontWeight="regular" color="text" textTransform="uppercase">{workoutActivity}</Box>
                <MDTypography variant="button" fontWeight="regular" fontStyle="italic" color="text" component="div" sx={{ paddingTop: 1 }}>
                  {message}
                </MDTypography>
              </MDTypography>
            ) : null}
            {postComment ? (
              <MDTypography variant="caption" fontWeight="regular" color="text" component="div">
                {t('profile.notifications.post.comment')}
                <MDTypography variant="button" fontWeight="regular" fontStyle="italic" color="text" component="div" sx={{ paddingTop: 1 }}>
                  {message}
                </MDTypography>
              </MDTypography>
            ) : null}
            {workoutLike ? (
              <MDTypography variant="caption" fontWeight="regular" color="text" component="div">
                {t('profile.notifications.activity.like')}
                {/* eslint-disable-next-line max-len */}
                <MDTypography variant="button" fontWeight="regular" color="text" textTransform="uppercase" component="div" sx={{ paddingTop: 1 }}>
                  {findAndTranslateActivity(workoutActivity, activities)}
                </MDTypography>
              </MDTypography>
            ) : null}
            {postLike ? (
              <MDTypography variant="caption" fontWeight="regular" color="text" component="div">
                {t('profile.notifications.post.like')}
                <MDTypography variant="button" fontWeight="regular" fontStyle="italic" color="text" component="div" sx={{ paddingTop: 1 }}>
                  {message}
                </MDTypography>
              </MDTypography>
            ) : null}
            {commentLike ? (
              <MDTypography variant="caption" fontWeight="regular" color="text" component="div">
                {t('profile.notifications.comment.like')}
                <MDTypography variant="button" fontWeight="regular" fontStyle="italic" color="text" component="div" sx={{ paddingTop: 1 }}>
                  {message}
                </MDTypography>
              </MDTypography>
            ) : null}
            {friendsId ? (
              <MDTypography variant="button" fontWeight="regular" color="text" component="div">
                {t('profile.notifications.friendRequest')}
              </MDTypography>
            ) : (
              <Button onClick={() => showPost(n)}>{t('profile.notifications.activity.show')}</Button>
            )}
          </Box>
        </Paper>
      </Box>
    );
  };

  return (
    <Grid container spacing={1}>
      <Grid item xs={12}>
        <MDBox p={2}>
          <MDTypography variant="h6" fontWeight="medium" textTransform="capitalize">{t('profile.notifications.title')}</MDTypography>
          {notifications?.data.map((n) => renderRow(n))}
        </MDBox>
      </Grid>
    </Grid>
  );
}

Notifications.propTypes = {
  user: PropTypes.objectOf(
    PropTypes.oneOfType([PropTypes.array, PropTypes.object, PropTypes.number, PropTypes.string, PropTypes.bool]),
  ).isRequired,
};

export default Notifications;
