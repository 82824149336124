import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import Card from '@mui/material/Card';
import MDBox from 'components/MDBox';
import MDTypography from 'components/MDTypography';
import MDButton from 'components/MDButton';
import { Avatar } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { stringAvatar } from '../../utils';

function FriendsList({
  friends, shadow, isMyProfile, removeFriend, approveFriend,
}) {
  const { t } = useTranslation();
  const renderActionButton = (id, approvedFriend, approvableFriend) => {
    if (!isMyProfile) {
      return null;
    }
    if (approvedFriend) {
      return (
        <MDButton variant="outlined" color="error" size="small" onClick={() => removeFriend(id)}>
          {t('general.delete')}
        </MDButton>
      );
    }
    if (approvableFriend) {
      return (
        <MDButton variant="outlined" color="success" size="small" onClick={() => approveFriend(id)}>
          {t('general.approve')}
        </MDButton>
      );
    }

    return (
      <MDButton variant="text" color="info">
        {t('profile.friend.action.waiting')}
      </MDButton>
    );
  };

  const renderFriends = friends.map(({
    id, avatar, firstName, lastName, team, approvedFriend, approvableFriend,
  }) => {
    if (!isMyProfile && !approvedFriend) {
      return null;
    }
    return (
      <MDBox key={id} component="li" display="flex" alignItems="center" py={1} mb={1}>
        <MDBox mr={2}>
          <Avatar {...stringAvatar(`${firstName} ${lastName}`)} src={avatar} />
        </MDBox>
        <Link to={`/profile/${id}`}>
          <MDBox display="flex" flexDirection="column" alignItems="flex-start" justifyContent="center">
            <MDTypography variant="button" fontWeight="medium">
              {firstName}
              {' '}
              {lastName}
            </MDTypography>
            <MDTypography variant="caption" color="text">
              { team.teamName === 'individual_team' ? t('general.individual_team') : team.teamName }
            </MDTypography>
          </MDBox>
        </Link>
        <MDBox ml="auto">
          {renderActionButton(id, approvedFriend, approvableFriend)}
        </MDBox>
      </MDBox>
    );
  });

  return (
    <Card sx={{ height: '100%', boxShadow: !shadow && 'none' }}>
      <MDBox pt={2} px={2}>
        <MDTypography variant="h6" fontWeight="medium" textTransform="capitalize">
          {t('profile.friends')}
        </MDTypography>
      </MDBox>
      <MDBox p={2}>
        <MDBox component="ul" display="flex" flexDirection="column" p={0} m={0}>
          {renderFriends}
        </MDBox>
      </MDBox>
    </Card>
  );
}

FriendsList.defaultProps = {
  shadow: true,
  isMyProfile: false,
};

FriendsList.propTypes = {
  friends: PropTypes.arrayOf(PropTypes.object).isRequired,
  shadow: PropTypes.bool,
  isMyProfile: PropTypes.bool,
  removeFriend: PropTypes.func.isRequired,
  approveFriend: PropTypes.func.isRequired,
};

export default FriendsList;
