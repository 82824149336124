import { Controller } from 'react-hook-form';
import PropTypes from 'prop-types';
import Autocomplete from '@mui/material/Autocomplete';
// eslint-disable-next-line import/no-extraneous-dependencies
import { styled, lighten, darken } from '@mui/system';
import { useTranslation } from 'react-i18next';
import FormField from '../../../comps/FormField';

const GroupHeader = styled('div')(({ theme }) => ({
  position: 'sticky',
  top: '-8px',
  padding: '4px 10px',
  color: theme.palette.primary.main,
  fontSize: '14px',
  backgroundColor:
    theme.palette.mode === 'light'
      ? lighten(theme.palette.primary.light, 0.85)
      : darken(theme.palette.primary.main, 0.8),
}));

const GroupItems = styled('ul')({
  padding: 0,
});

function Activity({
  onActivityChange, activities, control, isDisabled, isLoading,
}) {
  const { t } = useTranslation();
  return (
    <Controller
      name="activity"
      control={control}
      defaultValue={null}
      render={({
        field: { onChange, value },
        fieldState: { error, invalid },
      }) => (
        <Autocomplete
          disableClearable
          blurOnSelect
          onChange={(e, newValue) => {
            onActivityChange(newValue);
            onChange(newValue);
          }}
          isOptionEqualToValue={(option, val) => (option.id ? option.id === val.id : false)}
          getOptionLabel={(option) => (option.activityName ? option.translation : 'Loading...')}
          options={activities}
          groupBy={(option) => option.type}
          loading={isLoading}
          value={value}
          renderOption={(props, option) => (
            <li {...props} key={option.id}>
              {option.translation}
            </li>
          )}
          renderGroup={(params) => (
            <li key={params.key}>
              <GroupHeader>{t(params.group)}</GroupHeader>
              <GroupItems>{params.children}</GroupItems>
            </li>
          )}
          renderInput={(params) => (
            <FormField
              helperText={invalid ? error.message : null}
              label={t('general.activity')}
              InputLabelProps={{ shrink: true }}
              {...params}
              error={invalid}
              variant="outlined"
              disabled={isDisabled}
            />
          )}
        />
      )}
    />
  );
}

Activity.defaultProps = {
  workout: null,
  isDisabled: false,
  isLoading: false,
  activities: [],
};

Activity.propTypes = {
  workout: PropTypes.objectOf(
    PropTypes.oneOfType([PropTypes.array, PropTypes.object, PropTypes.number, PropTypes.string, PropTypes.bool]),
  ),
  control: PropTypes.oneOfType([PropTypes.object]).isRequired,
  onActivityChange: PropTypes.func.isRequired,
  activities: PropTypes.arrayOf(PropTypes.object),
  isDisabled: PropTypes.bool,
  language: PropTypes.string.isRequired,
  isLoading: PropTypes.bool,
};

export default Activity;
