import { Controller } from 'react-hook-form';
import PropTypes from 'prop-types';
import { format, utcToZonedTime } from 'date-fns-tz';
import { subHours } from 'date-fns';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { useTranslation } from 'react-i18next';
import MDInput from '../../../components/MDInput';

function StartTime({
  workout, timezone, control, isDisabled,
}) {
  const { t } = useTranslation();
  return (
    <Controller
      name="startTime"
      control={control}
      defaultValue={(workout && format(utcToZonedTime(workout.startTime, timezone), "yyyy-MM-dd'T'HH:mm"))
          || format(subHours(new Date(), 1), "yyyy-MM-dd'T'HH:mm")}
      render={({
        field: { onChange, value },
        fieldState: { error, invalid },
      }) => (
        <DateTimePicker
          inputFormat="yyyy-MM-dd HH:mm"
          mask="____-__-__ __:__"
          label={t('general.startTime')}
          value={value}
          onChange={(newValue) => {
            onChange(newValue);
          }}
          maxDateTime={new Date()}
          renderInput={(params) => (
            <MDInput
              helperText={invalid ? error.message : null}
              variant="outlined"
              fullWidth
              {...params}
              error={invalid}
              disabled={isDisabled}
            />
          )}
        />
      )}
    />
  );
}

StartTime.defaultProps = {
  workout: null,
  isDisabled: false,
};

StartTime.propTypes = {
  workout: PropTypes.objectOf(
    PropTypes.oneOfType([PropTypes.array, PropTypes.object, PropTypes.number, PropTypes.string, PropTypes.bool]),
  ),
  control: PropTypes.oneOfType([PropTypes.object]).isRequired,
  timezone: PropTypes.string.isRequired,
  isDisabled: PropTypes.bool,
};

export default StartTime;
