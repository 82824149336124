export const msalConfig = {
  auth: {
    clientId: '2028832e-3de4-4d4e-97c6-f85a97216e21',
    redirectUri: process.env.REACT_APP_SSO_REDIRECT_URI,
    authority: 'https://login.microsoftonline.com/common',
    grant_type: 'authorization_code',
  },
  cache: {
    cacheLocation: 'localStorage',
    storeAuthStateInCookie: false,
  },
};

export const ssoLoginRequest = {
  scopes: ['User.Read'],
};

export const STALE_TIME = {
  TWO_MINUTES: 120000,
  ONE_HOUR: 3600000,
};
