import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import InfiniteScroll from 'react-infinite-scroll-component';
import { Box, CircularProgress, Grid } from '@mui/material';
import { useQuery, useInfiniteQuery } from 'react-query';
import { STALE_TIME } from 'config';
import { getPosts, fetchPost, fetchActivities } from '../../../api';
import PostEntry from './postEntry';

function Posts({ user, postId }) {
  const [post, setPost] = useState(null);
  const PAGE_SIZE = 10;

  const { data: activityData } = useQuery(
    ['activities', user.language],
    () => fetchActivities(),
    {
      staleTime: STALE_TIME.ONE_HOUR,
    },
  );
  const { activities } = activityData || [];

  const {
    data,
    fetchNextPage,
    hasNextPage,
    isFetching,
  } = useInfiniteQuery(
    ['posts'],
    ({ pageParam = 0 }) => getPosts(PAGE_SIZE, pageParam),
    {
      getNextPageParam: (lastPage) => (lastPage.nextPage ? lastPage.nextPage : undefined),
    },
  );
  const posts = data?.pages.flatMap((page) => page.posts);
  const doFetchPost = async (id) => {
    const response = await fetchPost(id);
    setPost(response.data);
  };

  useEffect(async () => {
    if (postId) {
      await doFetchPost(postId);
    }
  }, [user, postId]);

  if (!posts) {
    return null;
  }

  return (
    <>
      {isFetching ? (
        <Box display="flex" alignItems="center" justifyContent="center" p={1}>
          <CircularProgress size={20} />
        </Box>
      ) : null}
      <InfiniteScroll
        dataLength={posts?.length}
        next={fetchNextPage}
        hasMore={hasNextPage}
        loader={<h4>Loading...</h4>}
      >
        <Grid container spacing={2}>
          {postId && post
            ? (
              <Grid item xs={12} md={6} lg={4}>
                <PostEntry post={post} user={user} activities={activities} />
              </Grid>
            )
            : (
              posts.map((p) => (
                <Grid item xs={12} md={6} lg={4} key={p.id}>
                  <PostEntry post={p} user={user} activities={activities} key={p.id} />
                </Grid>
              ))
            )}

        </Grid>
      </InfiniteScroll>
    </>
  );
}

Posts.defaultProps = {
  postId: null,
};

Posts.propTypes = {
  user: PropTypes.objectOf(
    PropTypes.oneOfType([PropTypes.array, PropTypes.object, PropTypes.number, PropTypes.string, PropTypes.bool]),
  ).isRequired,
  postId: PropTypes.string,
};

export default Posts;
