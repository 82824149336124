import { useContext, useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import Card from '@mui/material/Card';
import Footer from 'comps/Footer';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import { STALE_TIME } from 'config';
import {
  Box, Button, CardActions, Grid, Stack,
} from '@mui/material';
import CardMedia from '@mui/material/CardMedia';
import CardContent from '@mui/material/CardContent';
import defaultBgImage from 'assets/challengize/images/social/workout_bg.png';
import MDTypography from '../../components/MDTypography';
import DashboardLayout from '../../LayoutContainers/DashboardLayout';
import Header from '../header';
import { Context } from '../../store/Store';
import { getHealthPortal } from '../../api';
import Filter from '../../comps/Filter';

function Tips() {
  const location = useLocation();
  const { pathname } = location;
  const category = pathname && pathname === '/health-portal/health-tips' ? 'HEALTH_TIPS' : 'TRAINING';
  const { t } = useTranslation();
  const { state } = useContext(Context);
  const { user } = state;
  const { language } = user;
  const [filterData, setFilterData] = useState([]);
  const [selectedFilter, setSelectedFilter] = useState('all');

  useEffect(() => {
    setSelectedFilter('all');
  }, [category]);

  const { data: healthLinks } = useQuery(
    ['healthLinks', pathname, language],
    () => getHealthPortal(category),
    {
      staleTime: STALE_TIME.ONE_HOUR.STALE_TIME,
      cacheTime: STALE_TIME.ONE_HOUR.CACHE_TIME,
    },
  );

  const renderItem = (link) => {
    const {
      title,
      description,
      language: lang,
      length,
      author,
      url,
      imageUrl,
      tags,
    } = link;
    if (selectedFilter === 'all' || tags.includes(selectedFilter)) {
      return (
        <Grid item xs={12} md={6} lg={4} key={title}>
          <Card>
            <CardMedia
              height="300"
              sx={{ borderTopRightRadius: '0.75rem', borderTopLeftRadius: '0.75rem' }}
              component="img"
              image={imageUrl || defaultBgImage}
            />
            <CardContent>
              <MDTypography variant="subtitle2" fontWeight="regular" sx={{ lineHeight: 1.4 }}>
                {title}
              </MDTypography>
              <Box display="flex" mt={0.5}>
                <MDTypography variant="button" color="text" fontWeight="regular">
                  {description}
                </MDTypography>
              </Box>
            </CardContent>
            <CardActions sx={{ justifyContent: 'space-between' }}>
              <Stack ml={1}>
                <MDTypography variant="button" color="text" fontWeight="regular">
                  {author || null}
                </MDTypography>
                <MDTypography variant="button" color="text" fontWeight="regular">
                  {length ? `${length} ${t('healthPortal.minutes')}` : null}
                  {length && lang ? ', ' : null}
                  {lang ? t(`healthPortal.lang.${lang}`) : null}
                </MDTypography>
              </Stack>
              <Button size="small" target="_blank" href={`${url}?lang=${language}`}>
                {t(`healthPortal.${category.toLowerCase()}.link`)}
              </Button>
            </CardActions>
          </Card>
        </Grid>
      );
    }
    return null;
  };

  useEffect(() => {
    if (healthLinks) {
      const uniqueTags = [...new Set(healthLinks.map((l) => l.tags).flat())];
      // Translate the filter values to be able to do a proper sort
      const tagsWithTranslations = [];
      uniqueTags.forEach((value) => {
        tagsWithTranslations.push({ value, translation: t(`filter.${value}`) });
      });
      setFilterData([
        { value: 'all', translation: t('filter.all') },
        // eslint-disable-next-line no-nested-ternary
        ...tagsWithTranslations.sort((a, b) => ((a.translation > b.translation) ? 1 : ((b.translation > a.translation) ? -1 : 0))),
      ]);
    }
  }, [healthLinks]);

  return (
    <DashboardLayout>
      <Header challenge={user.team.challenge} />
      <Card sx={{ marginBottom: 2, padding: 3 }}>
        <Grid container>
          <Grid item xs={12} sm={4}>
            <MDTypography variant="h4">
              {t(`healthPortal.${category.toLowerCase()}`)}
            </MDTypography>
          </Grid>
          <Grid item container xs={12} sm={8} justifyContent="flex-end">
            <Filter
              data={filterData}
              onValueChange={(id) => setSelectedFilter(id)}
              selectedValue={selectedFilter}
            />
          </Grid>
        </Grid>
      </Card>
      <Grid container spacing={2}>
        {healthLinks?.map((hl) => renderItem(hl))}
      </Grid>
      <Footer />
    </DashboardLayout>
  );
}

export default Tips;
