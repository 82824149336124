import PropTypes from 'prop-types';

import MDTypography from 'components/MDTypography';
import { useTranslation } from 'react-i18next';

function Cell({ name }) {
  const { t } = useTranslation();
  return (
    <MDTypography variant="button" fontWeight="medium" color="text">
      {name === 'individual_team' ? t('general.individual_team') : name}
    </MDTypography>
  );
}

Cell.defaultProps = {
  name: '',
};

Cell.propTypes = {
  name: PropTypes.string,
};

export default Cell;
