import { useEffect, useRef, useState } from 'react';
import {
  onSnapshot, query, collection, orderBy, where,
} from 'firebase/firestore';
import Card from '@mui/material/Card';
import Linkify from 'react-linkify';
import PropTypes from 'prop-types';
import {
  Avatar,
  Box,
  Collapse,
  Dialog,
  List,
  ListItem,
  DialogTitle,
  DialogContent,
  DialogActions,
  DialogContentText,
  Grid,
  CircularProgress,
} from '@mui/material';
import Tooltip from '@mui/material/Tooltip';
import { isSameDay } from 'date-fns';
import { format, utcToZonedTime } from 'date-fns-tz';
import { useTranslation } from 'react-i18next';
import IconButton from '@mui/material/IconButton';
import SendIcon from '@mui/icons-material/Send';
import { getApp } from 'firebase/app';
import { getFunctions, connectFunctionsEmulator } from 'firebase/functions';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import InfiniteScroll from 'react-infinite-scroll-component';
import { useQueryClient } from 'react-query';
import { stringAvatar, stripParams } from '../../utils';
import * as FirestoreService from './firestore';
import MDTypography from '../../components/MDTypography';
import MDInput from '../../components/MDInput';
import {
  db, deleteMessage, markChatAsRead, getMessages, getOldMessages, markMessageAsRead,
} from './firestore';
import pxToRem from '../../assets/theme/functions/pxToRem';
import typography from '../../assets/theme/base/typography';
import MDButton from '../../components/MDButton';
import FileUpload from './fileUpload';
import { getImageInfo, sendChatNotification } from '../../api';
import logo from '../../assets/challengize/images/svg/logo.svg';
import teamChatLogo from '../../assets/challengize/images/team_chat_logo.png';

const { size, lineHeight } = typography;
const MESSAGE_BATCH_SIZE = 20;

function ChatContent({ user, chat }) {
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const [messages, setMessages] = useState([]);
  const [newMessage, setNewMessage] = useState('');
  const [expanded, setExpanded] = useState(false);
  const [showDeleteDialog, setShowDeleteDialog] = useState(false);
  const [selectedMessage, setSelectedMessage] = useState(null);
  const filePond = useRef(null);
  const [imageSelected, setImageSelected] = useState(false);
  const [firstMessage, setFirstMessage] = useState(null);
  const [subscriptionStartDate, setSubscriptionStartDate] = useState(null);
  const [lastSubscriptionMessage, setLastSubscriptionMessage] = useState(null);
  const [allItemsLoaded, setAllItemsLoaded] = useState(false);

  const chatScrollRef = useRef();

  const { timezone, id: userId } = user;
  const {
    id: chatId, name, challengeChat, teamChat, userMap: users,
  } = chat;
  let previousMessageDate = null;
  let thisMessageDate = null;

  const showTextInput = challengeChat ? user.roles.includes('CHALLENGE_ADMIN') : true;

  const handleExpandClick = () => {
    if (!challengeChat) {
      setExpanded(!expanded);
    }
  };

  const functions = getFunctions(getApp());
  connectFunctionsEmulator(functions, '127.0.0.1', 5001);

  useEffect(() => {
    const initializeMessages = async () => {
      const response = await getMessages(chatId, MESSAGE_BATCH_SIZE);
      const result = [];
      if (response?.size < MESSAGE_BATCH_SIZE) {
        setAllItemsLoaded(true);
      }
      let start = Date.now();
      response.forEach((m) => {
        let data = m.data();
        data = { ...data, id: m.id };
        start = data.created;
        setFirstMessage(data.created);
        result.push(data);
      });
      setMessages(result);
      setSubscriptionStartDate(start);
    };

    if (chat) {
      setAllItemsLoaded(false);

      markChatAsRead(chatId, user.id).then(() => {
        queryClient.invalidateQueries({ queryKey: ['unreadChatMessages'] });
      });

      // Fetch initial messages
      initializeMessages();
    }
    return null;
  }, [chat]);

  useEffect(() => {
    if (lastSubscriptionMessage) {
      const index = messages.findIndex((m) => m.id === lastSubscriptionMessage.id);
      if (index < 0) {
        if (lastSubscriptionMessage.userId !== userId) {
          setTimeout(() => markMessageAsRead(chatId, lastSubscriptionMessage.id, userId), 2000);
        }
        setMessages((oldMessages) => [lastSubscriptionMessage, ...oldMessages]);
        if (messages.length > 0) {
          chatScrollRef.current.scrollTo(0, chatScrollRef.current.scrollHeight);
        }
      } else {
        const updatedMessages = messages.map((m) => {
          if (m.id === lastSubscriptionMessage.id) {
            return lastSubscriptionMessage;
          }
          return m;
        });
        setMessages(updatedMessages);
      }
    }
  }, [lastSubscriptionMessage]);

  useEffect(() => {
    if (subscriptionStartDate) {
      // Subscribe to all messages after the last initial data
      const q = query(collection(db, 'chats', chatId, 'messages'), where('created', '>', subscriptionStartDate), orderBy('created', 'asc'));
      const unsubscribe = onSnapshot(q, (snapshot) => {
        snapshot.docs.forEach((message) => {
          let data = message.data();
          data = { ...data, id: message.id };
          setLastSubscriptionMessage(data);
        });
      });
      return () => {
        unsubscribe();
      };
    }
    setSubscriptionStartDate(null);
    return undefined;
  }, [subscriptionStartDate]);

  const loadOlderMessages = async () => {
    if (!allItemsLoaded) {
      const response = await getOldMessages(chatId, MESSAGE_BATCH_SIZE, firstMessage);
      const result = [];
      if (response?.size < MESSAGE_BATCH_SIZE) {
        setAllItemsLoaded(true);
      }
      // Ordered from lastCreated to firstCreated
      response.forEach((m) => {
        let data = m.data();
        data = { ...data, id: m.id };
        setFirstMessage(data.created);
        result.push(data);
      });
      setMessages((oldMessages) => oldMessages.concat(result));
    }
  };

  const postMessage = async () => {
    if (newMessage.length > 0 || filePond.current.getFile()) {
      if (filePond.current.getFile()) {
        const imageUrl = stripParams(filePond.current.getFile().serverId);
        const chatImageResponse = await getImageInfo(imageUrl);
        const { height, width } = chatImageResponse.data;
        await FirestoreService.postMessageWithImage(chatId, newMessage, userId, imageUrl, height, width);
        filePond.current.removeFiles();
      } else {
        await FirestoreService.postMessage(chatId, newMessage, userId);
      }
      setNewMessage('');
      await sendChatNotification(user.id, { message: newMessage, challengeChat, teamChat });
    }
  };

  const getTimeFromTimestamp = (timestamp) => {
    const dateObj = utcToZonedTime(timestamp, timezone);
    const hours = dateObj.getHours();
    const minutes = dateObj.getMinutes().toString().padStart(2, '0');
    return `${hours}:${minutes}`;
  };

  const onDeleteMessage = (messageId) => {
    setShowDeleteDialog(true);
    setSelectedMessage(messageId);
  };

  const confirmDeleteMessage = async () => {
    await deleteMessage(chatId, selectedMessage);

    const messageToDelete = messages.find((m) => m.id === selectedMessage);
    if (messageToDelete && messageToDelete.created < subscriptionStartDate) {
      const updatedMessages = messages.map((m) => {
        if (m.id === selectedMessage) {
          return { ...m, deleted: true };
        }
        return m;
      });
      setMessages(updatedMessages);
    }
    setSelectedMessage(null);
    setShowDeleteDialog(false);
  };

  const cancelDeleteMessage = () => {
    setSelectedMessage(null);
    setShowDeleteDialog(false);
  };

  const renderImage = (image, message, pad) => {
    const { url, height, width } = image || {};
    const isVideo = url && url?.split('.').pop() === 'mp4';

    const portraitMode = height > width;
    const hasText = message && message.length > 0;

    if (isVideo) {
      // eslint-disable-next-line jsx-a11y/media-has-caption
      return <video controls width="100%" src={url} />;
    }

    if (portraitMode && !hasText) {
      return (
        <img
          src={url}
          style={{
            maxHeight: 320, borderRadius: '4px', marginBottom: '10px', marginTop: pad ? '6px' : 0,
          }}
          alt=""
        />
      );
    }
    if (portraitMode && hasText) {
      return (
        <img
          src={url}
          style={{
            width: 284, maxHeight: 400, borderRadius: '4px', objectFit: 'cover', marginBottom: '10px', marginTop: pad ? '6px' : 0,
          }}
          alt=""
        />
      );
    }

    return (
      <img
        src={url}
        style={{
          maxWidth: 320, maxHeight: 320, borderRadius: '4px', marginBottom: '10px', marginTop: pad ? '6px' : 0,
        }}
        alt=""
      />
    );
  };

  const renderMessage = (item) => {
    const {
      id: messageId, userId: authorId, created, message, deleted, image, userAdded, userRemoved,
    } = item;
    const author = users.get(authorId);
    const { url, height, width } = image || {};
    // eslint-disable-next-line no-nested-ternary
    const containerWidth = url ? ((height > width) ? 300 : 336) : 500;

    const renderDate = previousMessageDate !== null && !isSameDay(created, previousMessageDate);
    previousMessageDate = created;

    if (thisMessageDate === null) {
      thisMessageDate = created;
    }
    const startDate = (thisMessageDate !== null && thisMessageDate !== -1 && !isSameDay(created, thisMessageDate)) ? thisMessageDate : null;

    if (startDate) {
      thisMessageDate = -1;
    }

    if (userAdded || userRemoved) {
      const text = userAdded ? t('chat.user_added') : t('chat.user_removed');
      return (
        <div key={messageId}>
          {renderDate ? (
            <Box m={1} display="flex" justifyContent="center">
              <MDTypography variant="caption" color="dark" fontWeight="regular">
                {isSameDay(created, new Date()) ? 'Today' : format(new Date(created), 'yyyy-MM-dd')}
              </MDTypography>
            </Box>
          ) : null}
          <Box m={1} display="flex" justifyContent="center" key={messageId}>
            <MDTypography variant="caption" color="dark" fontWeight="regular">
              {`${message} ${text}`}
            </MDTypography>
          </Box>
          {startDate ? (
            <Box m={1} display="flex" justifyContent="center">
              <MDTypography variant="caption" color="dark" fontWeight="regular">
                {isSameDay(startDate, new Date()) ? 'Today' : format(new Date(startDate), 'yyyy-MM-dd')}
              </MDTypography>
            </Box>
          ) : null}
        </div>
      );
    }

    // Do not show messages when author is missing, this will happen if a user is deleted from the chat
    if (!author || (challengeChat && deleted)) {
      return null;
    }

    if (userId === authorId) {
      return (
        <div key={messageId}>
          {renderDate ? (
            <Box m={1} display="flex" justifyContent="center">
              <MDTypography variant="caption" color="dark" fontWeight="regular">
                {isSameDay(created, new Date()) ? t('chat.today') : format(new Date(created), 'yyyy-MM-dd')}
              </MDTypography>
            </Box>
          ) : null}
          <Box mt={1} mb={2} pr={4} display="flex" justifyContent="flex-end">
            <Tooltip
              title={deleted ? '' : (
                <IconButton
                  color="error"
                  aria-label="delete"
                  onClick={() => onDeleteMessage(messageId)}
                  sx={{ marginLeft: '-3px', zIndex: 1 }}
                >
                  <DeleteOutlineIcon />
                </IconButton>
              )}
              placement="left-start"
              arrow={false}
              PopperProps={{
                sx: {
                  '& .MuiTooltip-tooltip': {
                    backgroundColor: '#f5f5f9',
                    border: '1px solid #dadde9',
                    width: 50,
                    height: 50,
                    borderRadius: 25,
                  },
                },
                disablePortal: true,
              }}
            >
              <Box
                display="flex"
                flexDirection="column"
                alignItems="flex-end"
                ml={9}
                p={1}
                sx={{
                  backgroundColor: '#6697B4',
                  borderTopRightRadius: 10,
                  borderTopLeftRadius: 10,
                  borderBottomLeftRadius: 10,
                  maxWidth: containerWidth,
                }}
              >
                {image && !deleted ? (
                  renderImage(image, message)
                ) : null}
                <Box sx={{ width: '100%' }}>
                  {deleted ? (
                    <MDTypography color="light" fontWeight="regular" fontSize={size.sm} sx={{ fontStyle: 'italic' }}>
                      Message has been deleted
                    </MDTypography>
                  ) : (
                    <MDTypography color="light" fontWeight="regular" fontSize={size.sm} lineHeight={lineHeight.md}>
                      <Linkify>
                        {message}
                      </Linkify>
                    </MDTypography>
                  )}
                </Box>
                <MDTypography variant="caption" color="light">
                  {getTimeFromTimestamp(created)}
                </MDTypography>
              </Box>
            </Tooltip>
          </Box>
        </div>
      );
    }

    return (
      <div key={messageId}>
        {renderDate ? (
          <Box m={1} display="flex" justifyContent="center">
            <MDTypography variant="caption" color="dark" fontWeight="regular">
              {isSameDay(created, new Date()) ? 'Today' : format(new Date(created), 'yyyy-MM-dd')}
            </MDTypography>
          </Box>
        ) : null}
        <Box display="flex" mt={1} mb={2} pl={4} sx={{ width: '100%' }} key={messageId}>
          <Box display="flex" mr={1} alignItems="flex-end">
            <Avatar {...stringAvatar(`${author?.firstName} ${author?.lastName}`, 'sm')} src={author?.avatar} />
          </Box>
          <Box
            display="flex"
            flexDirection="column"
            alignItems="flex-start"
            p={1}
            sx={{
              backgroundColor: '#FFFFFF',
              borderTopRightRadius: 10,
              borderTopLeftRadius: 10,
              borderBottomRightRadius: 10,
              maxWidth: containerWidth,
            }}
          >
            <MDTypography fontWeight="regular" fontSize={size.sm} lineHeight={lineHeight.sm} sx={{ color: '#6697B4' }}>
              {`${author?.firstName} ${author?.lastName}`}
            </MDTypography>
            {image && !deleted ? (
              renderImage(image, message, true)
            ) : null}
            <Box sx={{ width: '100%' }}>
              {deleted ? (
                <MDTypography color="text" fontWeight="regular" fontSize={size.sm} sx={{ fontStyle: 'italic' }}>
                  Message has been deleted
                </MDTypography>
              ) : (
                <MDTypography color="text" fontWeight="regular" fontSize={size.sm} lineHeight={lineHeight.md}>
                  <Linkify>
                    {message}
                  </Linkify>
                </MDTypography>
              )}
            </Box>
            <Box display="flex" flexDirection="row" justifyContent="flex-end" sx={{ width: '100%' }}>
              <MDTypography variant="caption" color="dark">
                {getTimeFromTimestamp(created)}
              </MDTypography>
            </Box>
          </Box>
        </Box>
      </div>
    );
  };

  const renderLoader = () => (
    <Box display="flex" alignItems="center" justifyContent="center" p={2}>
      <CircularProgress size={20} />
    </Box>
  );

  const handleKeypress = (e) => {
    if (e.charCode === 13) {
      postMessage();
    }
  };

  return (
    <Card>
      {/* Header */}
      <Box
        display="flex"
        p={2}
        sx={{
          width: '100%',
        }}
        onClick={handleExpandClick}
      >
        <Box mr={2}>
          {challengeChat ? (
            <img
              src={logo}
              style={{ width: 48, height: 48, borderRadius: 99 }}
              alt="ChallengeChat"
            />
          ) : (
            <img
              src={teamChatLogo}
              style={{ width: 48, height: 48, borderRadius: 99 }}
              alt="TeamChat"
            />
          )}
        </Box>
        <Box display="flex" flexDirection="column" sx={{ width: '100%' }}>
          <Box display="flex" flexDirection="row" justifyContent="space-between" sx={{ width: '100%' }}>
            <MDTypography variant="h6" fontWeight="medium">{name}</MDTypography>
          </Box>
          <Box
            display="flex"
            flexDirection="row"
            alignItems="center"
            justifyContent="space-between"
            sx={{ width: '100%', paddingRight: 1 }}
          >
            <MDTypography variant="button" fontWeight="regular" color="text" sx={{ paddingRight: 2 }}>
              {t('chat.members.count', { count: chat?.users.length })}
            </MDTypography>
          </Box>
          <Collapse in={expanded} timeout="auto" unmountOnExit>
            <List>
              {chat?.users.map((u) => (
                <ListItem sx={{ marginTop: '8px' }} key={u.id}>
                  <Avatar {...stringAvatar(`${u.firstName} ${u.lastName}`, 'sm')} src={u.avatar} />
                  <MDTypography variant="h6" fontWeight="regular" sx={{ marginLeft: '10px' }}>
                    {`${u.firstName} ${u.lastName}`}
                  </MDTypography>
                </ListItem>
              ))}
            </List>
          </Collapse>
        </Box>
      </Box>

      <Box
        display="flex"
        flexDirection="column"
        justifyContent="space-between"
        sx={{ height: `calc(100vh - ${pxToRem(170)})` }}
      >

        {/* Chat messages */}
        <Box sx={{
          overflowX: 'hidden', backgroundColor: '#e2e8ed', height: '100%',
        }}
        >
          <div
            id={`scrollableDiv-${chat.id}`}
            style={{
              height: '100%',
              overflow: 'auto',
              display: 'flex',
              flexDirection: 'column-reverse',
            }}
            ref={chatScrollRef}
          >
            <InfiniteScroll
              dataLength={messages.length}
              next={loadOlderMessages}
              hasMore={!allItemsLoaded}
              loader={renderLoader()}
              inverse
              style={{ display: 'flex', flexDirection: 'column-reverse' }}
              scrollableTarget={`scrollableDiv-${chat.id}`}
            >
              {messages?.map((message) => (renderMessage(message)))}
            </InfiniteScroll>
          </div>
        </Box>

        { showTextInput ? (
          <Grid
            container
            alignItems="center"
            m={1}
          >
            <Grid item xs={imageSelected ? 12 : 1}>
              <FileUpload filePond={filePond} setImageSelected={setImageSelected} />
            </Grid>

            <Grid item xs={imageSelected ? 11 : 10}>
              <MDInput
                onChange={(newValue) => {
                  setNewMessage(newValue.target.value);
                }}
                onKeyPress={handleKeypress}
                value={newMessage}
                sx={{ width: '100%' }}
                label="Write a message..."
              />
            </Grid>

            <Grid item xs={1}>
              <IconButton onClick={postMessage}>
                <SendIcon
                  sx={{ width: 24, height: 24 }}
                  color="text"
                />
              </IconButton>
            </Grid>
          </Grid>
        ) : null }

      </Box>
      <Dialog
        open={showDeleteDialog}
        onClose={() => setShowDeleteDialog(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {t('chat.dialog.delete.title')}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {t('chat.dialog.delete.message')}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <MDButton variant="text" color="info" onClick={cancelDeleteMessage}>
            {t('general.cancel')}
          </MDButton>
          <MDButton variant="outlined" color="error" size="small" onClick={confirmDeleteMessage}>
            {t('general.delete')}
          </MDButton>
        </DialogActions>
      </Dialog>
    </Card>
  );
}

ChatContent.propTypes = {
  chat: PropTypes.objectOf(
    PropTypes.oneOfType([PropTypes.array, PropTypes.object, PropTypes.number, PropTypes.string, PropTypes.bool]),
  ),
  user: PropTypes.objectOf(
    PropTypes.oneOfType([PropTypes.array, PropTypes.object, PropTypes.number, PropTypes.string, PropTypes.bool]),
  ).isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  locale: PropTypes.object.isRequired,
};

ChatContent.defaultProps = {
  chat: null,
};

export default ChatContent;
