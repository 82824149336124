// Import the functions you need from the SDKs you need
import { initializeApp } from 'firebase/app';
import {
  getFirestore,
  query,
  collection,
  getDocs,
  addDoc,
  updateDoc,
  doc,
  where,
  // connectFirestoreEmulator,
  orderBy,
  limit,
  collectionGroup,
  getCountFromServer,
  writeBatch,
  arrayRemove,
} from 'firebase/firestore';

const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
};

// Initialize Firebase
export const app = initializeApp(firebaseConfig);
export const db = getFirestore(app);
// connectFirestoreEmulator(db, '127.0.0.1', 8080);

export const getChats = async (userId) => {
  const q = query(collection(db, 'chats'), where('userIds', 'array-contains', userId));
  const querySnapshot = await getDocs(q);
  const result = [];
  querySnapshot.forEach((chat) => {
    let data = chat.data();
    data = { ...data, id: chat.id };
    result.push(data);
  });
  return result;
};

export const getMessages = async (chatId, lim) => {
  const coll = collection(db, 'chats', chatId, 'messages');
  const q = query(coll, orderBy('created', 'desc'), limit(lim));
  return getDocs(q);
};

export const getOldMessages = async (chatId, lim, oldestMessageDate) => {
  const coll = collection(db, 'chats', chatId, 'messages');
  const q = query(coll, where('created', '<', oldestMessageDate), orderBy('created', 'desc'), limit(lim));
  return getDocs(q);
};

export const getUnreadMessageCount = async (chatId, userId) => {
  const q = query(collection(db, 'chats', chatId, 'messages'), where('unread', 'array-contains', userId));
  const snapshot = await getCountFromServer(q);
  return snapshot.data().count;
};

export const getTotalUnreadMessageCount = async (userId) => {
  const q = query(collectionGroup(db, 'messages'), where('unread', 'array-contains', userId));
  const snapshot = await getCountFromServer(q);
  return snapshot.data().count;
};

export const getChat = async (chatId) => {
  const querySnapshot = await getDocs(collection(db, 'chats', chatId, 'messages'));
  const result = [];
  querySnapshot.forEach((message) => {
    let data = message.data();
    data = { ...data, id: message.id };
    result.push(data);
  });
  return result;
};

export const postMessage = async (chatId, message, userId) => {
  const chatRef = collection(db, 'chats');
  await addDoc(collection(chatRef, chatId, 'messages'), {
    created: new Date().getTime(),
    message,
    userId,
  });
};

export const postMessageWithImage = async (chatId, message, userId, url, height, width) => {
  const chatRef = collection(db, 'chats');
  await addDoc(collection(chatRef, chatId, 'messages'), {
    created: new Date().getTime(),
    message,
    userId,
    image: { url, height, width },
  });
};

export const deleteMessage = async (chatId, messageId) => {
  await updateDoc(doc(db, 'chats', chatId, 'messages', messageId), {
    deleted: true,
  });
};

export const markChatAsRead = async (chatId, userId) => {
  // Get all unread messages for this chat and user
  const coll = collection(db, 'chats', chatId, 'messages');
  const q = query(coll, where('unread', 'array-contains', userId));
  const unreadMessages = await getDocs(q);

  // Loop each document and remove user from unread array
  const batch = writeBatch(db);
  unreadMessages.forEach((d) => {
    batch.update(d.ref, { unread: arrayRemove(userId) });
  });
  batch.commit();
};

export const markMessageAsRead = async (chatId, messageId, userId) => {
  await updateDoc(doc(db, 'chats', chatId, 'messages', messageId), {
    unread: arrayRemove(userId),
  });
};
