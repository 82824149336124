import Card from '@mui/material/Card';
import Table from '@mui/material/Table';
import TableRow from '@mui/material/TableRow';
import TableBody from '@mui/material/TableBody';
import Switch from '@mui/material/Switch';
import MDBox from 'components/MDBox';

import MDTypography from 'components/MDTypography';
import PropTypes from 'prop-types';

import { useTranslation } from 'react-i18next';
import { Controller, useForm } from 'react-hook-form';
import { Box } from '@mui/material';
import TableCell from '../../comps/TableCell';
import MDButton from '../../components/MDButton';

function Notifications({ user, onSubmit }) {
  const { t } = useTranslation();
  const {
    handleSubmit, control, reset,
  } = useForm();

  const doSubmit = (data) => onSubmit(data, reset);

  return (
    <Card id="notifications">
      <MDBox component="form" onSubmit={handleSubmit(doSubmit)}>
        <MDBox p={3} lineHeight={1}>
          <MDBox mb={1}>
            <MDTypography variant="h5">{t('settings.notifications.title')}</MDTypography>
          </MDBox>
          <MDTypography variant="button" color="text">
            {t('settings.notifications.info')}
          </MDTypography>
        </MDBox>
        <MDBox pb={3} px={3}>
          <MDBox minWidth="auto">
            <Table sx={{ minWidth: '36rem' }}>
              <MDBox component="thead">
                <TableRow>
                  <TableCell width="100%" padding={[1.5, 3, 1.5, 0.5]}>
                    {t('settings.notifications.activity')}
                  </TableCell>
                  <TableCell align="center" padding={[1.5, 6, 1.5, 6]}>
                    {t('general.email')}
                  </TableCell>
                  <TableCell align="center" padding={[1.5, 6, 1.5, 6]}>
                    {t('settings.notifications.push')}
                  </TableCell>
                </TableRow>
              </MDBox>
              <TableBody>
                <TableRow>
                  <TableCell padding={[1, 1, 1, 0.5]}>
                    <MDBox lineHeight={1.4}>
                      <MDTypography display="block" variant="button" fontWeight="regular">
                        {t('settings.notifications.activity.weeklyEmail')}
                      </MDTypography>
                      <MDTypography variant="caption" color="text" fontWeight="regular">
                        {t('settings.notifications.activity.weeklyEmail.info')}
                      </MDTypography>
                    </MDBox>
                  </TableCell>
                  <TableCell align="center" padding={[1, 1, 1, 0.5]}>
                    <Controller
                      name="receiveWeeklySummary"
                      control={control}
                      defaultValue={user.receiveWeeklySummary}
                      render={({ field: { onChange, value } }) => (
                        <Switch
                          value={value}
                          onChange={onChange}
                          checked={value}
                        />
                      )}
                    />
                  </TableCell>
                  <TableCell align="center" padding={[1, 1, 1, 0.5]}><div /></TableCell>
                </TableRow>

                {/* Post - Comment */}
                <TableRow>
                  <TableCell padding={[1, 1, 1, 0.5]}>
                    <MDBox lineHeight={1.4}>
                      <MDTypography display="block" variant="button" fontWeight="regular">
                        {t('settings.notifications.push.post-comment')}
                      </MDTypography>
                      <MDTypography variant="caption" color="text" fontWeight="regular">
                        {t('settings.notifications.push.post-comment.info')}
                      </MDTypography>
                    </MDBox>
                  </TableCell>
                  <TableCell align="center" padding={[1, 1, 1, 0.5]}><div /></TableCell>
                  <TableCell align="center" padding={[1, 1, 1, 0.5]}>
                    <Controller
                      name="pushPostComment"
                      control={control}
                      defaultValue={user.pushPostComment}
                      render={({ field: { onChange, value } }) => (
                        <Switch
                          value={value}
                          onChange={onChange}
                          checked={value}
                        />
                      )}
                    />
                  </TableCell>
                </TableRow>

                {/* Post - Like */}
                <TableRow>
                  <TableCell padding={[1, 1, 1, 0.5]}>
                    <MDBox lineHeight={1.4}>
                      <MDTypography display="block" variant="button" fontWeight="regular">
                        {t('settings.notifications.push.post-like')}
                      </MDTypography>
                      <MDTypography variant="caption" color="text" fontWeight="regular">
                        {t('settings.notifications.push.post-like.info')}
                      </MDTypography>
                    </MDBox>
                  </TableCell>
                  <TableCell align="center" padding={[1, 1, 1, 0.5]}><div /></TableCell>
                  <TableCell align="center" padding={[1, 1, 1, 0.5]}>
                    <Controller
                      name="pushPostLike"
                      control={control}
                      defaultValue={user.pushPostLike}
                      render={({ field: { onChange, value } }) => (
                        <Switch
                          value={value}
                          onChange={onChange}
                          checked={value}
                        />
                      )}
                    />
                  </TableCell>
                </TableRow>

                {/* Workout - Comment */}
                <TableRow>
                  <TableCell padding={[1, 1, 1, 0.5]}>
                    <MDBox lineHeight={1.4}>
                      <MDTypography display="block" variant="button" fontWeight="regular">
                        {t('settings.notifications.push.workout-comment')}
                      </MDTypography>
                      <MDTypography variant="caption" color="text" fontWeight="regular">
                        {t('settings.notifications.push.workout-comment.info')}
                      </MDTypography>
                    </MDBox>
                  </TableCell>
                  <TableCell align="center" padding={[1, 1, 1, 0.5]}><div /></TableCell>
                  <TableCell align="center" padding={[1, 1, 1, 0.5]}>
                    <Controller
                      name="pushWorkoutComment"
                      control={control}
                      defaultValue={user.pushWorkoutComment}
                      render={({ field: { onChange, value } }) => (
                        <Switch
                          value={value}
                          onChange={onChange}
                          checked={value}
                        />
                      )}
                    />
                  </TableCell>
                </TableRow>

                {/* Workout - Like */}
                <TableRow>
                  <TableCell padding={[1, 1, 1, 0.5]}>
                    <MDBox lineHeight={1.4}>
                      <MDTypography display="block" variant="button" fontWeight="regular">
                        {t('settings.notifications.push.workout-like')}
                      </MDTypography>
                      <MDTypography variant="caption" color="text" fontWeight="regular">
                        {t('settings.notifications.push.workout-like.info')}
                      </MDTypography>
                    </MDBox>
                  </TableCell>
                  <TableCell align="center" padding={[1, 1, 1, 0.5]}><div /></TableCell>
                  <TableCell align="center" padding={[1, 1, 1, 0.5]}>
                    <Controller
                      name="pushWorkoutLike"
                      control={control}
                      defaultValue={user.pushWorkoutLike}
                      render={({ field: { onChange, value } }) => (
                        <Switch
                          value={value}
                          onChange={onChange}
                          checked={value}
                        />
                      )}
                    />
                  </TableCell>
                </TableRow>

                {/* Comment - Like */}
                <TableRow>
                  <TableCell padding={[1, 1, 1, 0.5]}>
                    <MDBox lineHeight={1.4}>
                      <MDTypography display="block" variant="button" fontWeight="regular">
                        {t('settings.notifications.push.comment-like')}
                      </MDTypography>
                      <MDTypography variant="caption" color="text" fontWeight="regular">
                        {t('settings.notifications.push.comment-like.info')}
                      </MDTypography>
                    </MDBox>
                  </TableCell>
                  <TableCell align="center" padding={[1, 1, 1, 0.5]}><div /></TableCell>
                  <TableCell align="center" padding={[1, 1, 1, 0.5]}>
                    <Controller
                      name="pushCommentLike"
                      control={control}
                      defaultValue={user.pushCommentLike}
                      render={({ field: { onChange, value } }) => (
                        <Switch
                          value={value}
                          onChange={onChange}
                          checked={value}
                        />
                      )}
                    />
                  </TableCell>
                </TableRow>

                {/* Friend - requested */}
                <TableRow>
                  <TableCell padding={[1, 1, 1, 0.5]}>
                    <MDBox lineHeight={1.4}>
                      <MDTypography display="block" variant="button" fontWeight="regular">
                        {t('settings.notifications.push.friend-request')}
                      </MDTypography>
                      <MDTypography variant="caption" color="text" fontWeight="regular">
                        {t('settings.notifications.push.friend-request.info')}
                      </MDTypography>
                    </MDBox>
                  </TableCell>
                  <TableCell align="center" padding={[1, 1, 1, 0.5]}><div /></TableCell>
                  <TableCell align="center" padding={[1, 1, 1, 0.5]}>
                    <Controller
                      name="pushFriendRequest"
                      control={control}
                      defaultValue={user.pushFriendRequest}
                      render={({ field: { onChange, value } }) => (
                        <Switch
                          value={value}
                          onChange={onChange}
                          checked={value}
                        />
                      )}
                    />
                  </TableCell>
                </TableRow>

                {/* Friend - approved */}
                <TableRow>
                  <TableCell padding={[1, 1, 1, 0.5]}>
                    <MDBox lineHeight={1.4}>
                      <MDTypography display="block" variant="button" fontWeight="regular">
                        {t('settings.notifications.push.friend-request-approval')}
                      </MDTypography>
                      <MDTypography variant="caption" color="text" fontWeight="regular">
                        {t('settings.notifications.push.friend-request-approval.info')}
                      </MDTypography>
                    </MDBox>
                  </TableCell>
                  <TableCell align="center" padding={[1, 1, 1, 0.5]}><div /></TableCell>
                  <TableCell align="center" padding={[1, 1, 1, 0.5]}>
                    <Controller
                      name="pushFriendRequestApproved"
                      control={control}
                      defaultValue={user.pushFriendRequestApproved}
                      render={({ field: { onChange, value } }) => (
                        <Switch
                          value={value}
                          onChange={onChange}
                          checked={value}
                        />
                      )}
                    />
                  </TableCell>
                </TableRow>

                {/* Chat */}
                <TableRow>
                  <TableCell padding={[1, 1, 1, 0.5]}>
                    <MDBox lineHeight={1.4}>
                      <MDTypography display="block" variant="button" fontWeight="regular">
                        {t('settings.notifications.push.chat-message')}
                      </MDTypography>
                      <MDTypography variant="caption" color="text" fontWeight="regular">
                        {t('settings.notifications.push.chat-message.info')}
                      </MDTypography>
                    </MDBox>
                  </TableCell>
                  <TableCell align="center" padding={[1, 1, 1, 0.5]}><div /></TableCell>
                  <TableCell align="center" padding={[1, 1, 1, 0.5]}>
                    <Controller
                      name="pushChatNewMessage"
                      control={control}
                      defaultValue={user.pushChatNewMessage}
                      render={({ field: { onChange, value } }) => (
                        <Switch
                          value={value}
                          onChange={onChange}
                          checked={value}
                        />
                      )}
                    />
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
            <Box mt={2} display="flex" justifyContent="right">
              <MDButton variant="gradient" color="info" size="small" type="submit">
                {t('general.save')}
              </MDButton>
            </Box>
          </MDBox>
        </MDBox>
      </MDBox>
    </Card>
  );
}

Notifications.propTypes = {
  user: PropTypes.objectOf(
    PropTypes.oneOfType([PropTypes.array, PropTypes.object, PropTypes.number, PropTypes.string, PropTypes.bool]),
  )
    .isRequired,
  onSubmit: PropTypes.func.isRequired,
};

export default Notifications;
