import { api, apiSecure } from 'api/axiosInterceptor';

// Auth
export const fetchLoginSettings = async (name) => api.get(`/auth/loginSettings/${name}`);
export const login = async (body) => api.post('/auth/login', body);
export const loginAzure = async (body) => api.post('/auth/login/azure', body);
export const resetPassword = async (username) => api.post(`/auth/resetPassword?username=${username}`);
export const saveNewPassword = async (body) => api.post('/auth/savePassword', body);

// Users
export const submitSurvey = async (id, body) => apiSecure.post(`/users/${id}/survey`, body);
export const getCurrentUser = async () => apiSecure.get('/users/currentUser');
export const fetchUserStatistics = async (id) => apiSecure.get(`/users/${id}/statistics`);
export const fetchMyActivities = async (id) => apiSecure.get(`/users/${id}/myActivities`);
export const fetchUserProfile = async (id) => apiSecure.get(`/users/${id}/profile`);
export const fetchDailyGoal = async (id) => apiSecure.get(`/users/${id}/dailyGoal`);
export const updateUser = async (body) => apiSecure.patch('/users', body);
export const fetchNotifications = async (id) => apiSecure.get(`/users/${id}/notifications`);
export const getNotificationCount = async (id) => apiSecure.get(`/users/${id}/notificationCount`);

// Friends
export const sendFriendRequest = async (id, requestorId) => apiSecure.post(`/users/${id}/friends?requestorId=${requestorId}`);
export const approveFriend = async (id, friendId) => apiSecure.put(`/users/${id}/friends/${friendId}`);
export const removeFriend = async (id, friendId) => apiSecure.delete(`/users/${id}/friends/${friendId}`);

// Activities
export const fetchActivities = async () => {
  try {
    const response = await apiSecure.get('/activities/withWorkouts');
    return response.data;
  } catch (error) {
    return [];
  }
};

// Posts
export const getPosts = async (pageSize, pageNo) => {
  const response = await apiSecure.get(
    '/posts',
    { params: { pageNo, pageSize } },
  );
  return response.data;
};

export const fetchPost = async (id) => apiSecure.get(`/posts/${id}`);
export const submitPost = async (body) => apiSecure.post('/posts/web', body);
export const updatePost = async (body) => apiSecure.patch('/posts', body);
export const deletePost = async (id) => apiSecure.delete(`/posts/${id}`);
export const toggleLike = async (id) => apiSecure.post(`/posts/${id}/toggleLike`);
export const toggleCommentLike = async (id) => apiSecure.post(`/posts/comment/${id}/toggleLike`);
export const addComment = async (id, body) => apiSecure.post(`/posts/${id}/addComment`, body);
export const deleteComment = async (commentId) => apiSecure.delete(`/posts/deleteComment/${commentId}`);

// Challenge
export const fetchStageSummary = async (id) => apiSecure.get(`/challenges/${id}/stageSummary`);
export const fetchChallengeDistance = async (id) => apiSecure.get(`/challenges/${id}/totalDistance`);
export const fetchChallengeDuration = async (id) => apiSecure.get(`/challenges/${id}/totalDuration`);
export const fetchChallengeCO2 = async (id) => apiSecure.get(`/challenges/${id}/co2`);
export const fetchCharityStatus = async (id) => apiSecure.get(`/challenges/${id}/charityStatus`);
export const fetchTimezones = async () => apiSecure.get('/challenges/timezones');
export const fetchTeams = async (id) => apiSecure.get(`/challenges/${id}/teams`);
export const fetchTeamsInUnit = async (id) => apiSecure.get(`/teamUnits/${id}/teams`);
export const fetchTeamUnits = async (id) => apiSecure.get(`/challenges/${id}/teamUnits`);
export const fetchUsers = async (id) => apiSecure.get(`/challenges/${id}/users`);

// Workouts
export const deleteWorkout = async (id) => apiSecure.delete(`/workouts/${id}`);
export const fetchWorkout = async (id) => apiSecure.get(`/workouts/${id}`);
export const fetchWorkouts = async (id) => {
  try {
    const response = await apiSecure.get(`/workouts/user/${id}`);
    return response.data;
  } catch (error) {
    return [];
  }
};

export const saveBasicWorkout = async (body) => {
  try {
    return await apiSecure.post('/workouts/web', body);
  } catch (error) {
    return error;
  }
};

export const updateWorkout = async (id, body) => {
  try {
    return await apiSecure.put(`/workouts/web/${id}`, body);
  } catch (error) {
    return error;
  }
};

export const fetchPhotos = async (pageNo, pageSize, orderBy) => {
  const response = await apiSecure.get(
    '/posts/photos/user',
    { params: { pageNo, pageSize, orderByLikes: (orderBy === 'likes') } },
  );
  return response.data;
};

// Leaderboard
export const fetchLeaderboard = async (url) => apiSecure.get(url);
export const exportLeaderboard = async (stage) => apiSecure.get(
  `/challenges/exportLeaderboard?stage=${stage}`,
  { responseType: 'blob' },
);

// Register
export const registerUser = async (body) => apiSecure.post('/users/register', body);
export const registerTeam = async (body) => apiSecure.post('/teams/register', body);

// File upload
export const getImageInfo = async (fileUrl) => apiSecure.get(`/fileUpload?fileUrl=${fileUrl}`);
export const deleteFile = async (fileUrl) => apiSecure.delete(`/fileUpload?fileUrl=${fileUrl}`);
export const deleteAvatar = async (filename) => apiSecure.delete(`/fileUpload/avatar/${filename}`);

// Tracker
export const connectTracker = async (userId, tracker) => apiSecure.get(`/oauth2/authorization/${tracker}?userId=${userId}`);
export const disconnectTracker = async (tracker) => apiSecure.get(`/oauth2/disconnect?tracker=${tracker}`);

// Chat
export const sendChatNotification = async (userId, body) => apiSecure.post(`/users/${userId}/chatNotification`, body);

// HealthPortal
export const getHealthPortal = async (category) => {
  const response = await apiSecure.get(`/healthPortal/links?category=${category}`);
  return response.data;
};

export const getLiveTraining = async (category) => {
  const response = await apiSecure.get(`/healthPortal/liveTraining/links?category=${category}`);
  return response.data;
};
