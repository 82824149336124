import Stack from '@mui/material/Stack';
import { Controller } from 'react-hook-form';
import Grid from '@mui/material/Grid';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import MDInput from '../../../components/MDInput';

function Count({ workout, control, isDisabled }) {
  const { t } = useTranslation();
  const handleCountBlur = (value, onChange) => {
    if (!value) {
      return onChange(1);
    }
    if (Number.isNaN(value)) {
      return onChange(1);
    }
    const val = parseInt(value, 10);
    if (val > 50000) {
      return onChange(50000);
    }
    if (val < 1) {
      return onChange(1);
    }
    return onChange(val);
  };

  return (
    <Grid item xs={12} md={6} mt={4}>
      <Stack direction="row" spacing={2}>
        <Controller
          name="count"
          control={control}
          defaultValue={workout ? workout.activityCount : 1}
          render={({
            field: { onChange, value },
            fieldState: { error, invalid },
          }) => (
            <MDInput
              helperText={invalid ? error.message : null}
              label={t('general.count')}
              value={value}
              variant="standard"
              size="small"
              onBlur={() => handleCountBlur(value, onChange)}
              onChange={(event) => {
                onChange(event.target.value);
              }}
              inputProps={{
                step: 1,
                min: 1,
                max: 50000,
                type: 'number',
              }}
              error={invalid}
              disabled={isDisabled}
            />
          )}
        />
      </Stack>

    </Grid>
  );
}

Count.defaultProps = {
  workout: null,
  isDisabled: false,
};

Count.propTypes = {
  workout: PropTypes.objectOf(
    PropTypes.oneOfType([PropTypes.array, PropTypes.object, PropTypes.number, PropTypes.string, PropTypes.bool]),
  ),
  control: PropTypes.oneOfType([PropTypes.object]).isRequired,
  isDisabled: PropTypes.bool,
};

export default Count;
