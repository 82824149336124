import Card from '@mui/material/Card';
import { Grid, Paper, Typography } from '@mui/material';
import Box from '@mui/material/Box';
import PropTypes from 'prop-types';
import { memo } from 'react';
import { useTranslation } from 'react-i18next';
import medal1 from '../../../assets/challengize/images/medals/medal_1.png';
import medal2 from '../../../assets/challengize/images/medals/medal_2.png';
import medal3 from '../../../assets/challengize/images/medals/medal_3.png';
import medal4 from '../../../assets/challengize/images/medals/medal_4.png';
import medal5 from '../../../assets/challengize/images/medals/medal_5.png';
import medal6 from '../../../assets/challengize/images/medals/medal_6.png';
import medal7 from '../../../assets/challengize/images/medals/medal_7.png';
import medal8 from '../../../assets/challengize/images/medals/medal_8.png';
import medal9 from '../../../assets/challengize/images/medals/medal_9.png';
import medal10 from '../../../assets/challengize/images/medals/medal_10.png';
import lock from '../../../assets/challengize/images/medals/medal-lock.png';
import MDTypography from '../../../components/MDTypography';

const Line = {
  background: '#FFFFFF',
  color: '#FFFFFF',
  border: 0,
  height: '2px',
  width: '100%',
  marginTop: '6px',
};

const ImageBackground = {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  paddingTop: '10px',
  paddingLeft: '10px',
  paddingRight: '10px',
  backgroundColor: '#5cccf7',
};

const LockOverlay = {
  position: 'absolute',
  bottom: 0,
  left: 0,
  width: '100%',
  height: '100%',
  bgcolor: 'rgba(0, 0, 0, 0.5)',
  color: 'white',
  padding: '10px',
};

const fontStyle = {
  textAlign: 'center',
  fontSize: '15px',
  '@media (max-width:1000px)': {
    fontSize: '14px',
  },
};

const RenderMedals = memo(({
  // eslint-disable-next-line react/prop-types
  medals, numWorkouts, t,
// eslint-disable-next-line react/prop-types
}) => medals.map((medal) => (
  <Grid item xs={6} sm={3} md={2.4} key={medal.count}>
    <Box sx={{ position: 'relative' }}>
      <Paper sx={ImageBackground}>
        <img src={medal.image} width="100%" alt="10 activities" />
        <hr style={Line} />
        <Typography variant="h6" color="#FFFFFF" sx={fontStyle}>
          {medal.count}
          {' '}
          {t('general.activities')}
        </Typography>
      </Paper>
      {numWorkouts < medal.count
            && (
              <Paper sx={LockOverlay}>
                <img src={lock} width="30%" alt="Locked" />
              </Paper>
            )}
    </Box>
  </Grid>
)));

function Medals({ numWorkouts }) {
  const { t } = useTranslation();
  const medals = [
    { count: 10, image: medal1 },
    { count: 20, image: medal2 },
    { count: 30, image: medal3 },
    { count: 40, image: medal4 },
    { count: 50, image: medal5 },
    { count: 60, image: medal6 },
    { count: 70, image: medal7 },
    { count: 80, image: medal8 },
    { count: 90, image: medal9 },
    { count: 100, image: medal10 },
  ];

  return (
    <Box mb={2}>
      <Card>
        <Box pt={2} pl={2}>
          <MDTypography variant="h5">{t('statistics.medals.title')}</MDTypography>
        </Box>
        <Grid container spacing={1} padding={2}>
          <RenderMedals medals={medals} numWorkouts={numWorkouts} t={t} />
        </Grid>
      </Card>
    </Box>
  );
}

Medals.propTypes = {
  numWorkouts: PropTypes.number.isRequired,
};

export default Medals;
