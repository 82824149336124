import { useContext, useEffect, useState } from 'react';
import { Route, Routes, useLocation } from 'react-router-dom';
import { ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';

import Login from 'pages/login/login';
import { Context } from 'store/Store';
import MyWorkouts from 'pages/workouts/myWorkouts';
import AddWorkout from 'pages/workouts/addWorkout/addWorkout';
import EditWorkout from 'pages/workouts/editWorkout';
import Notification from 'pages/notification';

import Profile from 'pages/profile/profile';
import Settings from 'pages/settings/settings';
import Logout from 'pages/logout';
import ProtectedRoute from 'routing/ProtectedRoute';
import Faq from 'pages/faq';
import Dashboard from 'pages/dashboard/dashboard';
import { useTranslation } from 'react-i18next';
import { getAuth, signInAnonymously } from 'firebase/auth';
import { setMiniSidenav, useMaterialUIController } from './context';
import routes from './routes';
import themeDark from './assets/theme-dark';
import theme from './assets/theme';
import Sidenav from './sidenav';
import Statistics from './pages/statistics/statistics';
import Social from './pages/social/social';
import AddPost from './pages/social/posts/addPost';
import Register from './pages/register/register';
import { getCurrentUser, submitSurvey } from './api';
import EditPost from './pages/social/posts/editPost';
import ViewPost from './pages/social/posts/viewPost';
import SurveyDialog from './pages/login/surveyDialog';
import Photowall from './pages/photowall/photowall';
import Chat from './pages/chat';
import Tips from './pages/health-portal/tips';
import LiveTraining from './pages/health-portal/liveTraining';

export default function App() {
  const { t } = useTranslation();
  const [controller, dispatch] = useMaterialUIController();
  const {
    miniSidenav,
    sidenavColor,
    darkMode,
  } = controller;
  const { dispatch: contextDispatch, state } = useContext(Context);
  const { user, loading } = state;

  const [onMouseEnter, setOnMouseEnter] = useState(false);
  const location = useLocation();
  const { pathname } = location;
  const [showSurvey, setShowSurvey] = useState(user !== null && (user.showUserSurvey != null && user.showUserSurvey));

  useEffect(() => {
    const auth = getAuth();
    signInAnonymously(auth);
  }, []);

  useEffect(() => {
    setShowSurvey(user !== null && (user.showUserSurvey != null && user.showUserSurvey));
  }, [location]);

  // Open sidenav when mouse enter on mini sidenav
  const handleOnMouseEnter = () => {
    if (miniSidenav && !onMouseEnter) {
      setMiniSidenav(dispatch, false);
      setOnMouseEnter(true);
    }
  };

  // Close sidenav when mouse leave mini sidenav
  const handleOnMouseLeave = () => {
    if (onMouseEnter) {
      setMiniSidenav(dispatch, true);
      setOnMouseEnter(false);
    }
  };

  // Setting page scroll to 0 when changing the route
  useEffect(() => {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
  }, [pathname]);

  const { dispatch: disp } = useContext(Context);

  useEffect(() => {
    async function findUser() {
      await getCurrentUser()
        .then((res) => {
          disp({ type: 'SET_USER', payload: res.data });
          disp({ type: 'LOADING', payload: false });
        }).catch(() => {
          disp({ type: 'LOADING', payload: false });
        });
    }

    findUser();
  }, []);

  const onSurveySubmit = async (survey) => {
    const res = await submitSurvey(user.id, survey);
    setShowSurvey(false);
    const { data: userResponse } = res;
    contextDispatch({
      type: 'SET_SUCCESS',
      payload: {
        title: t('survey.submitted'),
        content: t('survey.thanks'),
      },
    });

    contextDispatch({ type: 'SET_USER', payload: userResponse });
  };

  const letUserIn = user && !user.forcePasswordUpdate;
  const isChallengeUser = user && user.roles.includes('USER');
  const isRegistratorUser = user && user.roles.includes('REGISTRATOR');
  const isHealthPortalChallenge = user?.team.challenge.healthPortalChallenge;

  return (
    <ThemeProvider theme={darkMode ? themeDark : theme}>
      <CssBaseline />
      <Notification />
      {user && (
      <SurveyDialog
        user={user}
        open={showSurvey}
        onClose={() => setShowSurvey(false)}
        onSurveySubmit={onSurveySubmit}
      />
      )}
      <Sidenav
        color={sidenavColor}
        routes={routes}
        onMouseEnter={handleOnMouseEnter}
        onMouseLeave={handleOnMouseLeave}
      />
      <Routes>
        <Route path="login" element={<Login />} />
        <Route path="login/:id" element={<Login />} />
        <Route
          path="profile"
          element={(
            <ProtectedRoute isAllowed={letUserIn} loading={loading}>
              <Profile />
            </ProtectedRoute>
          )}
        />
        <Route
          path="profile/:id"
          element={(
            <ProtectedRoute isAllowed={letUserIn && isChallengeUser} loading={loading}>
              <Profile />
            </ProtectedRoute>
          )}
        />
        <Route
          path="settings"
          element={(
            <ProtectedRoute isAllowed={letUserIn} loading={loading}>
              <Settings />
            </ProtectedRoute>
          )}
        />
        <Route
          path="logout"
          element={(
            <ProtectedRoute isAllowed={letUserIn} loading={loading}>
              <Logout />
            </ProtectedRoute>
          )}
        />
        <Route
          path="dashboard"
          element={(
            <ProtectedRoute isAllowed={letUserIn && isChallengeUser} loading={loading}>
              <Dashboard />
            </ProtectedRoute>
          )}
        />
        <Route
          path="my-workouts"
          element={(
            <ProtectedRoute isAllowed={letUserIn && isChallengeUser} loading={loading}>
              <MyWorkouts />
            </ProtectedRoute>
          )}
        />
        <Route
          path="add-workout"
          element={(
            <ProtectedRoute isAllowed={letUserIn && isChallengeUser} loading={loading}>
              <AddWorkout />
            </ProtectedRoute>
          )}
        />
        <Route
          path="workouts/editWorkout/:id"
          element={(
            <ProtectedRoute isAllowed={letUserIn && isChallengeUser} loading={loading}>
              <EditWorkout />
            </ProtectedRoute>
          )}
        />
        <Route
          path="statistics"
          element={(
            <ProtectedRoute isAllowed={letUserIn && isChallengeUser} loading={loading}>
              <Statistics />
            </ProtectedRoute>
          )}
        />
        <Route
          path="social"
          element={(
            <ProtectedRoute isAllowed={letUserIn && isChallengeUser} loading={loading}>
              <Social />
            </ProtectedRoute>
          )}
        />
        <Route
          path="chat"
          element={(
            <ProtectedRoute isAllowed={letUserIn && isChallengeUser} loading={loading}>
              <Chat />
            </ProtectedRoute>
          )}
        />
        <Route
          path="photowall"
          element={(
            <ProtectedRoute isAllowed={letUserIn && isChallengeUser} loading={loading}>
              <Photowall />
            </ProtectedRoute>
          )}
        />
        <Route
          path="add-post"
          element={(
            <ProtectedRoute isAllowed={letUserIn && isChallengeUser} loading={loading}>
              <AddPost />
            </ProtectedRoute>
          )}
        />
        <Route
          path="social/posts/editPost/:postId"
          element={(
            <ProtectedRoute isAllowed={letUserIn && isChallengeUser} loading={loading}>
              <EditPost />
            </ProtectedRoute>
          )}
        />
        <Route
          path="social/:postId"
          element={(
            <ProtectedRoute isAllowed={letUserIn && isChallengeUser} loading={loading}>
              <ViewPost />
            </ProtectedRoute>
          )}
        />
        <Route
          path="faq"
          element={(
            <ProtectedRoute isAllowed={letUserIn && isChallengeUser} loading={loading}>
              <Faq />
            </ProtectedRoute>
          )}
        />
        <Route
          path="/health-portal/health-tips"
          element={(
            <ProtectedRoute isAllowed={letUserIn && isHealthPortalChallenge && isChallengeUser} loading={loading}>
              <Tips />
            </ProtectedRoute>
          )}
        />
        <Route
          path="/health-portal/training"
          element={(
            <ProtectedRoute isAllowed={letUserIn && isHealthPortalChallenge && isChallengeUser} loading={loading}>
              <Tips />
            </ProtectedRoute>
          )}
        />
        <Route
          path="/health-portal/live-training"
          element={(
            <ProtectedRoute isAllowed={letUserIn && isHealthPortalChallenge && isChallengeUser} loading={loading}>
              <LiveTraining />
            </ProtectedRoute>
          )}
        />
        <Route
          path="/health-portal/lectures"
          element={(
            <ProtectedRoute isAllowed={letUserIn && isHealthPortalChallenge && isChallengeUser} loading={loading}>
              <LiveTraining />
            </ProtectedRoute>
          )}
        />
        <Route
          path="register"
          element={(
            <ProtectedRoute isAllowed={letUserIn && isRegistratorUser} loading={loading}>
              <Register />
            </ProtectedRoute>
          )}
        />
        {letUserIn && isRegistratorUser
          ? (
            <Route
              path="*"
              element={(
                <ProtectedRoute isAllowed={letUserIn} loading={loading}>
                  <Register />
                </ProtectedRoute>
          )}
            />
          )
          : (
            <Route
              path="*"
              element={(
                <ProtectedRoute isAllowed={letUserIn} loading={loading}>
                  <Dashboard />
                </ProtectedRoute>
              )}
            />
          )}
      </Routes>
    </ThemeProvider>
  );
}
