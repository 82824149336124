import Card from '@mui/material/Card';
import {
  Box, Grid, Paper, Typography,
} from '@mui/material';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import MDTypography from '../../../components/MDTypography';

const ImageBackground = {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  paddingTop: '16px',
  paddingBottom: '16px',
  backgroundColor: '#5dd3ff',
};

const fontStyle = {
  fontSize: '15px',
  '@media (max-width:1000px)': {
    fontSize: '14px',
  },
};

function Me({ user, userStats }) {
  const { t } = useTranslation();
  const {
    distanceInChallenge, hoursInChallenge, pointsInChallenge, avgPointsPerDayInChallenge, teamCurrentPosition, teamTotalPosition,
  } = userStats || {};
  const { competeAsIndividual, team = {} } = user || {};
  const { challenge = {} } = team || {};
  const { challengeStages } = challenge;
  const stageChallenge = challengeStages && challengeStages.length > 1;

  return (
    <Box mb={2}>
      <Card>
        <Box pt={2} pl={2}>
          <MDTypography variant="h5">{t('statistics.me.title')}</MDTypography>
        </Box>
        <Grid container spacing={2} padding={2}>
          <Grid item xs={12} sm={6} md={4}>
            <Paper sx={ImageBackground}>
              <Typography variant="h6" color="#FFFFFF" sx={fontStyle}>{t('statistics.me.teamPosition.stage')}</Typography>
              <Typography variant="h6" color="#FFFFFF" sx={fontStyle}>
                {(teamCurrentPosition && stageChallenge && !competeAsIndividual) ? teamCurrentPosition : 'N/A'}
              </Typography>
            </Paper>
          </Grid>

          <Grid item xs={12} sm={6} md={4}>
            <Paper sx={ImageBackground}>
              <Typography variant="h6" color="#FFFFFF" sx={fontStyle}>{t('statistics.me.my.distance')}</Typography>
              <Typography variant="h6" color="#FFFFFF">{(distanceInChallenge / 1000).toFixed(0)}</Typography>
            </Paper>
          </Grid>

          <Grid item xs={12} sm={6} md={4}>
            <Paper sx={ImageBackground}>
              <Typography variant="h6" color="#FFFFFF" sx={fontStyle}>Challenge Points</Typography>
              <Typography variant="h6" color="#FFFFFF" sx={fontStyle}>{pointsInChallenge}</Typography>
            </Paper>
          </Grid>

          <Grid item xs={12} sm={6} md={4}>
            <Paper sx={ImageBackground}>
              <Typography variant="h6" color="#FFFFFF" sx={fontStyle}>{t('statistics.me.teamPosition.total')}</Typography>
              <Typography variant="h6" color="#FFFFFF" sx={fontStyle}>{competeAsIndividual ? 'N/A' : teamTotalPosition}</Typography>
            </Paper>
          </Grid>

          <Grid item xs={12} sm={6} md={4}>
            <Paper sx={ImageBackground}>
              <Typography variant="h6" color="#FFFFFF" sx={fontStyle}>{t('statistics.me.my.hours')}</Typography>
              <Typography variant="h6" color="#FFFFFF" sx={fontStyle}>{hoursInChallenge}</Typography>
            </Paper>
          </Grid>

          <Grid item xs={12} sm={6} md={4}>
            <Paper sx={ImageBackground}>
              <Typography variant="h6" color="#FFFFFF" sx={fontStyle}>{t('statistics.me.my.average')}</Typography>
              <Typography variant="h6" color="#FFFFFF" sx={fontStyle}>{avgPointsPerDayInChallenge}</Typography>
            </Paper>
          </Grid>
        </Grid>
      </Card>
    </Box>
  );
}

Me.propTypes = {
  user: PropTypes.objectOf(
    PropTypes.oneOfType([PropTypes.array, PropTypes.object, PropTypes.number, PropTypes.string, PropTypes.bool]),
  ).isRequired,
  userStats: PropTypes.oneOfType([PropTypes.array, PropTypes.object]).isRequired,
};

export default Me;
