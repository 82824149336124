import PropTypes from 'prop-types';
import { Stack } from '@mui/material';
import { setMiniSidenav, useMaterialUIController } from 'context';
import Icon from '@mui/material/Icon';
import IconButton from '@mui/material/IconButton';
import MDBox from '../../components/MDBox';
import MDTypography from '../../components/MDTypography';

const navbarDesktopMenu = ({ breakpoints }) => ({
  cursor: 'pointer',

  [breakpoints.up('xl')]: {
    display: 'inline-block !important',
  },
});

function Header({ challenge }) {
  const [controller, dispatch] = useMaterialUIController();
  const { miniSidenav } = controller;

  const handleMiniSidenav = () => setMiniSidenav(dispatch, !miniSidenav);
  return (
    <MDBox mb={2}>
      <MDBox
        display="flex"
        alignItems="flex-end"
        justifyContent="flex-start"
        minHeight="16rem"
        borderRadius="xl"
        sx={{
          backgroundImage: `url(${challenge.customerHeaderUrl})`,
          backgroundSize: 'cover',
          backgroundPosition: '50%',
          overflow: 'hidden',
          position: 'relative',
        }}
      >
        <div style={{ position: 'absolute', top: 10, left: 10 }}>
          <IconButton sx={navbarDesktopMenu} onClick={handleMiniSidenav} size="small" disableRipple>
            <Icon fontSize="medium" sx={{ color: '#757575', backgroundColor: '#FFFFFF', borderRadius: 2 }}>
              {miniSidenav ? 'menu_open' : 'menu'}
            </Icon>
          </IconButton>
        </div>

        <img
          src={challenge.customerLogoUrl}
          style={{
            position: 'absolute', top: 80, left: 20, width: '100px', height: '100px', borderRadius: '4px',
          }}
          alt=""
        />

        <div
          style={{
            width: '100%',
            padding: '10px 0px 10px 20px',
            textShadow: '1px 0.5px 1px black',
          }}
        >
          <Stack>
            <MDTypography
              variant="h3"
              fontWeight="medium"
              color="light"
            >
              {challenge.challengeName}
            </MDTypography>
            <MDTypography
              variant="caption"
              fontWeight="medium"
              color="light"
            >
              {(challenge.startTime).substr(0, 10)}
              {' - '}
              {(challenge.endTime).substr(0, 10)}
            </MDTypography>
          </Stack>
        </div>
      </MDBox>
    </MDBox>
  );
}

Header.propTypes = {
  challenge: PropTypes.objectOf(
    PropTypes.oneOfType([PropTypes.array, PropTypes.object, PropTypes.number, PropTypes.string, PropTypes.bool]),
  )
    .isRequired,
};

export default Header;
