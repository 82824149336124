import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import MDTypography from 'components/MDTypography';

function ProgressLine({
  label,
  backgroundColor = '#e5e5e5',
  visualParts = [
    {
      percentage: '0%',
      color: 'white',
    },
  ],
}) {
  const [widths, setWidths] = useState(
    visualParts.map(() => 0),
  );

  useEffect(() => {
    requestAnimationFrame(() => {
      setWidths(
        visualParts.map((item) => item.percentage),
      );
    });
  }, [visualParts]);

  return (
    <>
      <MDTypography variant="button" fontWeight="medium" color="text">{label}</MDTypography>
      <div
        style={{
          backgroundColor,
          display: 'flex',
          height: '6px',
        }}
      >
        {visualParts.map((item, index) => (
          <div
              /* eslint-disable-next-line react/no-array-index-key */
            key={index}
            style={{
              width: widths[index],
              backgroundColor: item.color,
              borderTopLeftRadius: `${index === 0 ? '0.125rem' : '0px'}`,
              borderBottomLeftRadius: `${index === 0 ? '0.125rem' : '0px'}`,
              borderTopRightRadius: `${index === 1 ? '0.125rem' : '0px'}`,
              borderBottomRightRadius: `${index === 1 ? '0.125rem' : '0px'}`,
              transition: 'width 2s',
            }}
          />
        ))}
      </div>
    </>
  );
}

ProgressLine.propTypes = {
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  backgroundColor: PropTypes.string,
  visualParts: PropTypes.arrayOf(
    PropTypes.oneOfType([PropTypes.object]),
  ).isRequired,
};

ProgressLine.defaultProps = {
  label: '',
  backgroundColor: '#e5e5e5',
};

export default ProgressLine;
