import PropTypes from 'prop-types';
import { Avatar, Box, Paper } from '@mui/material';
import { formatDistanceToNow } from 'date-fns';
import { utcToZonedTime } from 'date-fns-tz';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import IconButton from '@mui/material/IconButton';
import { useTranslation } from 'react-i18next';
import FavoriteIcon from '@mui/icons-material/Favorite';
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';
import { useEffect, useState } from 'react';
import Tooltip from '@mui/material/Tooltip';
import AvatarGroup from '@mui/material/AvatarGroup';
import { stringAvatar } from '../../../utils';
import MDTypography from '../../../components/MDTypography';

function Comment({
  user, onDeleteComment, onToggleCommentLike, comment, locale,
}) {
  const {
    id, avatar, fullName, comment: message, created, userId: commentUserId, likes,
  } = comment;
  const { t } = useTranslation();
  const { id: userId, timezone } = user;

  const [isLiked, setIsLiked] = useState(false);
  const [isLikedDisabled, setIsLikedDisable] = useState(false);

  useEffect(() => {
    if (likes?.some((like) => like.userId === userId)) {
      setIsLiked(true);
    }
  }, [likes]);

  const onToggleLike = () => onToggleCommentLike(id);

  const toggleLike = () => {
    setIsLikedDisable(true);
    setIsLiked(!isLiked);
    onToggleLike();
    setTimeout(() => {
      setIsLikedDisable(false);
    }, 2000);
  };

  const renderLikedBy = () => {
    if (likes?.length > 0) {
      return (
        <AvatarGroup
          total={likes.length}
          max={10}
          sx={{
            '& .MuiAvatar-root': {
              width: 18, height: 18, fontSize: 15,
            },
          }}
        >
          {/* eslint-disable-next-line no-shadow */}
          {likes.map(({ fullName, avatar, id }) => (
            <Tooltip title={fullName} key={id}>
              <Avatar {...stringAvatar(fullName)} src={avatar} />
            </Tooltip>
          ))}
        </AvatarGroup>
      );
    }
    return null;
  };

  return (
    <Box
      display="flex"
      justifyContent="space-between"
      alignItems="center"
      mt={2}
    >
      <Avatar {...stringAvatar(fullName)} src={avatar} />
      <Paper
        elevation={0}
        sx={{
          marginLeft: '16px',
          paddingTop: '10px',
          // paddingLeft: '10px',
          paddingRight: '10px',
          backgroundColor: 'rgb(244, 246, 248)',
          width: '100%',
        }}
      >
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          sx={{ paddingLeft: '10px' }}
        >
          <MDTypography variant="button" fontWeight="medium">{fullName}</MDTypography>
          <MDTypography variant="caption" fontWeight="regular" color="text">
            {formatDistanceToNow(utcToZonedTime(created, timezone), { locale })}
            {t('social.ago')}
          </MDTypography>
        </Box>

        <Box
          display="flex"
          justifyContent="space-between"
          sx={{ paddingLeft: '10px' }}
        >
          <MDTypography variant="button" fontWeight="regular" color="text">{message}</MDTypography>

          { userId === commentUserId && (
            <IconButton
              onClick={() => {
                onDeleteComment(id);
              }}
            >
              <DeleteOutlineIcon
                sx={{ width: 20, height: 20 }}
                color="text"
              />
            </IconButton>
          )}

        </Box>
        <Box
          display="flex"
          // justifyContent="space-between"
          alignItems="center"
        >
          <IconButton
            value="check"
            onClick={() => {
              toggleLike();
            }}
            disabled={isLikedDisabled}
          >
            {isLiked ? (
              <FavoriteIcon
                sx={{ width: 26, height: 26, paddingLeft: 0 }}
                color="error"
              />
            ) : (
              <FavoriteBorderIcon
                sx={{ width: 26, height: 26 }}
                color="error"
              />
            )}
          </IconButton>
          {renderLikedBy()}
        </Box>
      </Paper>
    </Box>
  );
}

Comment.propTypes = {
  comment: PropTypes.objectOf(
    PropTypes.oneOfType([PropTypes.array, PropTypes.object, PropTypes.number, PropTypes.string, PropTypes.bool]),
  ).isRequired,
  onDeleteComment: PropTypes.func.isRequired,
  onToggleCommentLike: PropTypes.func.isRequired,
  user: PropTypes.objectOf(
    PropTypes.oneOfType([PropTypes.array, PropTypes.object, PropTypes.number, PropTypes.string, PropTypes.bool]),
  ).isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  locale: PropTypes.object.isRequired,
};

export default Comment;
