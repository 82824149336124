import PropTypes from 'prop-types';

import MDTypography from 'components/MDTypography';
import {
  Grid, List, ListItem, ListItemText, ListItemButton,
} from '@mui/material';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';

function TeamUnitCell({ teamUnitName, teamNames }) {
  return (
    <Grid container>
      <Grid item>
        <Tooltip title={(
          <List>
            {teamNames.map((name) => (
              <ListItem disablePadding key={name}>
                <ListItemButton>
                  <ListItemText
                    primary={name}
                    primaryTypographyProps={{
                      fontSize: 14,
                      fontWeight: 'medium',
                      color: 'white',
                    }}
                  />
                </ListItemButton>
              </ListItem>
            ))}
          </List>
        )}
        >
          <IconButton>
            <MDTypography variant="button" fontWeight="medium" color="text">
              {teamUnitName}
            </MDTypography>
          </IconButton>
        </Tooltip>
      </Grid>
    </Grid>
  );
}

TeamUnitCell.defaultProps = {
  teamNames: [],
  teamUnitName: '',
};

TeamUnitCell.propTypes = {
  teamUnitName: PropTypes.string,
  teamNames: PropTypes.arrayOf(PropTypes.string),
};

export default TeamUnitCell;
