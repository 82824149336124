import { useContext, useEffect, useState } from 'react';
import Footer from 'comps/Footer';
import Card from '@mui/material/Card';
import { useTranslation, Trans } from 'react-i18next';
import { Link } from '@mui/material';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Icon from '@mui/material/Icon';
import AppBar from '@mui/material/AppBar';
import DashboardLayout from '../../LayoutContainers/DashboardLayout';
import { Context } from '../../store/Store';
import Header from '../header';
import Teams from './teams';
import MDBox from '../../components/MDBox';
import MDTypography from '../../components/MDTypography';
import breakpoints from '../../assets/theme/base/breakpoints';
import Users from './users';

const TEAMS = 'teams';
const USERS = 'users';

function Register() {
  const { t } = useTranslation();
  const { state } = useContext(Context);
  const { user } = state;
  const { challengeName, id } = user.team.challenge;

  const [tabsOrientation, setTabsOrientation] = useState('horizontal');
  const [tabValue, setTabValue] = useState(id === 287 ? 'users' : 'teams');

  useEffect(() => {
    // A function that sets the orientation state of the tabs.
    function handleTabsOrientation() {
      return window.innerWidth < breakpoints.values.sm
        ? setTabsOrientation('vertical')
        : setTabsOrientation('horizontal');
    }

    /**
     The event listener that's calling the handleTabsOrientation function when resizing the window.
     */
    window.addEventListener('resize', handleTabsOrientation);

    // Call the handleTabsOrientation function to set the state with the initial value.
    handleTabsOrientation();

    // Remove event listener on cleanup
    return () => window.removeEventListener('resize', handleTabsOrientation);
  }, [tabsOrientation]);

  const handleSetTabValue = (event, newValue) => setTabValue(newValue);

  return (
    <DashboardLayout>
      <Header challenge={user.team.challenge} />
      <Card>
        <MDBox p={3}>
          <MDTypography variant="h4">
            {t('register.title', { challengeName })}
          </MDTypography>
          {id !== 287 ? (
            <>
              <MDTypography variant="subtitle2">
                {t('register.subtitle')}
              </MDTypography>
              <MDTypography variant="h6" pt={1}>
                {t('register.text1')}
              </MDTypography>
              <MDTypography variant="subtitle2">
                {t('register.text2')}
              </MDTypography>
              <MDTypography variant="h6" pt={1}>
                {t('register.text3')}
              </MDTypography>
              <MDTypography variant="subtitle2">
                {t('register.text4')}
              </MDTypography>

              <MDTypography variant="subtitle2" pt={2}>
                <Trans i18nKey="register.text5">
                  x
                  <Link href="https://www.go.challengize.com/integritetspolicy" target="_blank" variant="subtitle2">x</Link>
                  x
                  <Link href="https://www.go.challengize.com/anvandarvillkor" target="_blank" variant="subtitle2">x</Link>
                </Trans>
              </MDTypography>
              <MDTypography variant="subtitle2" pt={1} pb={3}>
                {t('register.text6')}
              </MDTypography>
            </>
          ) : (
            <>
              <MDTypography variant="subtitle2">
                {t('register.tmp.subtitle')}
              </MDTypography>
              <MDTypography variant="h6" pt={1}>
                {t('register.tmp.text3')}
              </MDTypography>
              <MDTypography variant="subtitle2">
                {t('register.tmp.text4')}
              </MDTypography>
              <MDTypography variant="subtitle2" pt={2}>
                <Trans i18nKey="register.tmp.text5">
                  x
                  <Link href="https://www.go.challengize.com/integritetspolicy" target="_blank" variant="subtitle2">x</Link>
                  x
                  <Link href="https://www.go.challengize.com/anvandarvillkor" target="_blank" variant="subtitle2">x</Link>
                </Trans>
              </MDTypography>
              <MDTypography variant="subtitle2" pt={1} pb={3}>
                {t('register.tmp.text6')}
              </MDTypography>
            </>
          )}

          <AppBar position="sticky">
            <Tabs orientation={tabsOrientation} value={tabValue} onChange={handleSetTabValue}>
              {id !== 287 ? (
                <Tab
                  label={t('register.team.title')}
                  value={TEAMS}
                  icon={(
                    <Icon fontSize="small" sx={{ mt: -0.25 }}>
                      groups
                    </Icon>
                  )}
                />
              ) : null}
              <Tab
                label={t('register.user.title')}
                value={USERS}
                icon={(
                  <Icon fontSize="small" sx={{ mt: -0.25 }}>
                    people
                  </Icon>
                )}
              />
            </Tabs>
          </AppBar>

          { tabValue === TEAMS ? (
            <Teams user={user} />
          ) : null}
          { tabValue === USERS ? (
            <Users user={user} />
          ) : null}
        </MDBox>
      </Card>
      <Footer />
    </DashboardLayout>
  );
}

export default Register;
