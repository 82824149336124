import { useContext, useEffect, useState } from 'react';
import { NavLink, useLocation } from 'react-router-dom';

import PropTypes from 'prop-types';

import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import Link from '@mui/material/Link';
import Icon from '@mui/material/Icon';

import MDBox from 'components/MDBox';
import MDTypography from 'components/MDTypography';
import { useQuery } from 'react-query';

import {
  setMiniSidenav, setTransparentSidenav, setWhiteSidenav, useMaterialUIController,
} from 'context';
import logoImg from 'assets/challengize/images/challengize_logo_white.png';
import { Avatar, Badge } from '@mui/material';
import { useTranslation } from 'react-i18next';
import LogoutIcon from '@mui/icons-material/Logout';
import AccountBoxIcon from '@mui/icons-material/AccountBox';
import SettingsIcon from '@mui/icons-material/Settings';
import { styled } from '@mui/material/styles';
import SidenavCollapse from './SidenavCollapse';
import SidenavList from './SidenavList';
import SidenavItem from './SidenavItem';

import SidenavRoot from './SidenavRoot';

import { Context } from '../store/Store';
import { stringAvatar } from '../utils';
import { getNotificationCount } from '../api';
import { getTotalUnreadMessageCount } from '../pages/chat/firestore';

const StyledBadge = styled(Badge)(({ theme }) => ({
  '& .MuiBadge-badge': {
    border: `1px solid ${theme.palette.background.paper}`,
    padding: '0 4px',
    fontSize: '0.75rem',
    fontWeight: '600',
  },
}));

function Sidenav({ color, routes, ...rest }) {
  const { t } = useTranslation();
  const [openCollapse, setOpenCollapse] = useState(false);
  const [openNestedCollapse, setOpenNestedCollapse] = useState(false);
  const [controller, dispatch] = useMaterialUIController();
  const {
    miniSidenav, transparentSidenav, whiteSidenav, darkMode,
  } = controller;
  const location = useLocation();
  const { pathname } = location;
  const collapseName = pathname.split('/').slice(1)[0];
  const items = pathname.split('/').slice(1);
  const itemParentName = items[1];
  const itemName = items[items.length - 1];
  const { state } = useContext(Context);
  const { user } = state;
  const { id } = user || {};

  let textColor = 'white';

  if (transparentSidenav || (whiteSidenav && !darkMode)) {
    textColor = 'dark';
  } else if (whiteSidenav && darkMode) {
    textColor = 'inherit';
  }

  const closeSidenav = () => setMiniSidenav(dispatch, true);

  const { data } = useQuery(
    ['notificationCount'],
    () => getNotificationCount(id),
    {
      enabled: !!user,
      refetchInterval: 36000,
    },
  );

  const { data: numUnreadChatMessages } = useQuery(
    ['unreadChatMessages', id],
    () => getTotalUnreadMessageCount(id),
    {
      refetchInterval: 36000, // 1 minute
      enabled: !!user,
    },
  );

  useEffect(() => {
    setOpenCollapse(collapseName);
    setOpenNestedCollapse(itemParentName);
  }, []);

  useEffect(() => {
    // A function that sets the mini state of the sidenav.
    function handleMiniSidenav() {
      setMiniSidenav(dispatch, window.innerWidth < 1200);
      setTransparentSidenav(dispatch, window.innerWidth < 1200 ? false : transparentSidenav);
      setWhiteSidenav(dispatch, window.innerWidth < 1200 ? false : whiteSidenav);
    }

    /**
     The event listener that's calling the handleMiniSidenav function when resizing the window.
    */
    window.addEventListener('resize', handleMiniSidenav);

    // Call the handleMiniSidenav function to set the state with the initial value.
    handleMiniSidenav();

    // Remove event listener on cleanup
    return () => window.removeEventListener('resize', handleMiniSidenav);
  }, [dispatch, location]);

  // Render all the nested collapse items from the routes.js
  const renderNestedCollapse = (collapse) => {
    const template = collapse.map(({
      name, route, key, href,
    }) => (href ? (
      <Link
        key={key}
        href={href}
        target="_blank"
        rel="noreferrer"
        sx={{ textDecoration: 'none' }}
      >
        <SidenavItem name={t(name)} nested />
      </Link>
    ) : (
      <NavLink to={route} key={key} sx={{ textDecoration: 'none' }}>
        <SidenavItem name={t(name)} active={route === pathname} nested />
      </NavLink>
    )));

    return template;
  };
  // Render all the collapses from the routes.js
  const renderCollapse = (collapses) => collapses.map(({
    name, collapse, route, href, key,
  }) => {
    let returnValue;

    if (collapse) {
      returnValue = (
        <SidenavItem
          key={key}
          color={color}
          name={t(name)}
          active={key === itemParentName ? 'isParent' : false}
          open={openNestedCollapse === key}
          onClick={({ currentTarget }) => (openNestedCollapse === key && currentTarget.classList.contains('MuiListItem-root')
            ? setOpenNestedCollapse(false)
            : setOpenNestedCollapse(key))}
        >
          {renderNestedCollapse(collapse)}
        </SidenavItem>
      );
    } else {
      returnValue = href ? (
        <Link
          href={href}
          key={key}
          target="_blank"
          rel="noreferrer"
          sx={{ textDecoration: 'none' }}
        >
          <SidenavItem color={color} name={t(name)} active={key === itemName} />
        </Link>
      ) : (
        <NavLink to={route} key={key} sx={{ textDecoration: 'none' }}>
          <SidenavItem color={color} name={t(name)} active={key === itemName} />
        </NavLink>
      );
    }
    return <SidenavList key={key}>{returnValue}</SidenavList>;
  });

  // Render all the routes from the routes.js (All the visible items on the Sidenav)
  const renderRoutes = routes.map(
    ({
      type, name, icon, title, collapse, noCollapse, key, href, route, role,
    }) => {
      let returnValue;
      if ((role && !user?.roles.includes(role))
        || (!user?.team.challenge.healthPortalChallenge && key === 'health-portal')) {
        return null;
      }
      if (type === 'collapse') {
        if (href) {
          returnValue = (
            <Link
              href={href}
              key={key}
              target="_blank"
              rel="noreferrer"
              sx={{ textDecoration: 'none' }}
            >
              <SidenavCollapse
                name={t(name)}
                icon={icon}
                active={key === collapseName}
                noCollapse={noCollapse}
              />
            </Link>
          );
        } else if (noCollapse && route) {
          returnValue = (
            <NavLink to={route} key={key}>
              <SidenavCollapse
                name={t(name)}
                icon={icon}
                noCollapse={noCollapse}
                active={key === collapseName}
                showBadge={key === 'chat'}
                unreadChatMessages={numUnreadChatMessages}
              >
                {collapse ? renderCollapse(collapse) : null}
              </SidenavCollapse>
            </NavLink>
          );
        } else {
          returnValue = (
            <SidenavCollapse
              key={key}
              name={t(name)}
              icon={icon}
              active={key === collapseName}
              open={openCollapse === key}
              onClick={() => (openCollapse === key ? setOpenCollapse(false) : setOpenCollapse(key))}
            >
              {collapse ? renderCollapse(collapse) : null}
            </SidenavCollapse>
          );
        }
      } else if (type === 'title') {
        returnValue = (
          <MDTypography
            key={key}
            color={textColor}
            display="block"
            variant="caption"
            fontWeight="bold"
            textTransform="uppercase"
            pl={3}
            mt={2}
            mb={1}
            ml={1}
          >
            {title}
          </MDTypography>
        );
      } else if (type === 'divider') {
        returnValue = (
          <Divider
            key={key}
            light={
              (!darkMode && !whiteSidenav && !transparentSidenav)
              || (darkMode && !transparentSidenav && whiteSidenav)
            }
          />
        );
      }

      return returnValue;
    },
  );

  return (user && !user.forcePasswordUpdate) && (
  <SidenavRoot
    {...rest}
    variant="permanent"
    ownerState={{
      transparentSidenav, whiteSidenav, miniSidenav, darkMode,
    }}
  >
    <MDBox pt={3} pb={1} px={4} textAlign="center">
      <MDBox
        display={{ xs: 'block', xl: 'none' }}
        position="absolute"
        top={0}
        right={0}
        p={1.625}
        onClick={closeSidenav}
        sx={{ cursor: 'pointer' }}
      >
        <MDTypography variant="h6" color="secondary">
          <Icon sx={{ fontWeight: 'bold' }}>close</Icon>
        </MDTypography>
      </MDBox>
      <MDBox component={NavLink} to="/" alignItems="center">
        <MDBox component="img" src={logoImg} alt="Challengize" width="70%" />
      </MDBox>
    </MDBox>
    <Divider
      light={
            (!darkMode && !whiteSidenav && !transparentSidenav)
            || (darkMode && !transparentSidenav && whiteSidenav)
          }
    />
    <List>
      <SidenavCollapse
        key={user.id}
        name={user.fullName}
        icon={data?.data > 0 ? (
          <StyledBadge
            color="primary"
            badgeContent={data?.data}
          >
            <Avatar {...stringAvatar(user.fullName, 'sm')} src={user.avatar} />
          </StyledBadge>
        ) : (
          <Avatar {...stringAvatar(user.fullName, 'sm')} src={user.avatar} />
        )}
        active={collapseName === 'profile' || collapseName === 'settings'}
        open={openCollapse === user.id}
        onClick={() => (openCollapse === user.id ? setOpenCollapse(false) : setOpenCollapse(user.id))}
      >
        {user.roles.includes('USER') ? (
          <>
            <SidenavList key="profile">
              <NavLink to="/profile" key="profile" sx={{ textDecoration: 'none' }}>
                <SidenavItem
                  color={color}
                  name={t('sidenav.myProfile')}
                  active={itemName === 'profile'}
                  icon={data?.data > 0 ? (
                    <StyledBadge
                      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                      color="primary"
                      badgeContent={data?.data}
                    >
                      <AccountBoxIcon fontSize="small" />
                    </StyledBadge>
                  ) : (
                    <AccountBoxIcon fontSize="small" />
                  )}
                />
              </NavLink>
            </SidenavList>
            <SidenavList key="settings">
              <NavLink to="/settings" key="settings" sx={{ textDecoration: 'none' }}>
                <SidenavItem
                  color={color}
                  name={t('sidenav.settings')}
                  active={itemName === 'settings'}
                  icon={<SettingsIcon fontSize="small" />}
                />
              </NavLink>
            </SidenavList>
          </>
        ) : null}
        <SidenavList key="logout">
          <NavLink to="/logout" key="logout" sx={{ textDecoration: 'none' }}>
            <SidenavItem
              color={color}
              name={t('sidenav.logout')}
              active={itemName === 'logout'}
              icon={<LogoutIcon fontSize="small" />}
            />
          </NavLink>
        </SidenavList>
      </SidenavCollapse>
      {renderRoutes}
    </List>
  </SidenavRoot>
  );
}

// Setting default values for the props of Sidenav
Sidenav.defaultProps = {
  color: 'info',
  title: null,
};

// Typechecking props for the Sidenav
Sidenav.propTypes = {
  color: PropTypes.oneOf(['primary', 'secondary', 'info', 'success', 'warning', 'error', 'dark']),
  routes: PropTypes.arrayOf(PropTypes.object).isRequired,
  title: PropTypes.string,
};

export default Sidenav;
