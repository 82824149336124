import { Paper } from '@mui/material';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import TableContainer from '@mui/material/TableContainer';
import Table from '@mui/material/Table';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import TableBody from '@mui/material/TableBody';
import { useTranslation } from 'react-i18next';
import RegisterUser from './registerUser';
import { fetchTeams, fetchUsers, registerUser } from '../../api';
import MDBox from '../../components/MDBox';

function Users({ user }) {
  const { t } = useTranslation();
  const [teams, setTeams] = useState([]);
  const [users, setUsers] = useState([]);

  const doFetchTeams = async () => {
    const response = await fetchTeams(user.team.challenge.id);
    setTeams(response.data);
  };

  const doFetchUsers = async () => {
    const response = await fetchUsers(user.team.challenge.id);
    setUsers(response.data);
  };

  useEffect(async () => {
    if (user) {
      await doFetchTeams();
      await doFetchUsers();
    }
  }, [user]);

  const onRegisterUser = async (data, reset) => {
    const response = await registerUser({ ...data, teamId: data.team.id });
    setUsers((oldUsers) => [response.data, ...oldUsers]);
    reset();
  };

  const getTeamOptions = () => {
    const individualTeam = teams.find((team) => team.teamName === 'individual_team');
    if (individualTeam) {
      const { id } = individualTeam;
      return [{ id, teamName: t('register.individualTeam') }, ...teams];
    }
    return teams;
  };

  return (
    <MDBox>

      <RegisterUser
        onRegisterUser={onRegisterUser}
        teams={getTeamOptions()}
        metadata={user.team.challenge.metadata}
      />

      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>{t('register.user.name')}</TableCell>
              <TableCell align="left">{t('register.team')}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {users.map((u) => (
              <TableRow
                key={u.id}
                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
              >
                <TableCell component="th" scope="row">
                  {`${u.firstName} ${u.lastName}`}
                </TableCell>
                <TableCell align="left">
                  {u.team.teamName === 'individual_team' ? t('register.individualTeam') : u.team.teamName}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </MDBox>
  );
}

Users.propTypes = {
  user: PropTypes.objectOf(
    PropTypes.oneOfType([PropTypes.array, PropTypes.object, PropTypes.number, PropTypes.string, PropTypes.bool]),
  ).isRequired,
};

export default Users;
