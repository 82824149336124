import MDBox from 'components/MDBox';
import Card from '@mui/material/Card';
import { useEffect, useState } from 'react';
import { styled } from '@mui/material/styles';
import { Box, Stack } from '@mui/material';
import { linearProgressClasses } from '@mui/material/LinearProgress';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import MDProgress from '../../../comps/MDProgress';
import MDTypography from '../../../components/MDTypography';
import { fetchCharityStatus } from '../../../api';

const CharityMDProgress = styled(MDProgress)(({ theme }) => ({
  height: 30,
  borderRadius: 5,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor: theme.palette.grey[theme.palette.mode === 'light' ? 200 : 800],
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 5,
    height: 30,
    backgroundColor: theme.palette.mode === 'light' ? '#FF6471' : '#FF6471',
  },
}));

function Charity({ user }) {
  const { t } = useTranslation();
  const [charityPercentage, setCharityPercentage] = useState(0);
  const [userContribution, setUserContribution] = useState(0);
  const {
    charityReward, charityDescription, charityCurrency,
  } = user.team.challenge;
  const currentValue = (charityReward * (0.01 * charityPercentage)).toFixed(0);

  const doFetchCharityStatus = async (id) => {
    const response = await fetchCharityStatus(id);
    const { charityPercentage: percentage, userContribution: contribution } = response.data;
    setCharityPercentage(percentage);
    setUserContribution(contribution);
  };

  useEffect(async () => {
    await doFetchCharityStatus(user.team.challenge.id);
  }, [user]);

  if (!charityDescription) {
    return null;
  }

  return (
    <MDBox mb={2}>
      <Card>
        <MDBox p={3}>
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            spacing={2}
          >
            <MDTypography variant="h5">{t('charity.title')}</MDTypography>
          </Stack>
          <Box sx={{ flexGrow: 1 }}>
            <CharityMDProgress variant="determinate" value={charityPercentage} label={`${charityPercentage}%`} labelColor="error" />
          </Box>

          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            spacing={2}
          >
            <MDTypography variant="h6" color="error">
              {t('charity.current')}
              {': '}
              {currentValue}
              {' '}
              {charityCurrency}
            </MDTypography>
            <MDTypography variant="h6" mt={1}>
              {t('charity.contribution')}
              {': '}
              {userContribution}
              {' '}
              {charityCurrency}
            </MDTypography>
            <MDTypography variant="h6" color="error">
              {t('charity.goal')}
              {': '}
              {charityReward}
              {' '}
              {charityCurrency}
            </MDTypography>
          </Stack>

          <MDTypography variant="body2" mt={1}>
            {charityDescription}
          </MDTypography>
        </MDBox>
      </Card>
    </MDBox>
  );
}

Charity.propTypes = {
  user: PropTypes.objectOf(
    PropTypes.oneOfType([PropTypes.array, PropTypes.object, PropTypes.number, PropTypes.string, PropTypes.bool]),
  )
    .isRequired,
};

export default Charity;
