import { useContext } from 'react';
import { useQuery } from 'react-query';
import Card from '@mui/material/Card';
import Footer from 'comps/Footer';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import { STALE_TIME } from 'config';
import {
  Box, Button, CardActions, Grid, Stack,
} from '@mui/material';
import CardMedia from '@mui/material/CardMedia';
import CardContent from '@mui/material/CardContent';
import defaultBgImage from 'assets/challengize/images/social/workout_bg.png';
import MDTypography from '../../components/MDTypography';
import DashboardLayout from '../../LayoutContainers/DashboardLayout';
import Header from '../header';
import { Context } from '../../store/Store';
import { getLiveTraining } from '../../api';

function Tips() {
  const location = useLocation();
  const { pathname } = location;
  const category = pathname && pathname === '/health-portal/live-training' ? 'LIVE_TRAINING' : 'LECTURE';
  const { t } = useTranslation();
  const { state } = useContext(Context);
  const { user } = state;
  const { language } = user;

  const { data: links } = useQuery(
    ['links', pathname, language],
    () => getLiveTraining(category),
    {
      staleTime: STALE_TIME.ONE_HOUR.STALE_TIME,
      cacheTime: STALE_TIME.ONE_HOUR.CACHE_TIME,
    },
  );

  const renderItem = (link) => {
    const {
      title,
      description,
      language: lang,
      length,
      author,
      url,
      imageUrl,
    } = link;

    return (
      <Grid item xs={12} md={6} lg={4} key={title}>
        <Card>
          <CardMedia
            height="300"
            sx={{ borderTopRightRadius: '0.75rem', borderTopLeftRadius: '0.75rem' }}
            component="img"
            image={imageUrl || defaultBgImage}
          />
          <CardContent>
            <MDTypography variant="subtitle2" fontWeight="regular" sx={{ lineHeight: 1.4 }}>
              {title}
            </MDTypography>
            <Box display="flex" mt={0.5}>
              <MDTypography variant="button" color="text" fontWeight="regular">
                {description}
              </MDTypography>
            </Box>
          </CardContent>
          <CardActions sx={{ justifyContent: 'space-between' }}>
            <Stack ml={1}>
              <MDTypography variant="button" color="text" fontWeight="regular">
                {author || null}
              </MDTypography>
              <MDTypography variant="button" color="text" fontWeight="regular">
                {length ? `${length} ${t('healthPortal.minutes')}` : null}
                {length && lang ? ', ' : null}
                {lang ? t(`healthPortal.lang.${lang}`) : null}
              </MDTypography>
            </Stack>
            <Button size="small" target="_blank" href={url}>
              {t(`healthPortal.${category.toLowerCase()}.link`)}
            </Button>
          </CardActions>
        </Card>
      </Grid>
    );
  };

  return (
    <DashboardLayout>
      <Header challenge={user.team.challenge} />
      <Card sx={{ marginBottom: 2, padding: 3 }}>
        <Grid container>
          <Grid item xs={12} sm={4}>
            <MDTypography variant="h4">
              {t(`healthPortal.${category.toLowerCase()}`)}
            </MDTypography>
          </Grid>
        </Grid>
      </Card>
      {links && links.length > 0 ? (
        <Grid container spacing={2}>
          {links?.map((hl) => renderItem(hl))}
        </Grid>
      ) : (
        <Box display="flex" justifyContent="flex-start" pl={2}>
          <MDTypography variant="body2">{t(`healthPortal.${category.toLowerCase()}.empty`)}</MDTypography>
        </Box>
      )}
      <Footer />
    </DashboardLayout>
  );
}

export default Tips;
