import Icon from '@mui/material/Icon';
import FitnessCenterIcon from '@mui/icons-material/FitnessCenter';
import LiveHelpIcon from '@mui/icons-material/LiveHelp';
import GroupAddIcon from '@mui/icons-material/GroupAdd';
import EmojiPeopleIcon from '@mui/icons-material/EmojiPeople';
import ChatIcon from '@mui/icons-material/Chat';
import AddCommentIcon from '@mui/icons-material/AddComment';
import AddLocationAltIcon from '@mui/icons-material/AddLocationAlt';
import PhotoLibraryIcon from '@mui/icons-material/PhotoLibrary';
import ForumIcon from '@mui/icons-material/Forum';
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';

const routes = [
  { type: 'divider', key: 'divider-1' },
  {
    type: 'collapse',
    noCollapse: true,
    name: 'Challenge',
    key: 'dashboard',
    icon: <Icon fontSize="small">dashboard</Icon>,
    route: '/dashboard',
    role: 'USER',
  },
  {
    type: 'collapse',
    noCollapse: true,
    name: 'sidenav.social',
    key: 'social',
    icon: <ChatIcon fontSize="small" />,
    route: '/social',
    role: 'USER',
  },
  {
    type: 'collapse',
    noCollapse: true,
    name: 'sidenav.chat',
    key: 'chat',
    icon: <ForumIcon fontSize="small" />,
    route: '/chat',
    role: 'USER',
  },
  {
    type: 'collapse',
    noCollapse: true,
    name: 'sidenav.photowall',
    key: 'photowall',
    icon: <PhotoLibraryIcon fontSize="small" />,
    route: '/photowall',
    role: 'USER',
  },
  {
    type: 'collapse',
    noCollapse: true,
    name: 'sidenav.addSocial',
    key: 'add-post',
    icon: <AddCommentIcon fontSize="small" />,
    route: '/add-post',
    role: 'USER',
  },
  {
    type: 'collapse',
    noCollapse: true,
    name: 'sidenav.myStats',
    key: 'statistics',
    icon: <EmojiPeopleIcon fontSize="small" />,
    route: '/statistics',
    role: 'USER',
  },
  {
    type: 'collapse',
    noCollapse: true,
    name: 'sidenav.myWorkouts',
    key: 'my-workouts',
    route: '/my-workouts',
    icon: <FitnessCenterIcon fontSize="small" />,
    role: 'USER',
  },
  {
    type: 'collapse',
    noCollapse: true,
    name: 'sidenav.addWorkout',
    key: 'add-workout',
    route: '/add-workout',
    icon: <AddLocationAltIcon fontSize="small" />,
    role: 'USER',
  },
  {
    type: 'collapse',
    name: 'sidenav.healthPortal',
    key: 'health-portal',
    icon: <AccountBalanceIcon fontSize="small" />,
    role: 'USER',
    collapse: [
      {
        name: 'sidenav.healthTips',
        key: 'health-tips',
        route: '/health-portal/health-tips',
      },
      {
        name: 'sidenav.training',
        key: 'training',
        route: '/health-portal/training',
      },
      {
        name: 'sidenav.liveTraining',
        key: 'live-training',
        route: '/health-portal/live-training',
      },
      {
        name: 'sidenav.lectures',
        key: 'lectures',
        route: '/health-portal/lectures',
      },
    ],
  },
  {
    type: 'collapse',
    noCollapse: true,
    name: 'F.A.Q',
    key: 'faq',
    icon: <LiveHelpIcon fontSize="small" />,
    route: '/faq',
    role: 'USER',
  },
  { type: 'divider', key: 'divider-2' },
  {
    type: 'collapse',
    noCollapse: true,
    name: 'sidenav.register',
    key: 'register',
    icon: <GroupAddIcon fontSize="small" />,
    route: '/register',
    role: 'REGISTRATOR',
  },
];

export default routes;
