import { useEffect, useState } from 'react';
import Card from '@mui/material/Card';
import CardMedia from '@mui/material/CardMedia';
import CardContent from '@mui/material/CardContent';
import IconButton from '@mui/material/IconButton';
import EditIcon from '@mui/icons-material/Edit';

import PropTypes from 'prop-types';
import { Avatar, Box } from '@mui/material';
import { formatDistanceToNow } from 'date-fns';
import SendIcon from '@mui/icons-material/Send';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import { styled } from '@mui/material/styles';
import Collapse from '@mui/material/Collapse';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import bgImageWorkout from 'assets/challengize/images/social/workout_bg.png';
import bgImageBonus from 'assets/challengize/images/social/bonus_bg.png';
import MDTypography from '../../../components/MDTypography';
import MDBox from '../../../components/MDBox';
import Comment from './comment';
import Likes from './likes';
import {
  addComment, deleteComment, toggleLike, toggleCommentLike,
} from '../../../api';
import MDInput from '../../../components/MDInput';
import { stringAvatar, getLocale } from '../../../utils';

const ExpandMore = styled((props) => {
  const { expand, ...other } = props;
  return <IconButton {...other} />;
})(({ theme, expand }) => ({
  transform: !expand ? 'rotate(0deg)' : 'rotate(180deg)',
  transition: theme.transitions.create('transform', {
    duration: theme.transitions.duration.shortest,
  }),
}));

function PostEntry({ user, post, activities }) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [currentPost, updateCurrentPost] = useState(post);
  const [comment, setComment] = useState('');

  useEffect(() => {
    updateCurrentPost(post);
  }, [post]);

  const locale = getLocale(user.language);

  const {
    id: postId, comments, likes, message, fullName, created, avatar, imageUrl, userId: postUserId,
    activity, challengePoints, fileType,
  } = currentPost;
  const { id: userId, avatar: currentUserAvatar, fullName: currentUserFullName } = user;

  const activityObject = activities?.find((a) => a?.activityName.toLowerCase() === activity?.toLowerCase());
  const { bonusActivity } = activityObject || {};

  const bgImage = bonusActivity ? bgImageBonus : bgImageWorkout;

  const visibleComments = comments.slice(0, 2);
  const hiddenComments = comments.slice(2);

  const [expanded, setExpanded] = useState(false);

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  const onToggleLike = async () => {
    const response = await toggleLike(postId);
    updateCurrentPost(response.data);
  };

  const onToggleCommentLike = async (commentId) => {
    const response = await toggleCommentLike(commentId);
    updateCurrentPost(response.data);
  };

  const onAddComment = async () => {
    if (comment && comment.length > 0) {
      const response = await addComment(postId, { comment });
      setComment('');
      updateCurrentPost(response.data);
    }
  };

  const onDeleteComment = async (commentId) => {
    const response = await deleteComment(commentId);
    updateCurrentPost(response.data);
  };

  const navToEdit = () => navigate(`/social/posts/editPost/${postId}`);

  return (
    <Card sx={{ marginBottom: '16px' }}>
      <MDBox display="flex" alignItems="center" py={2} pl={2}>
        <MDBox mr={2}>
          <Avatar {...stringAvatar(fullName)} src={avatar} />
        </MDBox>
        <MDBox display="flex" flexDirection="column" alignItems="flex-start" justifyContent="center">
          <MDTypography variant="button" fontWeight="medium">
            {fullName}
          </MDTypography>
          <MDTypography variant="caption" fontWeight="regular" color="text">
            {formatDistanceToNow(new Date(created), { locale })}
            {t('social.ago')}
          </MDTypography>
        </MDBox>
        {postUserId === userId && (
          <MDBox ml="auto" mr={1}>
            <IconButton onClick={() => navToEdit()}>
              <EditIcon />
            </IconButton>
          </MDBox>
        )}
      </MDBox>

      <Box sx={{ position: 'relative' }}>
        {fileType === 'VIDEO'
          ? (
            <CardMedia
              height="300"
              component="video"
              src={imageUrl}
              controls
              autoPlay
              muted
            />
          )
          : (
            <CardMedia
              height="300"
              component="img"
              image={imageUrl || bgImage}
            />
          )}
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          sx={{
            position: 'absolute',
            bottom: 0,
            left: 0,
            width: '100%',
            color: 'white',
            padding: '0px 10px 0px 10px',
          }}
        >
          <Box>
            <MDTypography variant="h6" color="light" sx={{ textShadow: '1px 0.5px 1px black' }}>
              {activityObject && activityObject.translation ? activityObject.translation : activity}
            </MDTypography>
            {bonusActivity ? (
              <MDTypography variant="subtitle2" color="light" sx={{ textShadow: '1px 0.5px 1px black' }}>
                {t('social.bonus_activity')}
              </MDTypography>
            ) : null}
          </Box>
          <MDTypography variant="h2" color="light" sx={{ textShadow: '1px 0.5px 1px black' }}>
            {challengePoints}
          </MDTypography>
        </Box>
      </Box>

      <CardContent>
        <Likes onToggleLike={onToggleLike} userId={userId} likes={likes} />

        <Box display="flex">
          <MDTypography variant="button" fontWeight="regular" color="text">
            {message}
          </MDTypography>
        </Box>

        {visibleComments.map((c) => (
          <Comment
            comment={c}
            user={user}
            onDeleteComment={onDeleteComment}
            onToggleCommentLike={onToggleCommentLike}
            locale={locale}
            key={c.id}
          />
        ))}

        {hiddenComments.length > 0 && (
        <Box display="flex" justifyContent="flex-end" alignItems="center">
          <MDTypography variant="caption" color="text">
            {expanded ? t('social.hide') : t('social.show', { count: hiddenComments.length })}
          </MDTypography>
          <ExpandMore
            expand={expanded}
            onClick={handleExpandClick}
            aria-expanded={expanded}
            aria-label="show more"
          >
            <ExpandLessIcon />
          </ExpandMore>
        </Box>
        )}

        <Collapse in={expanded} timeout="auto" unmountOnExit>
          {hiddenComments.map((c) => (
            <Comment
              comment={c}
              user={user}
              onDeleteComment={onDeleteComment}
              onToggleCommentLike={onToggleCommentLike}
              locale={locale}
              key={c.id}
            />
          ))}
        </Collapse>

        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          mt={2}
        >
          <Avatar {...stringAvatar(currentUserFullName)} src={currentUserAvatar} />
          <MDInput
            onChange={(newValue) => {
              setComment(newValue.target.value);
            }}
            value={comment}
            sx={{ width: '100%', marginLeft: '16px', marginRight: '8px' }}
            label="Write a comment..."
          />
          <IconButton
            onClick={() => {
              onAddComment();
            }}
          >
            <SendIcon
              sx={{ width: 24, height: 24 }}
              color="text"
            />
          </IconButton>
        </Box>
      </CardContent>

    </Card>
  );
}

PostEntry.defaultProps = {
  activities: [],
};

PostEntry.propTypes = {
  post: PropTypes.objectOf(
    PropTypes.oneOfType([PropTypes.array, PropTypes.object, PropTypes.number, PropTypes.string, PropTypes.bool]),
  ).isRequired,
  user: PropTypes.objectOf(
    PropTypes.oneOfType([PropTypes.array, PropTypes.object, PropTypes.number, PropTypes.string, PropTypes.bool]),
  ).isRequired,
  activities: PropTypes.arrayOf(PropTypes.object),
};

export default PostEntry;
