import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Grid from '@mui/material/Grid';
import AppBar from '@mui/material/AppBar';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Icon from '@mui/material/Icon';
import breakpoints from 'assets/theme/base/breakpoints';

import {
  MenuItem, Select, FormControl, IconButton, Stack,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import InfoIcon from '@mui/icons-material/Info';
import { styled } from '@mui/material/styles';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import MDTypography from '../../../components/MDTypography';

const CustomWidthTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))({
  [`& .${tooltipClasses.tooltip}`]: {
    maxWidth: 300,
  },
});

function Header({
  setTab, stage, changeStage, user,
}) {
  const { t } = useTranslation();
  const { competeAsIndividual, team } = user;
  const { challenge } = team;
  const {
    unitChallenge, challengeStages, maxPointsPerDay, maxBonusPointsPerDay,
  } = challenge;
  const [tabsOrientation, setTabsOrientation] = useState('horizontal');
  const [tabValue, setTabValue] = useState('teams');

  useEffect(() => {
    // A function that sets the orientation state of the tabs.
    function handleTabsOrientation() {
      return window.innerWidth < breakpoints.values.sm
        ? setTabsOrientation('vertical')
        : setTabsOrientation('horizontal');
    }

    /**
     The event listener that's calling the handleTabsOrientation function when resizing the window.
     */
    window.addEventListener('resize', handleTabsOrientation);

    // Call the handleTabsOrientation function to set the state with the initial value.
    handleTabsOrientation();

    // Remove event listener on cleanup
    return () => window.removeEventListener('resize', handleTabsOrientation);
  }, [tabsOrientation]);

  const handleChange = (event) => {
    changeStage(event.target.value);
  };

  const handleSetTabValue = (event, newValue) => {
    setTab(newValue);
    return setTabValue(newValue);
  };

  return (
    <Grid container spacing={1} alignItems="center">
      <Grid item xs={12} md={12} lg={3}>
        <Stack direction="row" justifyContent="left" alignItems="center">
          <MDTypography variant="h5" fontWeight="medium">
            Scoreboard
          </MDTypography>
          <CustomWidthTooltip
            arrow={false}
            title={(
              <MDTypography color="inherit" variant="body2" style={{ whiteSpace: 'pre-line', textAlign: 'left' }}>
                {t('scoreboard.info', { maxPointsPerDay, maxBonusPointsPerDay })}
              </MDTypography>
          )}
          >
            <IconButton>
              <InfoIcon color="info" sx={{ fontSize: '30px' }} />
            </IconButton>
          </CustomWidthTooltip>
        </Stack>
      </Grid>
      { challenge?.challengeStages?.length > 1 ? (
        <Grid item xs={12} md={5} lg={4}>
          <FormControl sx={{ m: 1, minWidth: 120 }}>
            <Select
              value={stage}
              onChange={handleChange}
              displayEmpty
              variant="standard"
            >
              {challengeStages.map((s, index) => (
                <MenuItem value={index + 1} key={s.id}>
                  {`${t('scoreboard.stage')} (${index + 1}/${challengeStages.length})  : `}
                  {(s.startTime).substr(0, 10)}
                  {' - '}
                  {(s.endTime).substr(0, 10)}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
      ) : null}
      <Grid item xs={12} md={7} lg={5} sx={{ ml: 'auto' }}>
        <AppBar position="static">
          <Tabs orientation={tabsOrientation} value={tabValue} onChange={handleSetTabValue}>
            { unitChallenge && (
              <Tab
                label={t('scoreboard.teamUnits')}
                value="team_units"
                icon={(
                  <Icon fontSize="small" sx={{ mt: -0.25 }}>
                    groups
                  </Icon>
                    )}
              />
            )}
            <Tab
              label={t('scoreboard.teams')}
              value="teams"
              icon={(
                <Icon fontSize="small" sx={{ mt: -0.25 }}>
                  groups
                </Icon>
                  )}
            />
            <Tab
              label={t('scoreboard.users')}
              value="users"
              icon={(
                <Icon fontSize="small" sx={{ mt: -0.25 }}>
                  people
                </Icon>
                  )}
            />
            {competeAsIndividual ? null : (
              <Tab
                label={t('scoreboard.myTeam')}
                value="my_team"
                icon={(
                  <Icon fontSize="small" sx={{ mt: -0.25 }}>
                    person
                  </Icon>
                )}
              />
            )}
          </Tabs>
        </AppBar>
      </Grid>
    </Grid>
  );
}

Header.propTypes = {
  setTab: PropTypes.func.isRequired,
  stage: PropTypes.number.isRequired,
  changeStage: PropTypes.func.isRequired,
  user: PropTypes.objectOf(
    PropTypes.oneOfType([PropTypes.array, PropTypes.object, PropTypes.number, PropTypes.string, PropTypes.bool]),
  ).isRequired,
};

export default Header;
