/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { forwardRef } from 'react';
import PropTypes from 'prop-types';
import MDTypography from 'components/MDTypography';
import MDProgressRoot from 'components/MDProgress/MDProgressRoot';

const MDProgress = forwardRef(({
  variant, color, value, label, labelColor, ...rest
}, ref) => (
  <>
    {label && (
      <MDTypography variant="button" fontWeight="medium" color={labelColor}>
        {label}
      </MDTypography>
    )}
    <MDProgressRoot
      {...rest}
      ref={ref}
      variant="determinate"
      value={value}
      ownerState={{ color, value, variant }}
    />
  </>
));

MDProgress.defaultProps = {
  variant: 'contained',
  color: 'info',
  labelColor: 'text',
  value: 0,
  label: 0,
};

MDProgress.propTypes = {
  variant: PropTypes.oneOf(['contained', 'gradient', 'determinate']),
  color: PropTypes.oneOf([
    'primary',
    'secondary',
    'info',
    'success',
    'warning',
    'error',
    'light',
    'dark',
  ]),
  labelColor: PropTypes.string,
  value: PropTypes.number,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

export default MDProgress;
