import { useTranslation } from 'react-i18next';
import {
  useContext, useEffect, useRef, useState,
} from 'react';
import Card from '@mui/material/Card';
import { Avatar, Box, TextField } from '@mui/material';
import CardMedia from '@mui/material/CardMedia';
import CardContent from '@mui/material/CardContent';
import { useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Context } from '../../../store/Store';
import DashboardLayout from '../../../LayoutContainers/DashboardLayout';
import Header from '../../header';
import MDBox from '../../../components/MDBox';
import MDTypography from '../../../components/MDTypography';
import Footer from '../../../comps/Footer';
import { stringAvatar, stripParams } from '../../../utils';
import MDButton from '../../../components/MDButton';
import { submitPost, updatePost, deletePost } from '../../../api';
import FileUpload from './fileUpload';
import PostChoice from '../../workouts/addWorkout/postChoice';

const VISIBILITY_ALL = 'ALL';

function AddPost({ post }) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { state, dispatch } = useContext(Context);
  const { user } = state;
  const { fullName, avatar } = user;
  const [message, setMessage] = useState('');
  const filePond = useRef(null);
  const [buttonsDisabled, setButtonsDisable] = useState(false);
  const [visibility, setVisibility] = useState(VISIBILITY_ALL);
  const [isEdit, setIsEdit] = useState(false);

  useEffect(async () => {
    if (post) {
      setMessage(post.message);
      setIsEdit(true);
      setVisibility(post.visibility);
    }
  }, [post]);

  const handleSubmit = async () => {
    if (isEdit) {
      const body = {
        userId: user.id,
        postId: post.id,
        message,
        imageUrl: filePond.current.getFile() ? stripParams(filePond.current.getFile().serverId) : null,
      };

      if (filePond.current.getFile()) {
        body.visibility = visibility;
      }

      const response = await updatePost(body);

      if (response.status === 200) {
        dispatch({
          type: 'SET_SUCCESS',
          payload: {
            title: t('posts.add.success'),
            content: '',
          },
        });
        return navigate('/social');
      }
      return null;
    }

    const body = {
      userId: user.id,
      message,
      imageUrl: filePond.current.getFile() ? filePond.current.getFile().serverId : null,
    };

    if (filePond.current.getFile()) {
      body.visibility = visibility;
    }

    const response = await submitPost(body);

    if (response.status === 200) {
      dispatch({
        type: 'SET_SUCCESS',
        payload: {
          title: t('posts.add.success'),
          content: '',
        },
      });
      return navigate('/social');
    }
    return null;
  };

  const handleDelete = async () => {
    const response = await deletePost(post.id);
    if (response.status === 204) {
      dispatch({
        type: 'SET_SUCCESS',
        payload: {
          title: t('posts.delete.success'),
          content: '',
        },
      });
      return navigate('/social');
    }
    return null;
  };

  const onToggleButtons = () => {
    setVisibility(VISIBILITY_ALL);
    setButtonsDisable(!buttonsDisabled);
  };

  const hideVisibility = () => {
    setVisibility(null);
  };

  return (
    <DashboardLayout>
      <Header challenge={user.team.challenge} />
      <Card sx={{ maxWidth: 400 }}>
        <MDBox display="flex" alignItems="center" py={2} pl={2}>
          <MDBox mr={2}>
            <Avatar {...stringAvatar(fullName)} src={avatar} />
          </MDBox>
          <MDBox display="flex" flexDirection="column" alignItems="flex-start" justifyContent="center">
            <MDTypography variant="button" fontWeight="medium">
              {fullName}
            </MDTypography>
            <MDTypography variant="caption" fontWeight="regular" color="text">
              {post ? t('posts.edit') : t('posts.add')}
            </MDTypography>
          </MDBox>
        </MDBox>

        <Box sx={{ position: 'relative' }}>
          <CardMedia>
            <FileUpload
              filePond={filePond}
              imageUrl={post ? post.imageUrl : null}
              toggleButtons={onToggleButtons}
              hideVisibility={hideVisibility}
            />
          </CardMedia>
        </Box>
        <CardContent>
          { (post?.imageUrl || filePond?.current?.getFile()) && visibility ? (
            <Box pb={3}>
              <PostChoice setVisibility={setVisibility} visibility={visibility} />
            </Box>
          ) : null}
          <Box mt={1} display="flex">
            <TextField
              label={t('posts.comment.placeholder')}
              multiline
              fullWidth
              rows={4}
              value={message}
              onChange={(e) => setMessage(e.target.value)}
            />
          </Box>
          <Box mt={4} display="flex" justifyContent="right">
            {isEdit ? (
              <MDButton
                disabled={buttonsDisabled}
                variant="gradient"
                color="warning"
                onClick={handleDelete}
                sx={{ marginRight: 3 }}
              >
                {t('general.delete')}
              </MDButton>
            ) : (null)}
            <MDButton
              disabled={buttonsDisabled}
              variant="gradient"
              color="info"
              onClick={handleSubmit}
            >
              {t('general.save')}
            </MDButton>
          </Box>
        </CardContent>

      </Card>
      <Footer />
    </DashboardLayout>
  );
}

AddPost.defaultProps = {
  post: null,
};

AddPost.propTypes = {
  post: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
};

export default AddPost;
