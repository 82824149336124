import * as yup from 'yup';
import { addMinutes, differenceInMinutes } from 'date-fns';

const workoutSchema = yup.object().shape({
  startTime: yup
    .date()
    .nullable()
    .required('Start time is required')
    .test(
      'beforeStart',
      'Workout must end before the current time, please adjust your start time or duration',
      function (item) {
        return differenceInMinutes(addMinutes(item, (this.parent.hours * 60) + this.parent.minutes), new Date()) < 5;
      },
    )
    .transform((v) => (v instanceof Date && !Number.isNaN(v) ? v : null)),
  activity: yup.object().nullable().required('Activity is required'),
  distance: yup.number()
    .when('activity', {
      is: (activity) => (activity && !activity.countActivity && activity.distanceMandatory),
      then: yup.number().min(0.1, 'Distance is required'),
    }),
  minutes: yup.number()
    .when(['hours', 'activity'], {
      is: (hours, activity) => (activity && !activity.countActivity && hours === 0),
      then: yup.number().min(1, 'Duration is required'),
    }),
  hours: yup.number(),
});

export default workoutSchema;
