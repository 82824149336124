import PropTypes from 'prop-types';

import Collapse from '@mui/material/Collapse';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Icon from '@mui/material/Icon';

import MDBox from 'components/MDBox';

import { useMaterialUIController } from 'context';
import { styled } from '@mui/material/styles';
import { Badge } from '@mui/material';
import {
  collapseArrow, collapseIcon, collapseIconBox, collapseItem, collapseText,
} from './styles/sidenavCollapse';

const StyledBadge = styled(Badge)(({ theme }) => ({
  '& .MuiBadge-badge': {
    border: `1px solid ${theme.palette.background.paper}`,
    padding: '0 4px',
    fontSize: '0.75rem',
    fontWeight: '600',
  },
}));

function SidenavCollapse({
  icon, name, children, active, noCollapse, open, showBadge, unreadChatMessages, ...rest
}) {
  const [controller] = useMaterialUIController();
  const {
    miniSidenav, transparentSidenav, whiteSidenav, darkMode,
  } = controller;

  return (
    <>
      <ListItem component="li">
        <MDBox
          {...rest}
          sx={(theme) => collapseItem(theme, {
            active, transparentSidenav, whiteSidenav, darkMode,
          })}
        >
          <ListItemIcon
            sx={(theme) => collapseIconBox(theme, { transparentSidenav, whiteSidenav, darkMode })}
          >
            {typeof icon === 'string' ? (
              <Icon sx={(theme) => collapseIcon(theme, { active })}>{icon}</Icon>
            ) : (
              icon
            )}
          </ListItemIcon>

          <ListItemText
            primary={name}
            sx={(theme) => collapseText(theme, {
              miniSidenav,
              transparentSidenav,
              whiteSidenav,
              active,
            })}
          />

          {showBadge && unreadChatMessages > 0 ? (
            <StyledBadge
              anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
              color="primary"
              badgeContent={unreadChatMessages}
            >
              <Icon
                sx={(theme) => collapseArrow(theme, {
                  noCollapse,
                  transparentSidenav,
                  whiteSidenav,
                  miniSidenav,
                  open,
                  active,
                  darkMode,
                })}
              >
                expand_less
              </Icon>
            </StyledBadge>
          )
            : (
              <Icon
                sx={(theme) => collapseArrow(theme, {
                  noCollapse,
                  transparentSidenav,
                  whiteSidenav,
                  miniSidenav,
                  open,
                  active,
                  darkMode,
                })}
              >
                expand_less
              </Icon>
            )}
        </MDBox>
      </ListItem>
      {children && (
        <Collapse in={open} unmountOnExit>
          {children}
        </Collapse>
      )}
    </>
  );
}

// Setting default values for the props of SidenavCollapse
SidenavCollapse.defaultProps = {
  active: false,
  noCollapse: false,
  children: false,
  open: false,
  showBadge: false,
  unreadChatMessages: 0,
};

// Typechecking props for the SidenavCollapse
SidenavCollapse.propTypes = {
  icon: PropTypes.node.isRequired,
  name: PropTypes.string.isRequired,
  children: PropTypes.node,
  active: PropTypes.bool,
  noCollapse: PropTypes.bool,
  open: PropTypes.bool,
  showBadge: PropTypes.bool,
  unreadChatMessages: PropTypes.number,
};

export default SidenavCollapse;
