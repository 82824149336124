import * as React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import Store from 'store/Store';
import { QueryClient, QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';
import './i18n';
import { MsalProvider } from '@azure/msal-react';
import { PublicClientApplication } from '@azure/msal-browser';
import { msalConfig } from 'config';
import App from './App';
import { MaterialUIControllerProvider } from './context';
import { AxiosInterceptor } from './api/axiosInterceptor';

const queryClient = new QueryClient();
const msalInstance = new PublicClientApplication(msalConfig);

ReactDOM.render(
  <BrowserRouter>
    <Store>
      <AxiosInterceptor>
        <MaterialUIControllerProvider>
          <QueryClientProvider client={queryClient}>
            <React.Suspense fallback="loading...">
              <MsalProvider instance={msalInstance}>
                <App />
              </MsalProvider>
              <ReactQueryDevtools initialIsOpen={false} />
            </React.Suspense>
          </QueryClientProvider>
        </MaterialUIControllerProvider>
      </AxiosInterceptor>
    </Store>
  </BrowserRouter>,
  document.getElementById('root'),
);
