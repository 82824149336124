import { useEffect, useState } from 'react';

import {
  useAsyncDebounce, usePagination, useSortBy, useTable,
} from 'react-table';

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import Icon from '@mui/material/Icon';
import Autocomplete from '@mui/material/Autocomplete';

import MDBox from 'components/MDBox';
import MDTypography from 'components/MDTypography';
import MDInput from 'components/MDInput';
import MDPagination from 'components/MDPagination';

import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import DataTableHeadCell from './DataTableHeadCell';
import DataTableBodyCell from './DataTableBodyCell';
// eslint-disable-next-line import/no-cycle
import {
  PAGE_CHANGED,
  PAGE_FILTER_CHANGED,
  PAGE_SIZE_CHANGED,
  PAGE_SORT_CHANGED,
  TOTAL_COUNT_CHANGED,
} from '../scoreboard';

import MDButton from '../../../../components/MDButton';

function DataTable({
  columns,
  data,
  dispatch,
  queryPageIndex,
  queryPageSize,
  queryPageSortBy,
  totalCount,
  filter,
  setFilter,
  selectedTab,
  stage,
  maxPoints,
  minTeamSize,
  onExport,
}) {
  const totalPageCount = Math.ceil(totalCount / queryPageSize);
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    rows,
    page,
    canPreviousPage,
    canNextPage,
    pageOptions,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    state: { pageIndex, pageSize, sortBy },
  } = useTable(
    {
      columns,
      data: data?.results || [],
      initialState: {
        pageIndex: queryPageIndex,
        pageSize: queryPageSize,
        sortBy: queryPageSortBy,
      },
      manualPagination: true,
      pageCount: data ? totalPageCount : null,
      autoResetSortBy: false,
      manualSortBy: true,
      autoResetExpanded: false,
      autoResetPage: false,
    },
    useSortBy,
    usePagination,
  );
  const { t } = useTranslation();
  const [searchQuery, setSearchQuery] = useState('');

  const onClickFilterCallback = (f) => {
    setSearchQuery(f);
  };

  // Search input state handle
  const onFilterChange = useAsyncDebounce((value) => {
    onClickFilterCallback(value || undefined);
  }, 100);

  useEffect(() => {
    dispatch({ type: PAGE_CHANGED, payload: pageIndex });
  }, [pageIndex]);

  useEffect(() => {
    dispatch({ type: PAGE_SIZE_CHANGED, payload: pageSize });
    gotoPage(0);
  }, [pageSize, gotoPage]);

  useEffect(() => {
    dispatch({ type: PAGE_SORT_CHANGED, payload: sortBy });
    gotoPage(0);
  }, [sortBy, gotoPage]);

  useEffect(() => {
    dispatch({ type: PAGE_FILTER_CHANGED, payload: searchQuery });
    gotoPage(0);
  }, [searchQuery, gotoPage]);

  useEffect(() => {
    if (data?.count) {
      dispatch({
        type: TOTAL_COUNT_CHANGED,
        payload: data.count,
      });
    }
  }, [data?.count]);

  useEffect(() => {
    gotoPage(0);
  }, [selectedTab, stage]);

  // Set the entries per page value based on the select value
  const setEntriesPerPage = (value) => setPageSize(value);

  // Render the paginations
  const renderPagination = pageOptions.map((option) => (
    <MDPagination
      item
      key={option}
      onClick={() => gotoPage(Number(option))}
      active={pageIndex === option}
    >
      {option + 1}
    </MDPagination>
  ));

  // Handler for the input to set the pagination index
  const handleInputPagination = ({ target: { value } }) => (value > pageOptions.length || value < 0 ? gotoPage(0)
    : gotoPage(Number(value)));

  // Customized page options starting from 1
  const customizedPageOptions = pageOptions.map((option) => option + 1);

  // Setting value for the pagination input
  const handleInputPaginationValue = ({ target: value }) => gotoPage(Number(value.value - 1));

  // A function that sets the sorted value for the table
  const setSortedValue = (column) => {
    let sortedValue;
    if (column.isSorted) {
      sortedValue = column.isSortedDesc ? 'desc' : 'asce';
    } else {
      sortedValue = 'none';
    }
    return sortedValue;
  };

  return (
    <TableContainer sx={{ boxShadow: 'none' }}>
      <MDBox display="flex" justifyContent="space-between" alignItems="center" p={3} flexDirection={{ xs: 'column', lg: 'row' }}>
        <MDBox display="flex" alignItems="center">
          <Autocomplete
            disableClearable
            value={queryPageSize.toString()}
            options={['5', '10', '15', '20', '25']}
            onChange={(event, newValue) => {
              setEntriesPerPage(parseInt(newValue, 10));
            }}
            size="small"
            sx={{ width: '5rem' }}
            renderInput={(params) => <MDInput {...params} />}
          />
          <MDTypography variant="caption" color="secondary">
            &nbsp;&nbsp;
            {t('table.entries')}
          </MDTypography>
        </MDBox>
        <MDBox width="12rem" ml={{ lg: 'auto' }} pt={{ xs: 2 }}>
          <MDInput
            placeholder={`${t('general.search')}...`}
            value={filter}
            size="small"
            fullWidth
            onChange={({ currentTarget }) => {
              setFilter(currentTarget.value);
              onFilterChange(currentTarget.value);
            }}
          />
        </MDBox>
        <MDBox pt={{ xs: 2 }} pl={{ lg: 2 }}>
          <MDButton
            variant="gradient"
            color="info"
            onClick={onExport}
          >
            {t('table.export')}
          </MDButton>
        </MDBox>
      </MDBox>

      <Table {...getTableProps()}>
        <MDBox component="thead">
          {headerGroups.map((headerGroup) => (
            <TableRow {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => (
                <DataTableHeadCell
                  {...column.getHeaderProps(column.getSortByToggleProps())}
                  width={column.width ? column.width : 'auto'}
                  align={column.align ? column.align : 'left'}
                  sorted={setSortedValue(column)}
                >
                  {column.render('Header')}
                </DataTableHeadCell>
              ))}
            </TableRow>
          ))}
        </MDBox>
        <TableBody {...getTableBodyProps()}>
          {page.map((row, key) => {
            prepareRow(row);
            return (
              <TableRow {...row.getRowProps()}>
                {row.cells.map((cell) => (
                  <DataTableBodyCell
                    noBorder={rows.length - 1 === key}
                    align={cell.column.align ? cell.column.align : 'left'}
                    {...cell.getCellProps()}
                  >
                    {cell.render('Cell', { maxPoints, minTeamSize })}
                  </DataTableBodyCell>
                ))}
              </TableRow>
            );
          })}
        </TableBody>
      </Table>

      <MDBox
        display="flex"
        flexDirection={{ xs: 'column', sm: 'row' }}
        justifyContent="space-between"
        alignItems={{ xs: 'flex-start', sm: 'center' }}
      >

        <MDBox mb={{ xs: 3, sm: 0 }}>
          <MDTypography variant="button" color="secondary" fontWeight="regular">
            {t('table.showing', { numRows: page.length, totalCount })}
          </MDTypography>
        </MDBox>

        {pageOptions.length > 1 && (
          <MDPagination
            variant="gradient"
            color="info"
          >
            {canPreviousPage && (
              <MDPagination item onClick={() => previousPage()}>
                <Icon sx={{ fontWeight: 'bold' }}>chevron_left</Icon>
              </MDPagination>
            )}
            {renderPagination.length > 6 ? (
              <MDBox width="5rem" mx={1}>
                <MDInput
                  inputProps={{ type: 'number', min: 1, max: customizedPageOptions.length }}
                  value={customizedPageOptions[pageIndex]}
                  onChange={(handleInputPagination, handleInputPaginationValue)}
                />
              </MDBox>
            ) : (
              renderPagination
            )}
            {canNextPage && (
              <MDPagination item onClick={() => nextPage()}>
                <Icon sx={{ fontWeight: 'bold' }}>chevron_right</Icon>
              </MDPagination>
            )}
          </MDPagination>
        )}
      </MDBox>
    </TableContainer>
  );
}

DataTable.defaultProps = {
  data: null,
  queryPageIndex: 0,
  queryPageSize: 0,
  queryPageSortBy: '',
  totalCount: 0,
  filter: '',
  selectedTab: null,
  stage: 0,
  maxPoints: 0,
  minTeamSize: 0,
};

DataTable.propTypes = {
  columns: PropTypes.arrayOf(PropTypes.object).isRequired,
  data: PropTypes.objectOf(PropTypes.oneOfType([PropTypes.array, PropTypes.object, PropTypes.number, PropTypes.string, PropTypes.bool])),
  dispatch: PropTypes.func.isRequired,
  queryPageIndex: PropTypes.number,
  queryPageSize: PropTypes.number,
  queryPageSortBy: PropTypes.arrayOf(PropTypes.string),
  totalCount: PropTypes.number,
  filter: PropTypes.string,
  setFilter: PropTypes.func.isRequired,
  onExport: PropTypes.func.isRequired,
  selectedTab: PropTypes.string,
  stage: PropTypes.number,
  maxPoints: PropTypes.number,
  minTeamSize: PropTypes.number,
};

export default DataTable;
