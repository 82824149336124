import { useEffect, useState } from 'react';
import Chart from 'react-apexcharts';
import PropTypes from 'prop-types';
import { Box, Card } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { fetchMyActivities } from '../../../api';
import MDTypography from '../../../components/MDTypography';

const config = {
  options: {
    legend: {
      show: false,
    },
    chart: {
      height: 350,
      type: 'treemap',
      toolbar: {
        show: false,
      },
    },
  },
};

function MyActivities({ user }) {
  const [series, setSeries] = useState([]);
  const { t } = useTranslation();

  useEffect(async () => {
    const res = await fetchMyActivities(user.id);
    const newData = [];
    Object.entries(res.data).forEach(([key, value]) => {
      newData.push({ x: key, y: value });
    });
    setSeries(newData);
  }, []);

  return (
    <Card>
      <Box pt={2} pl={2}>
        <MDTypography variant="h5">{t('statistics.activities.title')}</MDTypography>
      </Box>
      <Box pl={2} pr={2}>
        <Chart
          options={config.options}
          series={[
            {
              data: series,
            },
          ]}
          type="treemap"
          width="100%"
          height="400"
        />
      </Box>
    </Card>
  );
}

MyActivities.propTypes = {
  user: PropTypes.objectOf(
    PropTypes.oneOfType([PropTypes.array, PropTypes.object, PropTypes.number, PropTypes.string, PropTypes.bool]),
  ).isRequired,
};

export default MyActivities;
