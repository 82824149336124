import { useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Grid } from '@mui/material';
import { useQuery } from 'react-query';
import { fetchActivities, fetchPost } from '../../../api';
import PostEntry from './postEntry';
import { Context } from '../../../store/Store';
import Header from '../../header';
import MDBox from '../../../components/MDBox';
import Footer from '../../../comps/Footer';
import DashboardLayout from '../../../LayoutContainers/DashboardLayout';
import { STALE_TIME } from '../../../config';

function ViewPost() {
  const { postId } = useParams();
  const [post, setPost] = useState(null);
  const { state } = useContext(Context);
  const { user } = state;

  useEffect(async () => {
    if (postId) {
      const result = await fetchPost(postId);
      setPost(result.data);
    }
  }, [postId]);

  const { data: activityData } = useQuery(
    ['activities', user.language],
    () => fetchActivities(),
    {
      staleTime: STALE_TIME.ONE_HOUR,
    },
  );
  const { activities } = activityData || [];

  if (post === null) {
    return null;
  }

  return (
    <DashboardLayout>
      <Header challenge={user.team.challenge} />
      <MDBox mb={2}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Grid container spacing={2}>
              <Grid item xs={12} md={6} lg={4}>
                <PostEntry post={post} user={user} activities={activities} />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}

export default ViewPost;
