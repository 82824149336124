import { useContext } from 'react';
import MDSnackbar from '../components/MDSnackbar';
import { Context } from '../store/Store';

function Notification() {
  const { state, dispatch } = useContext(Context);
  const { error, success } = state;
  const { title: errorTitle, content: errorContent } = error || {};
  const { title: successTitle, content: successContent } = success || {};

  const onErrorClose = () => dispatch({ type: 'SET_ERROR', payload: null });
  const onSuccessClose = () => dispatch({ type: 'SET_SUCCESS', payload: null });

  return (
    <>
      {error
        && (
        <MDSnackbar
          color="error"
          icon="notifications"
          title={errorTitle}
          content={errorContent}
          dateTime=""
          open={!!error}
          close={onErrorClose}
          anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
          autoHideDuration={3000}
          onClose={onErrorClose}
        />
        )}

      {success
        && (
        <MDSnackbar
          color="success"
          icon="notifications"
          title={successTitle}
          content={successContent}
          dateTime=""
          open={!!success}
          close={onSuccessClose}
          anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
          autoHideDuration={3000}
          onClose={onSuccessClose}
        />
        )}
    </>
  );
}

export default Notification;
