import MDBox from 'components/MDBox';
import Grid from '@mui/material/Grid';
import { useContext } from 'react';

import Footer from 'comps/Footer';
import { useParams } from 'react-router-dom';
import DashboardLayout from '../../LayoutContainers/DashboardLayout';
import Posts from './posts';
import { Context } from '../../store/Store';
import Header from '../header';

function Social() {
  const { state } = useContext(Context);
  const { user } = state;
  const { postId } = useParams();

  return (
    <DashboardLayout>
      <Header challenge={user.team.challenge} />
      <MDBox mb={2}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Posts user={user} postId={postId} />
          </Grid>
        </Grid>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}

export default Social;
