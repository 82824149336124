import PropTypes from 'prop-types';

import { Link } from 'react-router-dom';
import MDTypography from 'components/MDTypography';
import {
  Avatar, Grid, List, ListItem, ListItemText, ListItemIcon, ListItemButton, styled, IconButton,
} from '@mui/material';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import AvatarGroup from '@mui/material/AvatarGroup';
import ErrorIcon from '@mui/icons-material/Error';
import { useTranslation } from 'react-i18next';
import { stringAvatar } from '../../../../utils';

const CustomTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))({
  [`& .${tooltipClasses.tooltip}`]: {
    maxWidth: 800,
  },
});

function TeamCell({ teamName, users, minTeamSize }) {
  const { t } = useTranslation();
  return (
    <Grid container>
      <Grid item xs={12}>
        <MDTypography variant="button" fontWeight="medium" color="text">
          {teamName}
        </MDTypography>
        {minTeamSize > users.length ? (
          <Tooltip
            arrow={false}
            title={(
              <MDTypography color="inherit" variant="body2" style={{ whiteSpace: 'pre-line', textAlign: 'left' }}>
                {t('scoreboard.minTeamSize', { minTeamSize })}
              </MDTypography>
            )}
          >
            <IconButton>
              <ErrorIcon color="error" sx={{ fontSize: '24px' }} />
            </IconButton>
          </Tooltip>
        ) : null}
      </Grid>
      <Grid item>
        <CustomTooltip title={(
          <List>
            {users.map(({ fullName, avatar, id }) => (
              <ListItem disablePadding key={id}>
                <ListItemButton>
                  <ListItemIcon>
                    <Avatar {...stringAvatar(fullName, 'sm')} src={avatar} />
                  </ListItemIcon>
                  <Link to={`/profile/${id}`} style={{ color: 'white', textDecoration: 'none' }}>
                    <ListItemText
                      primary={fullName}
                      primaryTypographyProps={{
                        fontSize: 14,
                        fontWeight: 'medium',
                        color: 'white',
                      }}
                    />
                  </Link>
                </ListItemButton>
              </ListItem>
            ))}
          </List>
        )}
        >
          <AvatarGroup
            total={users.length}
            max={8}
            sx={{
              '& .MuiAvatar-root': {
                width: 32, height: 32, fontSize: 15,
              },
            }}
          >
            {users.map(({ fullName, avatar, id }) => (
              <Avatar {...stringAvatar(fullName)} src={avatar} key={id} />
            ))}
          </AvatarGroup>
        </CustomTooltip>
      </Grid>
    </Grid>
  );
}

TeamCell.defaultProps = {
  users: [],
  teamName: '',
  minTeamSize: 0,
};

TeamCell.propTypes = {
  teamName: PropTypes.string,
  users: PropTypes.arrayOf(PropTypes.object),
  minTeamSize: PropTypes.number,
};

export default TeamCell;
