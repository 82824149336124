import PropTypes from 'prop-types';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

function ResetPasswordDialog(props) {
  const { t } = useTranslation();
  const { onSubmit, onClose, open } = props;
  const [email, setEmail] = useState('');

  const handleSubmit = () => {
    onSubmit(email);
  };

  const handleClose = () => {
    setEmail('');
    onClose();
  };

  return (
    <Dialog onClose={handleClose} open={open}>
      <DialogTitle>{t('login.reset.title')}</DialogTitle>
      <DialogContent>
        <DialogContentText>
          {t('login.reset.info')}
        </DialogContentText>
        <TextField
          autoFocus
          margin="dense"
          id="name"
          label={t('general.email')}
          type="email"
          fullWidth
          variant="standard"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>{t('general.cancel')}</Button>
        <Button onClick={handleSubmit}>{t('login.reset')}</Button>
      </DialogActions>
    </Dialog>
  );
}

ResetPasswordDialog.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
};

export default ResetPasswordDialog;
