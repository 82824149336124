import axios from 'axios';
import { useContext, useEffect } from 'react';
import { Context } from 'store/Store';

export const api = axios.create({
  baseURL: process.env.NODE_ENV === 'development' ? 'https://local.challengize.com:3000/api' : '/api',
});

export const apiSecure = axios.create({
  baseURL: process.env.NODE_ENV === 'development' ? 'https://local.challengize.com:3000/api' : '/api',
});

// Set the AUTH token for any request
apiSecure.interceptors.request.use((config) => {
  const token = localStorage.getItem('accessToken');
  // eslint-disable-next-line no-param-reassign
  config.headers.Authorization = token ? `Bearer ${token}` : '';
  return config;
});

const AxiosInterceptor = ({ children }) => {
  const { dispatch } = useContext(Context);

  useEffect(() => {
    const resInterceptor = (response) => response;

    const errInterceptor = (error) => {
      if (error.response && error.response.status === 401) {
        localStorage.removeItem('accessToken');
        localStorage.removeItem('userId');
        dispatch({ type: 'SET_USER', payload: null });
        return Promise.reject(error);
      }

      dispatch({
        type: 'SET_ERROR',
        payload: {
          title: 'Error',
          content: error.response.data.message,
        },
      });
      dispatch({ type: 'LOADING', payload: false });
      return Promise.reject(error);
    };

    const interceptor = apiSecure.interceptors.response.use(resInterceptor, errInterceptor);

    return () => apiSecure.interceptors.response.eject(interceptor);
  }, []);
  return children;
};

export { AxiosInterceptor };
