import Card from '@mui/material/Card';
import Grid from '@mui/material/Grid';
import MDBox from 'components/MDBox';
import MDTypography from 'components/MDTypography';

import { useContext, useEffect, useState } from 'react';
import MenuItem from '@mui/material/MenuItem';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Box from '@mui/material/Box';

import { useSearchParams } from 'react-router-dom';
import PropTypes from 'prop-types';
import MDButton from 'components/MDButton';
import { Context } from 'store/Store';
import { useTranslation } from 'react-i18next';
import { connectTracker, disconnectTracker } from '../../api';

function Tracker({ user }) {
  const { dispatch } = useContext(Context);
  const [tracker, setTracker] = useState('');
  const { t } = useTranslation();

  const [searchParams] = useSearchParams();

  useEffect(() => {
    const trackerConnect = searchParams.get('tracker_connected');
    const error = searchParams.get('error');
    if (error) {
      return dispatch({
        type: 'SET_ERROR',
        payload: {
          title: 'Tracker connection failed',
          content: error,
        },
      });
    }
    if (trackerConnect === 'runkeeper' || trackerConnect === 'strava') {
      return dispatch({
        type: 'SET_SUCCESS',
        payload: {
          title: t('settings.tracker.connected'),
          content: t('settings.tracker.connect.success', { tracker: trackerConnect }),
        },
      });
    }
    return null;
  }, [searchParams]);

  const handleChange = (event) => {
    setTracker(event.target.value);
  };

  const handleTrackerConnect = async (event) => {
    event.preventDefault();
    const response = await connectTracker(user.id, tracker);
    window.location.href = response.data;
  };

  const handleTrackerDisconnect = async (event) => {
    event.preventDefault();
    const response = await disconnectTracker(user.tracker);
    if (response.status === 200) {
      return dispatch({
        type: 'SET_SUCCESS',
        payload: {
          title: t('settings.tracker.disconnected'),
          content: t('settings.tracker.disconnect.success', { tracker: user.tracker }),
        },
      });
    }
    return dispatch({
      type: 'SET_ERROR',
      payload: {
        title: 'Tracker disconnection failed',
        content: 'Please try again later',
      },
    });
  };

  return (
    <Card>
      <MDBox p={3} lineHeight={1}>
        <MDBox mb={1}>
          <MDTypography variant="h5">Tracker</MDTypography>
        </MDBox>
        <MDTypography variant="button" color="text">
          {t('settings.tracker.info')}
        </MDTypography>
      </MDBox>
      {user.tracker ? (
        <MDBox component="form" pb={3} px={3} onSubmit={handleTrackerDisconnect}>
          <Grid container alignItems="flex-end">
            <Grid item xs={6}>
              <Box display="flex" justifyContent="flex-start">
                <MDTypography variant="subtitle2">
                  {t('settings.tracker.connectedTo')}
                  <Box component="span" fontWeight="fontWeightMedium" textTransform="upperCase">{user.tracker}</Box>
                </MDTypography>
              </Box>
            </Grid>
            <Grid item xs={6}>
              <Box display="flex" justifyContent="flex-end">
                <MDButton variant="gradient" color="warning" size="small" type="submit">
                  {t('settings.tracker.disconnect')}
                </MDButton>
              </Box>
            </Grid>
          </Grid>
        </MDBox>
      ) : (
        <MDBox component="form" pb={3} px={3} onSubmit={handleTrackerConnect}>
          <Grid container alignItems="flex-end">
            <Grid item xs={6}>
              <Box display="flex" justifyContent="flex-start">
                <FormControl fullWidth variant="standard">
                  <InputLabel id="tracker-label">Tracker</InputLabel>
                  <Select
                    labelId="tracker-label"
                    id="tracker"
                    value={tracker}
                    label="Tracker"
                    onChange={handleChange}
                  >
                    <MenuItem value="runkeeper">Runkeeper</MenuItem>
                    <MenuItem value="strava">Strava</MenuItem>
                  </Select>
                </FormControl>
              </Box>
            </Grid>
            <Grid item xs={6}>
              <Box display="flex" justifyContent="flex-end">
                <MDButton variant="gradient" color="info" size="small" type="submit" disabled={!tracker}>
                  {t('settings.tracker.connect')}
                </MDButton>
              </Box>
            </Grid>
          </Grid>
        </MDBox>
      )}
    </Card>
  );
}

Tracker.propTypes = {
  user: PropTypes.objectOf(
    PropTypes.oneOfType([PropTypes.array, PropTypes.object, PropTypes.number, PropTypes.string, PropTypes.bool]),
  )
    .isRequired,
};

export default Tracker;
