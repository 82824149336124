import { Navigate } from 'react-router-dom';
import PropTypes from 'prop-types';

function ProtectedRoute({ isAllowed, loading, children }) {
  if (loading) {
    return null;
  }

  if (!isAllowed) {
    return <Navigate to="/login" replace />;
  }

  return children;
}

ProtectedRoute.defaultProps = {
  children: false,
  loading: false,
  isAllowed: false,
};

ProtectedRoute.propTypes = {
  children: PropTypes.node,
  isAllowed: PropTypes.bool,
  loading: PropTypes.bool,
};

export default ProtectedRoute;
