import { Badge, Box } from '@mui/material';
import PropTypes from 'prop-types';
import { formatDistanceToNow } from 'date-fns';
import { utcToZonedTime } from 'date-fns-tz';
import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';
import teamChatLogo from 'assets/challengize/images/team_chat_logo.png';
import logo from 'assets/challengize/images/svg/logo.svg';
import PhotoCameraIcon from '@mui/icons-material/PhotoCamera';
import MDTypography from '../../components/MDTypography';
import { getUnreadMessageCount } from './firestore';

function ChatItem({
  user, locale, chat, isSelected,
}) {
  const { t } = useTranslation();
  const { id, timezone } = user;
  const [unreadCount, setUnreadCount] = useState(0);

  const {
    name, lastMessage, challengeChat, userMap,
  } = chat;
  const { created, userId } = lastMessage || {};

  const author = userMap?.get(userId);

  useEffect(() => {
    const getCount = async (c) => {
      const count = await getUnreadMessageCount(c.id, id);
      setUnreadCount(count);
    };

    if (chat && !isSelected) {
      // A little delay to make sure the unread message count is updated
      setTimeout(() => getCount(chat), 1000);
    }
  }, [chat.id, chat?.lastMessage?.messageId]);

  useEffect(() => {
    if (isSelected) {
      setUnreadCount(0);
    }
  }, [isSelected]);

  return (
    <Box display="flex" sx={{ width: '100%' }}>
      <Box mr={2}>
        {challengeChat ? (
          <img
            src={logo}
            style={{ width: 48, height: 48, borderRadius: 99 }}
            alt="ChallengeChat"
          />
        ) : (
          <img
            src={teamChatLogo}
            style={{ width: 48, height: 48, borderRadius: 99 }}
            alt="TeamChat"
          />
        )}
      </Box>
      <Box display="flex" flexDirection="column" sx={{ width: '100%' }}>
        <Box display="flex" flexDirection="row" justifyContent="space-between" alignItems="center" sx={{ width: '100%' }}>
          <MDTypography variant="h6" fontWeight="medium">{name}</MDTypography>
          {lastMessage && author ? (
            <MDTypography variant="caption" fontWeight="regular" color="text">
              {formatDistanceToNow(utcToZonedTime(created, timezone), { locale })}
              {t('social.ago')}
            </MDTypography>
          ) : null}
        </Box>
        {lastMessage && author ? (
          <Box
            display="flex"
            flexDirection="row"
            alignItems="center"
            justifyContent="space-between"
            sx={{ paddingRight: 1 }}
          >
            <div style={{
              width: 300,
              overflow: 'hidden',
              whiteSpace: 'nowrap',
              textOverflow: 'ellipsis',
              display: 'flex',
              alignItems: 'center',
              flexWrap: 'nowrap',
            }}
            >
              <MDTypography variant="button" fontWeight="regular" sx={{ color: '#108cc4', marginRight: 0.5 }}>
                {author?.firstName}
                :
              </MDTypography>
              {!lastMessage.deleted && lastMessage.url ? (
                <PhotoCameraIcon
                  color="action"
                  sx={{
                    width: 16, height: 16, marginRight: 0.5,
                  }}
                />
              ) : null}
              {lastMessage.deleted ? (
                <MDTypography variant="button" noWrap fontWeight="regular" color="text" sx={{ paddingRight: 2 }}>
                  {t('chat.message.deleted')}
                </MDTypography>
              ) : (
                <MDTypography variant="button" noWrap fontWeight="regular" color="text" sx={{ paddingRight: 2 }}>
                  {lastMessage.message}
                </MDTypography>
              )}
            </div>
            <Badge badgeContent={unreadCount} color="primary" />
          </Box>
        ) : null}
      </Box>
    </Box>
  );
}

ChatItem.propTypes = {
  chat: PropTypes.objectOf(
    PropTypes.oneOfType([PropTypes.array, PropTypes.object, PropTypes.number, PropTypes.string, PropTypes.bool]),
  ).isRequired,
  user: PropTypes.objectOf(
    PropTypes.oneOfType([PropTypes.array, PropTypes.object, PropTypes.number, PropTypes.string, PropTypes.bool]),
  ).isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  locale: PropTypes.object.isRequired,
  isSelected: PropTypes.bool,
};

ChatItem.defaultProps = {
  isSelected: false,
};

export default ChatItem;
