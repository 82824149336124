import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Avatar, Box, IconButton } from '@mui/material';
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';
import FavoriteIcon from '@mui/icons-material/Favorite';
import AvatarGroup from '@mui/material/AvatarGroup';
import Tooltip from '@mui/material/Tooltip';
import { stringAvatar } from '../../../utils';

function Likes({ onToggleLike, userId, likes }) {
  const [isLiked, setIsLiked] = useState(false);
  const [isLikedDisabled, setIsLikedDisable] = useState(false);

  useEffect(() => {
    if (likes.some((like) => like.userId === userId)) {
      setIsLiked(true);
    }
  }, [likes]);

  const toggleLike = () => {
    setIsLikedDisable(true);
    setIsLiked(!isLiked);
    onToggleLike();
    setTimeout(() => {
      setIsLikedDisable(false);
    }, 2000);
  };

  return (
    <Box
      display="flex"
      justifyContent="flex-start"
      alignItems="center"
    >
      <IconButton
        value="check"
        onClick={() => {
          toggleLike();
        }}
        disabled={isLikedDisabled}
      >
        {isLiked ? (
          <FavoriteIcon
            sx={{ width: 26, height: 26 }}
            color="error"
          />
        ) : (
          <FavoriteBorderIcon
            sx={{ width: 26, height: 26 }}
            color="error"
          />
        )}
      </IconButton>

      <AvatarGroup
        total={likes.length}
        max={5}
        sx={{
          marginLeft: '10px',
          '& .MuiAvatar-root': {
            width: 32, height: 32, fontSize: 15,
          },
        }}
      >
        {likes.map(({ fullName, avatar, id }) => (
          <Tooltip title={fullName} key={id}>
            <Avatar {...stringAvatar(fullName)} src={avatar} />
          </Tooltip>
        ))}
      </AvatarGroup>
    </Box>
  );
}

Likes.defaultProps = {
  likes: {},
};

Likes.propTypes = {
  userId: PropTypes.number.isRequired,
  likes: PropTypes.arrayOf(PropTypes.object),
  onToggleLike: PropTypes.func.isRequired,
};

export default Likes;
