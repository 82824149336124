import Card from '@mui/material/Card';
import { useEffect, useState } from 'react';
import MDTypography from 'components/MDTypography';
import { fetchStageSummary } from 'api';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import PropTypes from 'prop-types';
import InfoIcon from '@mui/icons-material/Info';
import { styled } from '@mui/material/styles';
import { Stack, IconButton, Box } from '@mui/material';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import { useTranslation } from 'react-i18next';
import StageTabs from './stageTabs';

const CustomWidthTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))({
  [`& .${tooltipClasses.tooltip}`]: {
    maxWidth: 500,
  },
});

function Stages({ user }) {
  const { t } = useTranslation();
  const [data, setData] = useState([]);
  const [tabValue, setTabValue] = useState(0);

  const { charityDescription } = user.team.challenge;
  const heightOfTable = charityDescription ? 432 : 755;

  const doFetchStageSummary = async (id) => {
    const response = await fetchStageSummary(id);
    setData(response.data);
  };

  useEffect(async () => {
    await doFetchStageSummary(user.team.challenge.id);
  }, [user]);

  return (
    <Box mb={2}>
      <Card>
        <Box p={3}>
          <Stack direction="row" justifyContent="space-between" alignItems="center">
            <MDTypography variant="h5">Challenge Total</MDTypography>
            <CustomWidthTooltip
              arrow={false}
              title={(
                <MDTypography color="inherit" variant="body2" style={{ whiteSpace: 'pre-line', textAlign: 'left' }}>
                  {t('stages.tooltip')}
                </MDTypography>
            )}
            >
              <IconButton>
                <InfoIcon color="info" sx={{ fontSize: '30px' }} />
              </IconButton>
            </CustomWidthTooltip>
          </Stack>
          <StageTabs numStages={data.length - 1} tabValue={tabValue} setTabValue={setTabValue} />

          <TableContainer sx={{ maxHeight: heightOfTable }}>
            <Table size="small">
              <TableHead>
                <TableRow>
                  <TableCell>#</TableCell>
                  <TableCell>{t('stages.team')}</TableCell>
                  <TableCell align="right">Stage Points</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {data[tabValue] && data[tabValue].map((row) => (
                  <TableRow
                    key={row.id}
                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                  >
                    <TableCell component="th" scope="row">
                      {tabValue === 0 ? row.totalPosition : row.position}
                    </TableCell>
                    <TableCell>{row.teamName}</TableCell>
                    <TableCell align="right">{row.stagePoints}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>

        </Box>
      </Card>
    </Box>
  );
}
Stages.propTypes = {
  user: PropTypes.objectOf(
    PropTypes.oneOfType([PropTypes.array, PropTypes.object, PropTypes.number, PropTypes.string, PropTypes.bool]),
  )
    .isRequired,
};

export default Stages;
