import MDBox from 'components/MDBox';
import Card from '@mui/material/Card';
import { useContext } from 'react';
import Footer from 'comps/Footer';
import Workout from './workout';
import DashboardLayout from '../../../LayoutContainers/DashboardLayout';
import Header from '../../header';
import { Context } from '../../../store/Store';

function AddWorkout() {
  const { state } = useContext(Context);
  const { user } = state;

  return (
    <DashboardLayout>
      <Header challenge={user.team.challenge} />
      <MDBox mb={3}>
        <Card sx={{ overflow: 'visible' }}>
          <Workout />
        </Card>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}

export default AddWorkout;
