import PropTypes from 'prop-types';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import ChangePassword from '../settings/changePassword';
import { saveNewPassword, updateUser } from '../../api';
import { Context } from '../../store/Store';

function ChangePasswordDialog(props) {
  const { dispatch } = useContext(Context);
  const { t } = useTranslation();
  const {
    onClose, open, token, isForcePasswordUpdate,
  } = props;

  const handleClose = () => {
    onClose();
  };

  const handleSubmit = async (currentPassword, newPassword) => {
    try {
      if (isForcePasswordUpdate) {
        try {
          const result = await updateUser({ currentPassword, newPassword });
          dispatch({ type: 'SET_USER', payload: result.data });
          return dispatch({
            type: 'SET_SUCCESS',
            payload: {
              title: t('settings.password.notification.success'),
              content: '',
            },
          });
        } catch (error) {
          dispatch({ type: 'SET_USER', payload: null });
          return dispatch({
            type: 'SET_ERROR',
            payload: {
              title: t('settings.password.notification.error'),
              content: t(error.response.data.message),
            },
          });
        }
      }

      await saveNewPassword({ newPassword, token });
      dispatch({
        type: 'SET_SUCCESS',
        payload: {
          title: 'Password updated',
          content: 'Your password has been updated',
        },
      });
      // eslint-disable-next-line no-empty
    } catch (error) {}
    return handleClose();
  };

  return (
    <Dialog onClose={handleClose} open={open}>
      <DialogContent>
        <ChangePassword
          onSavePassword={handleSubmit}
          onClose={handleClose}
          validateCurrentPassword={isForcePasswordUpdate}
        />
      </DialogContent>
    </Dialog>
  );
}

ChangePasswordDialog.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  token: PropTypes.string.isRequired,
  isForcePasswordUpdate: PropTypes.bool.isRequired,
};

export default ChangePasswordDialog;
