import { useState, useContext, useEffect } from 'react';
import {
  useLocation,
  useNavigate,
  useParams,
  useSearchParams,
} from 'react-router-dom';

import { Context } from 'store/Store';

import {
  Card,
  Grid,
  Button,
  Link as MuiLink,
  OutlinedInput,
  InputLabel,
  FormControl,
  CircularProgress,
} from '@mui/material';

import MDBox from 'components/MDBox';
import MDTypography from 'components/MDTypography';
import MDInput from 'components/MDInput';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import { styled } from '@mui/material/styles';

import challengizeLogo from 'assets/challengize/images/challengize_logo_white.png';
import bgImage from 'assets/challengize/images/login-background.jpg';
import { useTranslation } from 'react-i18next';
import { useMsal } from '@azure/msal-react';
import BasicLayout from './basicLayout';

import {
  login,
  loginAzure,
  fetchLoginSettings,
  resetPassword,
} from '../../api';

import MDButton from '../../components/MDButton';
import ResetPasswordDialog from './resetPasswordDialog';
import ChangePasswordDialog from './changePasswordDialog';

const LoginButton = styled(Button)(({ theme, mycolor }) => {
  const { functions, palette } = theme;
  const { info } = palette;
  const { boxShadow, rgba } = functions;
  const col = mycolor || info.main;
  const hoveredBoxShadowValue = `${boxShadow(
    [0, 14],
    [26, -12],
    col,
    0.4,
  )}, ${boxShadow([0, 4], [23, 0], col, 0.15)}, ${boxShadow(
    [0, 8],
    [10, -5],
    col,
    0.2,
  )}`;

  return {
    color: '#FFFFFF',
    backgroundColor: col,
    '&:disabled': {
      backgroundColor: rgba(col, 0.75),
      color: '#FFFFFF',
    },
    '&:hover': {
      backgroundColor: col,
      boxShadow: hoveredBoxShadowValue,
    },
    '&:focus:not(:hover)': {
      backgroundColor: col,
    },
  };
});

function Login() {
  const { t, i18n } = useTranslation();
  const { dispatch, state } = useContext(Context);
  const { user: loggedInUser } = state;
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const { id } = useParams();
  const [searchParams] = useSearchParams();

  const [heading, setHeading] = useState(null);
  const [logoBackgroundColor, setLogoBackgroundColor] = useState(null);
  const [buttonBackgroundColor, setButtonBackgroundColor] = useState(null);
  const [backgroundImage, setBackgroundImage] = useState(bgImage);
  const [logo, setLogo] = useState(challengizeLogo);
  const [moreInfoText, setMoreInfoText] = useState(null);
  const [moreInfoUrl, setMoreInfoUrl] = useState(null);
  const [showRegisterButton, setShowRegisterButton] = useState(false);
  const [showPassword, setShowPassword] = useState(false);

  const [showResetPassword, setShowResetPassword] = useState(false);
  const [resetPasswordToken, setResetPasswordToken] = useState(null);

  const [forcePasswordUpdate, setForcePasswordUpdate] = useState(false);
  const [user, setUser] = useState({});
  const [ssoChallenge, setSsoChallenge] = useState(false);
  const [singleSignOnButtonText, setSingleSignOnButtonText] = useState(false);
  const [loading, setLoading] = useState(false);

  const { instance } = useMsal();

  useEffect(async () => {
    if (id) {
      const res = await fetchLoginSettings(id);
      const {
        welcomeText,
        bgImageUrl,
        logoUrl,
        logoBgColor,
        buttonColor,
        infoText,
        infoUrl,
        register,
        sso,
        ssoButtonText,
      } = res.data;
      setMoreInfoText(infoText);
      setMoreInfoUrl(infoUrl);
      setHeading(welcomeText);
      setShowRegisterButton(register !== null && register);
      if (bgImageUrl !== null) {
        setBackgroundImage(bgImageUrl);
      }
      if (logoUrl !== null) {
        setLogo(logoUrl);
      }
      setLogoBackgroundColor(logoBgColor);
      setButtonBackgroundColor(buttonColor);
      setSsoChallenge(sso);
      setSingleSignOnButtonText(ssoButtonText);
    }
  }, [id]);

  useEffect(() => {
    if (searchParams.get('token')) {
      setResetPasswordToken(searchParams.get('token'));
    }
    if (searchParams.get('invalidToken')) {
      dispatch({
        type: 'SET_ERROR',
        payload: {
          title: 'Reset password failed',
          content: 'The password reset request is no longer valid',
        },
      });
    }
  }, [searchParams]);

  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    // Prevent showing login page when user is already logged in
    if (loggedInUser && !loggedInUser.forcePasswordUpdate) {
      return navigate('/');
    }
    return null;
  }, [loggedInUser]);

  const handleAzureLogin = async ({ token }) => {
    try {
      setLoading(true);
      const res = await loginAzure({ token });
      const { user: userResponse, accessToken } = res.data;
      localStorage.setItem('accessToken', accessToken);
      localStorage.setItem('userId', userResponse.id);

      await i18n.changeLanguage(userResponse.language);

      setLoading(false);

      dispatch({ type: 'SET_USER', payload: userResponse });
      const origin = location.state?.from?.pathname || '/';
      return navigate(origin);
    } catch (error) {
      setLoading(false);
      dispatch({ type: 'SET_USER', payload: null });
      return dispatch({
        type: 'SET_ERROR',
        payload: {
          title: t('settings.password.invalid.credentials'),
          content: '',
        },
      });
    }
  };

  const handleLogin = async () => {
    try {
      const res = await login({ username, password });

      const { user: userResponse, accessToken } = res.data;
      dispatch({ type: 'SET_USER', payload: userResponse });
      localStorage.setItem('accessToken', accessToken);
      localStorage.setItem('userId', userResponse.id);

      await i18n.changeLanguage(userResponse.language);

      if (userResponse.forcePasswordUpdate) {
        setUser(userResponse);
        return setForcePasswordUpdate(true);
      }

      const origin = location.state?.from?.pathname || '/';
      return navigate(origin);
    } catch (error) {
      dispatch({ type: 'SET_USER', payload: null });
      return dispatch({
        type: 'SET_ERROR',
        payload: {
          title: t('settings.password.invalid.credentials'),
          content: '',
        },
      });
    }
  };

  useEffect(() => {
    instance.handleRedirectPromise().then((item) => {
      if (item && localStorage.getItem('ssoAccessToken') !== item.accessToken) {
        localStorage.setItem('ssoAccessToken', item.accessToken);
        handleAzureLogin({ token: item.accessToken });
      }
    });
  }, []);

  const handleSsoLogin = () => {
    instance.loginRedirect({
      scopes: ['User.Read'],
      prompt: 'select_account',
    });
  };

  const doPasswordReset = async (email) => {
    setShowResetPassword(false);
    await resetPassword(email);
    return dispatch({
      type: 'SET_SUCCESS',
      payload: {
        title: 'Password reset',
        content: 'Check your email how to reset your password',
      },
    });
  };

  const handleRegister = () => {
    // TODO
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    return false;
  };

  return (
    <BasicLayout image={backgroundImage}>
      <Card>
        <MDBox
          variant={logoBackgroundColor !== null ? 'contained' : 'gradient'}
          bgColor={logoBackgroundColor !== null ? logoBackgroundColor : 'info'}
          borderRadius="lg"
          coloredShadow={logoBackgroundColor !== null ? 'light' : 'light'}
          mx={2}
          mt={-3}
          p={2}
          mb={1}
          textAlign="center"
        >
          <Grid
            container
            spacing={3}
            justifyContent="center"
            sx={{ mt: 1, mb: 2 }}
          >
            <Grid item xs={12}>
              <img src={logo} width={200} alt="Challengize" />
            </Grid>
          </Grid>
        </MDBox>
        <MDBox pt={2} pb={3} px={3}>
          {heading !== null ? (
            <MDBox
              display="flex"
              pb={2}
              justifyContent="center"
              alignItems="center"
              textAlign="center"
            >
              <MDTypography
                variant="button"
                fontWeight="regular"
                color="text"
                fontSize="medium"
              >
                {heading}
              </MDTypography>
            </MDBox>
          ) : (
            <>
              <MDBox display="flex" justifyContent="center" alignItems="center">
                <MDTypography
                  variant="button"
                  fontWeight="regular"
                  color="text"
                  fontSize="medium"
                >
                  Challenge your colleagues.
                </MDTypography>
              </MDBox>
              <MDBox
                display="flex"
                pb={2}
                justifyContent="center"
                alignItems="center"
              >
                <MDTypography
                  variant="button"
                  fontWeight="regular"
                  color="text"
                  fontSize="medium"
                >
                  Energize yourself!
                </MDTypography>
              </MDBox>
            </>
          )}
          <MDBox component="form" role="form" onSubmit={handleSubmit}>
            <MDBox mb={2}>
              <MDInput
                value={username}
                onChange={(e) => setUsername(e.target.value)}
                label={t('general.username')}
                fullWidth
              />
            </MDBox>
            <MDBox mb={2}>
              <FormControl fullWidth variant="outlined">
                <InputLabel htmlFor="outlined-adornment-password">
                  {t('login.password')}
                </InputLabel>
                <OutlinedInput
                  id="outlined-adornment-password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  type={showPassword ? 'text' : 'password'}
                  label="Password"
                  endAdornment={(
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={() => setShowPassword(!showPassword)}
                      >
                        {showPassword ? <Visibility /> : <VisibilityOff />}
                      </IconButton>
                    </InputAdornment>
                  )}
                />
              </FormControl>
            </MDBox>
            <MDBox mt={4} mb={1}>
              <LoginButton
                variant="contained"
                mycolor={buttonBackgroundColor}
                fullWidth
                onClick={handleLogin}
                disabled={username === '' || password === ''}
                type="submit"
              >
                {t('login.login')}
              </LoginButton>
            </MDBox>
            {ssoChallenge ? (
              <MDBox mt={2} mb={1}>
                <LoginButton
                  variant="contained"
                  mycolor={buttonBackgroundColor}
                  fullWidth
                  onClick={handleSsoLogin}
                  type="submit"
                >
                  {loading ? 'Loading...' : singleSignOnButtonText}
                  {loading && (
                    <CircularProgress
                      size={16}
                      sx={{
                        marginLeft: 1,
                        color: '#FFFFFF',
                      }}
                    />
                  )}
                </LoginButton>
              </MDBox>
            ) : null}
            {showRegisterButton ? (
              <MDBox mt={4} mb={1}>
                <MDButton
                  variant="outlined"
                  color="info"
                  fullWidth
                  onClick={handleRegister}
                >
                  {t('login.register')}
                </MDButton>
              </MDBox>
            ) : null}
            {moreInfoText != null ? (
              <MDBox mt={3} textAlign="center">
                <MDTypography
                  component={MuiLink}
                  href={moreInfoUrl}
                  target="_blank"
                  variant="button"
                  color="info"
                  fontWeight="medium"
                  textGradient
                >
                  {moreInfoText}
                </MDTypography>
              </MDBox>
            ) : null}
            <MDBox mt={3} mb={1} textAlign="center">
              <MDTypography variant="button" color="text">
                {t('login.password.forgot')}
                {' '}
                <MDTypography
                  component={MuiLink}
                  onClick={() => {
                    setShowResetPassword(true);
                  }}
                  variant="button"
                  color="info"
                  fontWeight="medium"
                  textGradient
                  sx={{ cursor: 'pointer' }}
                >
                  {t('login.reset')}
                </MDTypography>
              </MDTypography>
            </MDBox>

            <MDBox mt={1} mb={0} textAlign="center">
              <MDTypography variant="button" color="text">
                {t('login.goto.homepage')}
                {' '}
                <MDTypography
                  component={MuiLink}
                  href="http://go.challengize.com"
                  target="_blank"
                  variant="button"
                  color="info"
                  fontWeight="medium"
                  textGradient
                  sx={{ cursor: 'pointer' }}
                >
                  {t('login.homepage')}
                </MDTypography>
              </MDTypography>
            </MDBox>
          </MDBox>
        </MDBox>
      </Card>

      <ResetPasswordDialog
        open={showResetPassword}
        onClose={() => setShowResetPassword(false)}
        onSubmit={doPasswordReset}
      />
      <ChangePasswordDialog
        user={user}
        open={resetPasswordToken !== null || forcePasswordUpdate}
        onClose={() => {
          setResetPasswordToken(null);
          setForcePasswordUpdate(false);
        }}
        token={resetPasswordToken !== null ? resetPasswordToken : ''}
        isForcePasswordUpdate={forcePasswordUpdate}
      />
    </BasicLayout>
  );
}

export default Login;
