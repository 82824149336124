import { Controller } from 'react-hook-form';
import PropTypes from 'prop-types';
import { TextField } from '@mui/material';
import { useTranslation } from 'react-i18next';
import MDBox from '../../../components/MDBox';

function Comment({ workout, control }) {
  const { t } = useTranslation();
  return (
    <MDBox
      sx={{
        '& .MuiTextField-root': { width: '100%' },
      }}
      noValidate
      autoComplete="off"
    >
      <Controller
        name="message"
        control={control}
        defaultValue={workout ? workout.message : ''}
        render={({
          field: { onChange, value },
        }) => (
          <TextField
            id="outlined-multiline-static"
            label={t('workouts.comment.placeholder')}
            multiline
            rows={3}
            value={value}
            onChange={onChange}
          />
        )}
      />
    </MDBox>
  );
}
Comment.defaultProps = {
  workout: null,
};

Comment.propTypes = {
  workout: PropTypes.objectOf(
    PropTypes.oneOfType([PropTypes.array, PropTypes.object, PropTypes.number, PropTypes.string, PropTypes.bool]),
  ),
  control: PropTypes.oneOfType([PropTypes.object]).isRequired,
};

export default Comment;
