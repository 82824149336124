import {
  Box,
  FormControl,
  FormHelperText,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from '@mui/material';
import PropTypes from 'prop-types';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import Autocomplete from '@mui/material/Autocomplete';
import MDBox from '../../components/MDBox';
import MDButton from '../../components/MDButton';

function RegisterUser({
  onRegisterUser, metadata, teams,
}) {
  const {
    handleSubmit, control, getValues, reset,
  } = useForm();
  const { t } = useTranslation();

  const onSubmit = (data) => onRegisterUser({ ...data }, reset);

  return (
    <MDBox component="form" onSubmit={handleSubmit(onSubmit)}>
      <MDBox mt={4} mb={3}>
        <Grid container spacing={2} alignContent="center" justifyContent="flex-start" alignItems="flex-start">
          <Grid item xs={6} md={3}>
            <Controller
              name="firstName"
              control={control}
              defaultValue=""
              rules={{
                required: t('register.firstName.required'),
              }}
              render={({ field: { onChange, value }, fieldState: { error } }) => (
                <TextField
                  fullWidth
                  label={t('register.firstName')}
                  value={value}
                  onChange={onChange}
                  error={!!error}
                  inputProps={{ type: 'text', autoComplete: '' }}
                  helperText={error ? error.message : null}
                />
              )}
            />
          </Grid>
          <Grid item xs={6} md={3}>
            <Controller
              name="lastName"
              control={control}
              defaultValue=""
              rules={{
                required: t('register.lastName.required'),
              }}
              render={({ field: { onChange, value }, fieldState: { error } }) => (
                <TextField
                  fullWidth
                  label={t('register.lastName')}
                  value={value}
                  onChange={onChange}
                  error={!!error}
                  inputProps={{ type: 'text', autoComplete: '' }}
                  helperText={error ? error.message : null}
                />
              )}
            />
          </Grid>

          <Grid item xs={6} md={3}>
            <Box sx={{ minWidth: 120 }}>
              <Controller
                name="team"
                control={control}
                defaultValue={null}
                rules={{
                  required: t('register.team.required'),
                }}
                render={({
                  field: { onChange, value },
                  fieldState: { error },
                }) => (
                  <Autocomplete
                    disableClearable
                    onChange={(e, newValue) => {
                      onChange(newValue);
                    }}
                    isOptionEqualToValue={(option, val) => (option.id ? option.id === val.id : false)}
                    options={teams}
                    value={value}
                    getOptionLabel={(option) => (option.teamName ? option.teamName : t('general.loading'))}
                    renderInput={(params) => (
                      <TextField
                        label={t('register.team')}
                        InputLabelProps={{ shrink: true }}
                        {...params}
                        error={!!error}
                        helperText={error ? error.message : null}
                      />
                    )}
                  />
                )}
              />
            </Box>
          </Grid>
        </Grid>
      </MDBox>

      {/* Metadata */}
      <MDBox mt={3} mb={3}>
        <Grid container spacing={2} alignContent="flex-start" justifyContent="flex-start" alignItems="flex-start">
          {metadata && metadata.map((md) => (
            <Grid item xs={5} md={3} key={md.identifier}>
              <Box sx={{ minWidth: 120 }}>
                <Controller
                  name={`metadata.${md.identifier}`}
                  control={control}
                  defaultValue=""
                  rules={{
                    validate: (value) => (!md.mandatory || value.length > 0) || t('general.input.mandatory'),
                  }}
                  validate={() => true}
                  render={({ field: { onChange, value }, fieldState: { error } }) => (
                    <TextField
                      fullWidth
                      label={md.description}
                      value={value}
                      onChange={onChange}
                      error={!!error}
                      inputProps={{ type: 'text', autoComplete: '' }}
                      helperText={error ? error.message : null}
                    />
                  )}
                />
              </Box>
            </Grid>
          ))}
        </Grid>
      </MDBox>

      <MDBox mt={3} mb={3}>
        <Grid container spacing={2} alignContent="flex-start" justifyContent="flex-start" alignItems="flex-start">
          <Grid item xs={6} md={3}>
            <Controller
              name="email"
              control={control}
              defaultValue=""
              rules={{
                required: 'Email is required',
                pattern: {
                  // eslint-disable-next-line max-len
                  value: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                  message: 'Invalid email address',
                },
              }}
              render={({ field: { onChange, value }, fieldState: { error } }) => (
                <TextField
                  fullWidth
                  label={t('general.email')}
                  value={value}
                  onChange={onChange}
                  error={!!error}
                  inputProps={{ type: 'text', autoComplete: '' }}
                  helperText={error ? error.message : null}
                />
              )}
            />
          </Grid>
          <Grid item xs={6} md={3}>
            <Controller
              name="confirmEmail"
              control={control}
              defaultValue=""
              rules={{
                required: t('register.email.confirm'),
                pattern: {
                  // eslint-disable-next-line max-len
                  value: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                  message: t('register.email.invalid'),
                },
                validate: (value) => {
                  if (value === getValues().email) {
                    return true;
                  }
                  return t('register.email.mismatch');
                },
              }}
              render={({ field: { onChange, value }, fieldState: { error } }) => (
                <TextField
                  fullWidth
                  label={t('register.email.confirm')}
                  value={value}
                  onChange={onChange}
                  error={!!error}
                  inputProps={{ type: 'text', autoComplete: '' }}
                  helperText={error ? error.message : null}
                />
              )}
            />
          </Grid>
          <Grid item xs={6} md={3}>
            <Box sx={{ minWidth: 120 }}>
              <Controller
                name="language"
                control={control}
                defaultValue=""
                rules={{
                  required: t('register.language.required'),
                }}
                render={({ field: { onChange, value }, fieldState: { error } }) => (
                  <FormControl fullWidth error={!!error}>
                    <InputLabel id="language-label">{t('general.language')}</InputLabel>
                    <Select
                      labelId="language-label"
                      value={value}
                      label={t('general.language')}
                      error={!!error}
                      onChange={onChange}
                    >
                      <MenuItem value="sv">Svenska</MenuItem>
                      <MenuItem value="en">English</MenuItem>
                      <MenuItem value="da">Dansk</MenuItem>
                      <MenuItem value="no">Norsk</MenuItem>
                      <MenuItem value="fi">Suomi</MenuItem>
                      <MenuItem value="de">Deutsch</MenuItem>
                    </Select>
                    {error && <FormHelperText>{t('register.language.required')}</FormHelperText>}
                  </FormControl>
                )}
              />
            </Box>
          </Grid>
        </Grid>
      </MDBox>

      <MDBox mt={3} mb={3}>
        <Grid container justifyContent="flex-end">
          <MDButton variant="outlined" color="info" size="medium" type="submit" sx={{ marginTop: '4px' }}>{t('general.add')}</MDButton>
        </Grid>
      </MDBox>
    </MDBox>
  );
}

RegisterUser.defaultProps = {
  metadata: [],
  teams: null,
};

RegisterUser.propTypes = {
  onRegisterUser: PropTypes.func.isRequired,
  metadata: PropTypes.arrayOf(PropTypes.object),
  teams: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.object])),
};

export default RegisterUser;
