import { createContext, useReducer } from 'react';
import Reducer from 'reducers/reducer';
import PropTypes from 'prop-types';

const initialState = {
  user: null,
  error: null,
  success: null,
  loading: true,
};

function Store({ children }) {
  const [state, dispatch] = useReducer(Reducer, initialState);
  return (
    // eslint-disable-next-line react/jsx-no-constructed-context-values
    <Context.Provider value={{ state, dispatch }}>
      {children}
    </Context.Provider>
  );
}

Store.defaultProps = {
  children: false,
};

Store.propTypes = {
  children: PropTypes.node,
};

export const Context = createContext(initialState);
export default Store;
