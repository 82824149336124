/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// Material Dashboard 2 PRO React base styles
import colors from "assets/theme/base/colors";

// Material Dashboard 2 PRO React helper functions
import pxToRem from "assets/theme/functions/pxToRem";

const { transparent } = colors;

const select = {
  styleOverrides: {
    select: {
      // display: "grid",
      // alignItems: "center",
      // padding: `0 ${pxToRem(12)} !important`,

      // "& .Mui-selected": {
      //   backgroundColor: transparent.main,
      // },
    },

    // selectMenu: {
    //   background: "none",
    //   height: "none",
    //   minHeight: "none",
    //   overflow: "unset",
    // },

    icon: {
      // display: "none",
    },
  },
};

export default select;
