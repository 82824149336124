import PropTypes from 'prop-types';

import Collapse from '@mui/material/Collapse';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import Icon from '@mui/material/Icon';
import MDBox from 'components/MDBox';
import { useMaterialUIController } from 'context';
import ListItemIcon from '@mui/material/ListItemIcon';
import { item, itemArrow, itemContent } from './styles/sidenavItem';
import { collapseIconBox } from './styles/sidenavCollapse';

function SidenavItem({
  color, name, active, nested, children, open, icon, ...rest
}) {
  const [controller] = useMaterialUIController();
  const {
    miniSidenav, transparentSidenav, whiteSidenav, darkMode,
  } = controller;
  return (
    <>
      <ListItem
        {...rest}
        component="li"
        sx={(theme) => item(theme, {
          active, color, transparentSidenav, whiteSidenav, darkMode,
        })}
      >
        <MDBox
          sx={(theme) => itemContent(theme, {
            active,
            miniSidenav,
            name,
            open,
            nested,
            transparentSidenav,
            whiteSidenav,
            darkMode,
          })}
        >
          <ListItemIcon sx={(theme) => collapseIconBox(theme, { transparentSidenav, whiteSidenav, darkMode })}>
            {icon}
          </ListItemIcon>
          <ListItemText primary={name} sx={{ paddingLeft: '10px' }} />
          {children && (
            <Icon
              component="i"
              sx={(theme) => itemArrow(theme, {
                open, miniSidenav, transparentSidenav, whiteSidenav, darkMode,
              })}
            >
              expand_less
            </Icon>
          )}
        </MDBox>
      </ListItem>
      {children && (
        <Collapse in={open} timeout="auto" unmountOnExit {...rest}>
          {children}
        </Collapse>
      )}
    </>
  );
}

// Setting default values for the props of SidenavItem
SidenavItem.defaultProps = {
  color: 'info',
  active: false,
  nested: false,
  children: false,
  open: false,
};

// Typechecking props for the SidenavItem
SidenavItem.propTypes = {
  color: PropTypes.oneOf(['primary', 'secondary', 'info', 'success', 'warning', 'error', 'dark']),
  name: PropTypes.string.isRequired,
  active: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
  nested: PropTypes.bool,
  children: PropTypes.node,
  open: PropTypes.bool,
  icon: PropTypes.node,
};

SidenavItem.defaultProps = {
  icon: null,
};

export default SidenavItem;
