import AppBar from '@mui/material/AppBar';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Grid from '@mui/material/Grid';
import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import breakpoints from '../../../assets/theme/base/breakpoints';

function StageTabs({ numStages, tabValue, setTabValue }) {
  const [tabsOrientation, setTabsOrientation] = useState('horizontal');

  useEffect(() => {
    // A function that sets the orientation state of the tabs.
    function handleTabsOrientation() {
      return window.innerWidth < breakpoints.values.sm
        ? setTabsOrientation('vertical')
        : setTabsOrientation('horizontal');
    }

    /**
     The event listener that's calling the handleTabsOrientation function when resizing the window.
     */
    window.addEventListener('resize', handleTabsOrientation);

    // Call the handleTabsOrientation function to set the state with the initial value.
    handleTabsOrientation();

    // Remove event listener on cleanup
    return () => window.removeEventListener('resize', handleTabsOrientation);
  }, [tabsOrientation]);

  const handleSetTabValue = (event, newValue) => {
    // setTab(newValue);
    setTabValue(newValue);
  };

  const renderTabs = () => {
    const rows = [];
    for (let i = 0; i < numStages; i += 1) {
      rows.push(<Tab key={i} label={`Stage ${i + 1}`} value={i + 1} />);
    }
    return rows;
  };

  return (
    <Grid item xs={12} mt={2} mb={2}>
      <AppBar position="static">
        <Tabs orientation={tabsOrientation} value={tabValue} onChange={handleSetTabValue}>
          <Tab
            label="Total"
            value={0}
          />
          {renderTabs()}
        </Tabs>
      </AppBar>
    </Grid>
  );
}

StageTabs.defaultProps = {
  tabValue: 0,
};

StageTabs.propTypes = {
  numStages: PropTypes.number.isRequired,
  tabValue: PropTypes.number,
  setTabValue: PropTypes.func.isRequired,
};

export default StageTabs;
