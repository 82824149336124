import { Controller, useForm } from 'react-hook-form';
import Grid from '@mui/material/Grid';

import MDBox from 'components/MDBox';
import MDTypography from 'components/MDTypography';
import MDButton from 'components/MDButton';
import PropTypes from 'prop-types';
import { Stack, TextField } from '@mui/material';
import { useTranslation } from 'react-i18next';

function ChangePassword({ onSavePassword, onClose, validateCurrentPassword }) {
  const { t } = useTranslation();
  const passwordRequirements = [
    t('settings.password.rules.min'),
    t('settings.password.rules.max'),
    t('settings.password.rules.digit'),
    t('settings.password.rules.upper'),
    t('settings.password.rules.lower'),
  ];

  const renderPasswordRequirements = passwordRequirements.map((item, key) => {
    const itemKey = `element-${key}`;

    return (
      <MDBox key={itemKey} component="li" color="text" fontSize="1.25rem" lineHeight={1}>
        <MDTypography variant="button" color="text" fontWeight="regular" verticalAlign="middle">
          {item}
        </MDTypography>
      </MDBox>
    );
  });

  const { handleSubmit, control, getValues } = useForm();

  const onSubmit = (data) => onSavePassword(data.currentPassword, data.newPassword);

  return (
    <>
      <MDBox pb={3}>
        <MDTypography variant="h5">{t('settings.password.title')}</MDTypography>
      </MDBox>
      <MDBox component="form" pb={1} onSubmit={handleSubmit(onSubmit)}>
        <Grid container spacing={3}>
          { validateCurrentPassword ? (
            <Grid item xs={12}>
              <Controller
                name="currentPassword"
                control={control}
                defaultValue=""
                rules={{
                  required: t('settings.password.required.current'),
                }}
                render={({ field: { onChange, value }, fieldState: { error } }) => (
                  <TextField
                    fullWidth
                    variant="outlined"
                    label={t('settings.password.current')}
                    value={value}
                    onChange={onChange}
                    error={!!error}
                    inputProps={{ type: 'password', autoComplete: '' }}
                    helperText={error ? error.message : null}
                  />
                )}
              />
            </Grid>
          ) : null}
          <Grid item xs={12}>
            <Controller
              name="newPassword"
              control={control}
              defaultValue=""
              rules={{
                required: t('settings.password.required.new'),
                minLength: { value: 8, message: t('settings.password.rules.min') },
                maxLength: { value: 64, message: t('settings.password.rules.max') },
                pattern: {
                  value: /^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])[a-zA-Z0-9 !"#$%&'()*+,-./:;<=>?@[\\\]^_`{|}~]{8,64}$/,
                  message: t('settings.password.invalid.pattern'),
                },
              }}
              render={({ field: { onChange, value }, fieldState: { error } }) => (
                <TextField
                  fullWidth
                  variant="outlined"
                  label={t('settings.password.new')}
                  value={value}
                  onChange={onChange}
                  error={!!error}
                  inputProps={{ type: 'password', autoComplete: '' }}
                  helperText={error ? error.message : null}
                />
              )}
            />
          </Grid>
          <Grid item xs={12}>
            <Controller
              name="confirmNewPassword"
              control={control}
              defaultValue=""
              rules={{
                required: t('settings.password.required.confirm'),
                minLength: { value: 8, message: t('settings.password.rules.min') },
                maxLength: { value: 64, message: t('settings.password.rules.max') },
                pattern: {
                  value: /^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])[a-zA-Z0-9 !"#$%&'()*+,-./:;<=>?@[\\\]^_`{|}~]{8,64}$/,
                  message: t('settings.password.invalid.pattern'),
                },
                validate: (value) => {
                  if (value === getValues().newPassword) {
                    return true;
                  }
                  return t('settings.password.mismatch');
                },
              }}
              render={({ field: { onChange, value }, fieldState: { error } }) => (
                <TextField
                  variant="outlined"
                  name="confirmNewPassword"
                  fullWidth
                  label={t('settings.password.confirm')}
                  value={value}
                  onChange={onChange}
                  error={!!error}
                  inputProps={{ type: 'password', autoComplete: '' }}
                  helperText={error ? error.message : null}
                />
              )}
            />
          </Grid>
        </Grid>
        <MDBox mt={6} mb={1}>
          <MDTypography variant="h5">{t('settings.password.rules.title')}</MDTypography>
        </MDBox>

        <MDBox component="ul" m={0} pl={3.25} mb={3}>
          {renderPasswordRequirements}
        </MDBox>

        <Stack
          direction="row"
          justifyContent="right"
          alignItems="center"
          spacing={2}
        >
          {onClose !== null ? (
            <MDButton variant="outlined" color="info" size="small" onClick={() => onClose()}>
              {t('general.cancel')}
            </MDButton>
          ) : null}
          <MDButton variant="gradient" color="info" size="small" type="submit">
            {t('settings.password.submit')}
          </MDButton>
        </Stack>

      </MDBox>
    </>
  );
}

ChangePassword.defaultProps = {
  onClose: null,
  validateCurrentPassword: true,
};

ChangePassword.propTypes = {
  onSavePassword: PropTypes.func.isRequired,
  onClose: PropTypes.func,
  validateCurrentPassword: PropTypes.bool,
};

export default ChangePassword;
