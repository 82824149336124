import MDBox from 'components/MDBox';
import Card from '@mui/material/Card';
import { useEffect, useState } from 'react';
import Tooltip from '@mui/material/Tooltip';
import { fetchChallengeDuration } from 'api';
import yearImg from 'assets/challengize/images/achievements/year.png';
import dayImg from 'assets/challengize/images/achievements/day.png';
import filmImg from 'assets/challengize/images/achievements/film.png';
import {
  Grid, Paper, Stack, Typography,
} from '@mui/material';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

const Line = {
  background: '#FFFFFF',
  color: '#FFFFFF',
  border: 0,
  height: '2px',
  width: '100%',
  marginTop: '6px',
};

const ImageBackground = {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  paddingTop: '10px',
  paddingLeft: '10px',
  paddingRight: '10px',
  backgroundColor: '#D67CF8',
};

function TotalDuration({ user }) {
  const { t } = useTranslation();
  const [data, setData] = useState([]);

  const {
    totalDuration = 0, numberOfYears = 0, numberOfDays = 0, numberOfStarWars = 0,
  } = data;

  const doFetchChallengeDuration = async (id) => {
    const response = await fetchChallengeDuration(id);
    setData(response.data);
  };

  useEffect(async () => {
    await doFetchChallengeDuration(user.team.challenge.id);
  }, [user]);

  return (
    <MDBox mb={2}>
      <Card style={{ backgroundColor: '#5768AD' }}>
        <MDBox p={3}>
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            spacing={2}
          >
            <Typography variant="h5" color="#FFFFFF">{t('time.title')}</Typography>
            <Typography variant="h5" color="#FFFFFF">
              {(totalDuration / 3600).toFixed(0)}
              {' '}
              {t('general.hours')}
            </Typography>
          </Stack>
          <Grid container spacing={1} mt={1}>
            <Grid item xs={12} md={4}>
              <Paper sx={ImageBackground}>
                <Tooltip placement="top" title={t('time.years')}>
                  <img src={yearImg} width="100%" alt="Number of years" />
                </Tooltip>
                <hr style={Line} />
                <Typography variant="h4" color="#FFFFFF">{(numberOfYears).toFixed(2)}</Typography>
              </Paper>
            </Grid>

            <Grid item xs={12} md={4}>
              <Paper sx={ImageBackground}>
                <Tooltip placement="top" title={t('time.days')}>
                  <img src={dayImg} width="100%" alt="Number of days" />
                </Tooltip>
                <hr style={Line} />
                <Typography variant="h4" color="#FFFFFF">{numberOfDays}</Typography>
              </Paper>
            </Grid>

            <Grid item xs={12} md={4}>
              <Paper sx={ImageBackground}>
                <Tooltip placement="top" title={t('time.starwars')}>
                  <img src={filmImg} width="100%" alt="Star Wars" />
                </Tooltip>
                <hr style={Line} />
                <Typography variant="h4" color="#FFFFFF">{numberOfStarWars}</Typography>
              </Paper>
            </Grid>

          </Grid>
        </MDBox>
      </Card>
    </MDBox>
  );
}

TotalDuration.propTypes = {
  user: PropTypes.objectOf(
    PropTypes.oneOfType([PropTypes.array, PropTypes.object, PropTypes.number, PropTypes.string, PropTypes.bool]),
  )
    .isRequired,
};

export default TotalDuration;
