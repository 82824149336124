import { useState } from 'react';
import PropTypes from 'prop-types';
import { FilePond, registerPlugin } from 'react-filepond';

import 'filepond/dist/filepond.min.css';

import FilePondPluginImageExifOrientation from 'filepond-plugin-image-exif-orientation';
import FilePondPluginImagePreview from 'filepond-plugin-image-preview';
import FilePondPluginFileValidateType from 'filepond-plugin-file-validate-type';
import FilePondPluginImageTransform from 'filepond-plugin-image-transform';
import FilePondPluginImageResize from 'filepond-plugin-image-resize';
import FilePondPluginFileValidateSize from 'filepond-plugin-file-validate-size';
import 'filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css';
import './filepond.css';
import { renderToString } from 'react-dom/server';
import AddPhotoAlternateIcon from '@mui/icons-material/AddPhotoAlternate';
import { deleteFile } from '../../api';

// Register filepond the plugins
registerPlugin(
  FilePondPluginImageExifOrientation,
  FilePondPluginImagePreview,
  FilePondPluginFileValidateType,
  FilePondPluginImageTransform,
  FilePondPluginImageResize,
  FilePondPluginFileValidateSize,
);

function FileUpload({ filePond, setImageSelected }) {
  const [files, setFiles] = useState([]);

  const genLabel = () => renderToString(
    <AddPhotoAlternateIcon
      color="text"
      style={{
        height: '2em',
        width: '2em',
        marginTop: '15px',
      }}
    />,
  );

  return (
    <div className="chat">
      <FilePond
        ref={filePond}
        files={files}
        allowMultiple={false}
        maxFiles={1}
        acceptedFileTypes="image/jpeg, image/png, image/gif, image/jpg"
        imageResizeTargetWidth={600}
        imageResizeTargetHeight={600}
        imageResizeMode="cover"
        server={{
          url: '/api/fileUpload/chat',
          headers: {
            Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
          },
          load: (uniqueFileId, load, error) => {
            fetch(uniqueFileId)
              .then((res) => res.blob())
              .then(load)
              .catch(error);
          },
          revert: (uniqueFileId, load, error) => {
            deleteFile(uniqueFileId)
              .then(() => {
                load();
                setImageSelected(false);
              })
              .catch(error);
          },
        }}
        name="file"
        labelIdle={genLabel()}
        credits={false}
        onupdatefiles={(f) => {
          setImageSelected(f.length > 0);
          setFiles(f);
        }}
        onremovefile={() => {
          setFiles([]);
        }}
      />
    </div>
  );
}

FileUpload.propTypes = {
  filePond: PropTypes.oneOfType([PropTypes.object]).isRequired,
  setImageSelected: PropTypes.func.isRequired,
};

export default FileUpload;
