import { useContext, useEffect } from 'react';
import { Context } from 'store/Store';

function Logout() {
  const { dispatch } = useContext(Context);

  useEffect(() => {
    async function logoutUser() {
      localStorage.removeItem('accessToken');
      localStorage.removeItem('userId');
      dispatch({ type: 'SET_USER', payload: null });
    }
    logoutUser();
  }, []);

  return null;
}

export default Logout;
