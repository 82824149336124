import {
  useContext, useEffect, useRef, useState,
} from 'react';

import MDBox from 'components/MDBox';
import Card from '@mui/material/Card';
import { Context } from 'store/Store';
import { yupResolver } from '@hookform/resolvers/yup';
import Grid from '@mui/material/Grid';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import MDButton from 'components/MDButton';
import { useForm } from 'react-hook-form';
import moment from 'moment';
import { useQuery, useQueryClient } from 'react-query';
import { fetchActivities, saveBasicWorkout, updateWorkout } from 'api';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { secondsToHours, secondsToMinutes } from 'date-fns';
import MDTypography from 'components/MDTypography';
import { STALE_TIME } from 'config';
import workoutSchema from './workoutSchema';
import { stripParams } from '../../../utils';
import Duration from './duration';
import Distance from './distance';
import PostChoice from './postChoice';
import Count from './count';
import StartTime from './startTime';
import Activity from './activity';
import FileUpload from './fileUpload';
import Comment from './comment';

const VISIBILITY_ALL = 'ALL';

function Workout({ editWorkout, workout, onDeleteWorkout }) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { state, dispatch } = useContext(Context);
  const { user } = state;
  const [selectedActivity, setSelectedActivity] = useState();
  const [visibility, setVisibility] = useState(VISIBILITY_ALL);
  const [buttonsDisabled, setButtonsDisable] = useState(false);
  const queryClient = useQueryClient();

  const filePond = useRef(null);

  const {
    handleSubmit, control, setValue, getValues,
  } = useForm({ resolver: yupResolver(workoutSchema) });

  const { data, isLoading } = useQuery(
    ['activities', user.language],
    () => fetchActivities(),
    {
      staleTime: STALE_TIME.ONE_HOUR,
    },
  );
  const { activities: allActivities, latestActivities } = data || [];
  const activities = latestActivities?.concat(allActivities?.filter((a) => a.selectable));

  useEffect(() => {
    if (workout != null && activities) {
      const selectedAct = activities.find((a) => a.activityName?.toLowerCase() === workout.activity?.toLowerCase());
      setValue('activity', selectedAct);
      setSelectedActivity(selectedAct);
    }
  }, [workout, isLoading]);

  const onToggleButtons = () => {
    setButtonsDisable(!buttonsDisabled);
  };

  const onActivityChange = (value) => {
    setSelectedActivity(value);
    setValue('distance', value.distance ? (value.distance / 1000).toFixed(1) : 0);
    setValue('hours', value.duration ? secondsToHours(value.duration) : 0);
    setValue('minutes', value.duration ? secondsToMinutes(value.duration % 3600) : 0);
    setValue('seconds', value.duration ? value.duration % 60 : 0);
    setValue('count', value.count ? value.count : 0);
  };

  const saveWorkout = async (workoutData) => {
    const {
      activity, startTime, distance, hours, minutes, seconds, count, message,
    } = workoutData;
    const body = {
      userId: user.id,
      activity: activity.activityName,
      distance: (distance * 1000),
      duration: (hours * 3600) + (minutes * 60) + seconds,
      startTimeUtc: new Date(moment(startTime).valueOf()).toISOString(),
      activityCount: count || 0,
      tracker: 'challengize',
      imageUrl: filePond.current?.getFile() ? stripParams(filePond.current.getFile().serverId) : null,
      message,
    };

    if (filePond.current?.getFile()) {
      body.visibility = visibility;
    }

    // Update workout
    if (editWorkout) {
      const response = await updateWorkout(workout.id, body);
      if (response.status === 200) {
        dispatch({
          type: 'SET_SUCCESS',
          payload: {
            title: t('workouts.update.success'),
            content: '',
          },
        });
        queryClient.invalidateQueries({ queryKey: ['scoreboard'] });
        queryClient.invalidateQueries({ queryKey: ['activities'] });
        return navigate('/my-workouts');
      }
      return dispatch({
        type: 'SET_ERROR',
        payload: {
          title: t('workouts.update.error'),
          content: response.response.data.message,
        },
      });
    }

    // Save workout
    const response = await saveBasicWorkout(body);
    if (response.status === 200) {
      if (response.data.valid) {
        dispatch({
          type: 'SET_SUCCESS',
          payload: {
            title: t('workouts.register.success'),
            content: t('workouts.register.success.info', { activity: response.data.activity, points: response.data.challengePoints }),
          },
        });
      } else {
        dispatch({
          type: 'SET_SUCCESS',
          payload: {
            title: t('workouts.register.invalid'),
            content: t('workouts.register.invalid.reason', { reason: response.data.invalidReason }),
          },
        });
      }
      queryClient.invalidateQueries({ queryKey: ['scoreboard'] });
      queryClient.invalidateQueries({ queryKey: ['activities'] });
      return navigate('/my-workouts');
    }
    return dispatch({
      type: 'SET_ERROR',
      payload: {
        title: t('workouts.register.error'),
        content: t('workouts.register.error.reason', { reason: response.data.message }),
      },
    });
  };

  const onSubmit = (workoutData) => saveWorkout(workoutData);

  if (editWorkout && !workout) {
    return null;
  }
  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <Card
        sx={{ overflow: 'visible' }}
        onSubmit={handleSubmit(onSubmit)}
      >
        <Grid container spacing={3} p={3} display="flex" justifyContent="space-between">
          <Grid item xs={12} md={9}>
            <MDTypography variant="h5">
              {editWorkout ? t('workouts.edit.title') : t('workouts.add.title')}
            </MDTypography>
          </Grid>
        </Grid>
        <MDBox component="form" noValidate pb={3} px={3}>
          <Grid container spacing={3}>
            <Grid item md={editWorkout ? 12 : 8} container spacing={3}>
              <Grid item xs={12} sm={6}>
                <Activity
                  activities={activities}
                  onActivityChange={onActivityChange}
                  control={control}
                  isDisabled={editWorkout && !workout.editable}
                  language={user.language}
                  isLoading={isLoading}
                />
              </Grid>

              <Grid item xs={12} sm={6}>
                <StartTime
                  workout={workout}
                  timezone={user.timezone}
                  control={control}
                  isDisabled={editWorkout && !workout.editable}
                />
              </Grid>

              { selectedActivity?.countActivity ? (
                <Grid item xs={12} sm={6}>
                  <Count workout={workout} control={control} isDisabled={editWorkout && !workout.editable} />
                </Grid>
              ) : (
                <>
                  <Grid item xs={12} sm={6}>
                    <Distance workout={workout} control={control} isDisabled={editWorkout && !workout.editable} />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Duration
                      workout={workout}
                      control={control}
                      setValue={setValue}
                      getValues={getValues}
                      isDisabled={editWorkout && !workout.editable}
                    />
                  </Grid>
                </>
              )}
              {editWorkout ? null : (
                <Grid item xs={12}>
                  <Comment workout={workout} control={control} />
                </Grid>
              ) }
            </Grid>

            {editWorkout ? null : (
              <Grid item xs={12} md={4}>
                <FileUpload filePond={filePond} imageUrl={workout ? workout.imageUrl : null} toggleButtons={onToggleButtons} />
                {filePond?.current?.getFile() && (
                  <PostChoice setVisibility={setVisibility} visibility={visibility} />
                )}
              </Grid>
            ) }
          </Grid>

          <MDBox display="flex" justifyContent="space-between" alignItems="flex-end" flexWrap="wrap" mt={2}>
            <MDBox ml="auto">
              {editWorkout && (
              <MDButton
                variant="gradient"
                color="error"
                size="small"
                sx={{ marginRight: '20px' }}
                onClick={() => onDeleteWorkout(workout.id)}
                disabled={!workout.deletable || buttonsDisabled}
              >
                {t('general.delete')}
              </MDButton>
              )}
              <MDButton
                variant="gradient"
                color="dark"
                size="small"
                type="submit"
                disabled={workout && (!workout.editable || buttonsDisabled)}
              >
                {t('general.save')}
              </MDButton>
            </MDBox>
          </MDBox>
        </MDBox>
      </Card>
    </LocalizationProvider>
  );
}

Workout.defaultProps = {
  editWorkout: false,
  workout: null,
  onDeleteWorkout: null,
};

Workout.propTypes = {
  editWorkout: PropTypes.bool,
  workout: PropTypes.objectOf(
    PropTypes.oneOfType([PropTypes.array, PropTypes.object, PropTypes.number, PropTypes.string, PropTypes.bool]),
  ),
  onDeleteWorkout: PropTypes.func,
};

export default Workout;
