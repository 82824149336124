import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

function ColumnHeader({ label }) {
  const { t } = useTranslation();

  return (
    <div>
      <span>{t(label)}</span>
    </div>
  );
}

ColumnHeader.propTypes = {
  label: PropTypes.string.isRequired,
};

export default ColumnHeader;
