/* eslint-disable react/no-array-index-key */
import { Chip } from '@mui/material';
import PropTypes from 'prop-types';
import Stack from '@mui/material/Stack';

function FilterButton({ callback, value, selectedKey }) {
  const clicked = selectedKey === value.value;
  return (
    <Chip
      label={value.translation}
      variant={clicked ? 'filled' : 'outlined'}
      color="secondary"
      onClick={() => {
        callback(value.value);
      }}
    />
  );
}

FilterButton.propTypes = {
  value: PropTypes.objectOf(PropTypes.string).isRequired,
  selectedKey: PropTypes.string.isRequired,
  callback: PropTypes.func.isRequired,
};

function Filter({ data, selectedValue, onValueChange }) {
  return (
    <Stack direction="row" flexWrap="wrap" sx={{ gap: 1 }}>
      {data.map((x, i) => (
        <FilterButton
          key={i}
          value={x}
          id={i}
          selectedKey={selectedValue}
          callback={(v) => {
            if (onValueChange) {
              onValueChange(v);
            }
          }}
        />
      ))}
    </Stack>
  );
}

Filter.propTypes = {
  data: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.string)).isRequired,
  onValueChange: PropTypes.func.isRequired,
  selectedValue: PropTypes.string.isRequired,
};

export default Filter;
