import { Grid, TextField } from '@mui/material';
import PropTypes from 'prop-types';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import Autocomplete from '@mui/material/Autocomplete';
import MDBox from '../../components/MDBox';
import MDButton from '../../components/MDButton';

function RegisterTeam({
  onRegisterTeam, teamUnits, showTeamUnits,
}) {
  const {
    handleSubmit, control, reset,
  } = useForm();
  const { t } = useTranslation();

  const onSubmit = (data) => onRegisterTeam(data, reset);

  return (
    <MDBox component="form" onSubmit={handleSubmit(onSubmit)}>
      <MDBox mt={4} mb={3}>
        <Grid container spacing={2} alignContent="flex-start" justifyContent="flex-start" alignItems="flex-start">
          <Grid item xs={12} md={5}>
            <Controller
              name="teamName"
              control={control}
              defaultValue=""
              rules={{
                required: t('register.teamName.required'),
              }}
              render={({ field: { onChange, value }, fieldState: { error } }) => (
                <TextField
                  fullWidth
                  label={t('register.teamName')}
                  value={value}
                  onChange={onChange}
                  error={!!error}
                  inputProps={{ type: 'text', autoComplete: '' }}
                  helperText={error ? error.message : null}
                />
              )}
            />
          </Grid>

          {showTeamUnits ? (
            <Grid item xs={12} md={5}>
              <Controller
                name="teamUnit"
                control={control}
                defaultValue={null}
                rules={{
                  required: t('register.teamUnit.required'),
                }}
                render={({
                  field: { onChange, value },
                  fieldState: { error },
                }) => (
                  <Autocomplete
                    disableClearable
                    onChange={(e, newValue) => {
                      onChange(newValue);
                    }}
                    isOptionEqualToValue={(option, val) => (option.id ? option.id === val.id : false)}
                    options={teamUnits}
                    value={value}
                    getOptionLabel={(option) => (option.teamUnitName ? option.teamUnitName : t('general.loading'))}
                    renderInput={(params) => (
                      <TextField
                        label={t('register.teamUnit')}
                        InputLabelProps={{ shrink: true }}
                        {...params}
                        error={!!error}
                        helperText={error ? error.message : null}
                      />
                    )}
                  />
                )}
              />
            </Grid>
          ) : null}

          <Grid item xs={12} md={2}>
            <MDButton variant="outlined" color="info" size="medium" type="submit" sx={{ marginTop: '4px' }}>{t('general.add')}</MDButton>
          </Grid>
        </Grid>
      </MDBox>
    </MDBox>
  );
}

RegisterTeam.propTypes = {
  onRegisterTeam: PropTypes.func.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  teamUnits: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.object])),
  showTeamUnits: PropTypes.bool.isRequired,
};

RegisterTeam.defaultProps = {
  teamUnits: null,
};

export default RegisterTeam;
