import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';

import { useState } from 'react';
import FavoriteIcon from '@mui/icons-material/Favorite';
import { IconButton } from '@mui/material';
import { styled } from '@mui/material/styles';
import Badge from '@mui/material/Badge';
import PropTypes from 'prop-types';
import MDTypography from '../../components/MDTypography';

const StyledBadge = styled(Badge)(() => ({
  '& .MuiBadge-badge': {
    right: 2,
    top: 10,
    border: '2px solid #ffb3b3',
    padding: '0 4px',
  },
}));

const photoStyle = {
  backgroundPosition: 'center',
  backgroundSize: 'cover',
  display: 'flex',
  justifyContent: 'center',
  height: '30vh',
  position: 'relative',
};

const photoOverlayStyle = {
  position: 'absolute',
  color: 'white',
  bottom: 0,
  left: '50%',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  width: '100%',
  transform: 'translateX(-50%)',
};

function Photo({ photo, onToggleLike, userId }) {
  const {
    author, imageUrl, postId, likes,
  } = photo;

  const [isLiked, setIsLiked] = useState(() => likes.some((id) => id === userId));

  const [numLikes, setNumLikes] = useState(likes.length);

  const toggleLike = () => {
    setNumLikes(isLiked ? (numLikes - 1) : (numLikes + 1));
    setIsLiked(!isLiked);
    onToggleLike(postId);
  };

  return (
    <div style={{ display: 'flex', paddingRight: 10, paddingBottom: 10 }}>
      <div style={photoStyle}>
        <img src={imageUrl} alt="" />
        <div style={photoOverlayStyle}>
          <MDTypography color="light" variant="caption" fontWeight="regular" sx={{ textShadow: '1px 0.5px 1px black' }}>
            {author}
          </MDTypography>
          <StyledBadge badgeContent={numLikes} color="error">
            <IconButton
              value="check"
              onClick={toggleLike}
            >
              {isLiked ? (
                <FavoriteIcon color="error" />
              ) : (
                <FavoriteBorderIcon color="error" />
              )}
            </IconButton>
          </StyledBadge>
        </div>
      </div>
    </div>
  );
}

Photo.propTypes = {
  photo: PropTypes.objectOf(
    PropTypes.oneOfType([PropTypes.array, PropTypes.object, PropTypes.number, PropTypes.string, PropTypes.bool]),
  ).isRequired,
  userId: PropTypes.number.isRequired,
  onToggleLike: PropTypes.func.isRequired,
};

export default Photo;
