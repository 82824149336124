import Card from '@mui/material/Card';
import Grid from '@mui/material/Grid';
import Autocomplete from '@mui/material/Autocomplete';
import MDBox from 'components/MDBox';
import MDTypography from 'components/MDTypography';
import { useEffect, useState } from 'react';

import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import FormField from '../../comps/FormField';
import { fetchTimezones } from '../../api';

const languages = [
  { label: 'Svenska', id: 'sv' },
  { label: 'English', id: 'en' },
  { label: 'Dansk', id: 'da' },
  { label: 'Norsk', id: 'no' },
  { label: 'Suomi', id: 'fi' },
  { label: 'Deutsch', id: 'de' },
  { label: 'Italiano', id: 'it' },
];

function LanguageTimezone({ user, onSaveLanguage, onSaveTimezone }) {
  const { t } = useTranslation();
  const [timezones, setTimezones] = useState([]);
  const loading = timezones.length === 0;

  const doFetchTimezones = async () => {
    const response = await fetchTimezones();
    setTimezones(response.data);
  };

  useEffect(() => {
    (async () => {
      await doFetchTimezones();
    })();
  }, []);

  return (
    <Card id="basic-info" sx={{ overflow: 'visible' }}>
      <MDBox p={3}>
        <MDTypography variant="h5">{t('settings.language.title')}</MDTypography>
      </MDBox>
      <MDBox component="form" pb={3} px={3}>
        <Grid container spacing={3}>

          <Grid item xs={12} md={6}>
            <Autocomplete
              disableClearable
              onChange={(e, value) => onSaveLanguage(value)}
              options={languages}
              value={languages.find((e) => e.id === user.language)}
              renderInput={(params) => (
                <FormField {...params} label={t('general.language')} InputLabelProps={{ shrink: true }} />
              )}
            />
          </Grid>

          <Grid item xs={12} md={6}>
            <Autocomplete
              disableClearable
              onChange={(e, value) => onSaveTimezone(value)}
              isOptionEqualToValue={(option, value) => (option.timezone ? option.timezone === value.timezone : false)}
              getOptionLabel={(option) => (option.timezone ? `${option.timezone} ${option.timezoneOffset}` : t('general.loading'))}
              options={timezones}
              loading={loading}
              value={timezones.find((e) => e.timezone === user.timezone) || []}
              renderInput={(params) => (
                <FormField {...params} label={t('settings.timezone')} InputLabelProps={{ shrink: true }} />
              )}
            />
          </Grid>

        </Grid>
      </MDBox>
    </Card>
  );
}

LanguageTimezone.propTypes = {
  user: PropTypes.objectOf(
    PropTypes.oneOfType([PropTypes.array, PropTypes.object, PropTypes.number, PropTypes.string, PropTypes.bool]),
  )
    .isRequired,
  onSaveTimezone: PropTypes.func.isRequired,
  onSaveLanguage: PropTypes.func.isRequired,
};

export default LanguageTimezone;
