import Card from '@mui/material/Card';
import { useContext, useState } from 'react';
import { useInfiniteQuery, useQueryClient, useMutation } from 'react-query';

import Grid from '@mui/material/Grid';
import InfiniteScroll from 'react-infinite-scroll-component';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import { useTranslation } from 'react-i18next';
import { fetchPhotos, toggleLike } from '../../api';
import { Context } from '../../store/Store';
import Photo from './photo';
import Header from '../header';
import MDBox from '../../components/MDBox';
import Footer from '../../comps/Footer';
import DashboardLayout from '../../LayoutContainers/DashboardLayout';

const PAGE_SIZE = 20;

function Photowall() {
  const { t } = useTranslation();
  const { state } = useContext(Context);
  const { user } = state;
  const { id: userId } = user;
  const queryClient = useQueryClient();
  const [orderBy, setOrderBy] = useState('date');

  const {
    data,
    fetchNextPage,
    hasNextPage,
  } = useInfiniteQuery(
    ['photos', orderBy],
    ({ pageParam = 0 }) => fetchPhotos(pageParam, PAGE_SIZE, orderBy),
    {
      getNextPageParam: (lastPage) => (lastPage.nextPage ? lastPage.nextPage : undefined),
    },
  );
  const photos = data?.pages.flatMap((page) => page.photos);

  const toggleLikeMutation = useMutation(
    (postId) => toggleLike(postId),
    {
      onSuccess: (d, postId) => {
        queryClient.setQueryData(['photos', orderBy], (prev) => ({
          ...prev,
          pages: prev.pages.map((page) => ({
            ...page,
            photos: page.photos.map((p) => (p.id === postId
              ? { ...p, isLiked: !p.isLiked, likes: d.payload.likes.length }
              : p)),
          })),
        }));
      },
    },
  );

  const onToggleLike = async (postId) => toggleLikeMutation.mutate(postId);

  const onOrderChange = (value) => {
    setOrderBy(value);
  };

  return (
    <DashboardLayout>
      <Header challenge={user.team.challenge} />
      <Card sx={{
        paddingLeft: 2, paddingRight: 2, paddingBottom: 1, paddingTop: 2,
      }}
      >
        <MDBox sx={{
          display: 'flex', height: 50, alignItems: 'center', justifyContent: 'center',
        }}
        >
          <RadioGroup
            row
            defaultValue="newest"
            value={orderBy}
            onChange={(e, value) => onOrderChange(value)}
          >
            <FormControlLabel value="date" control={<Radio />} label={t('photowall.newest')} />
            <FormControlLabel value="likes" control={<Radio />} label={t('photowall.popular')} />
          </RadioGroup>
        </MDBox>
        <MDBox mb={2}>
          {photos ? (
            <InfiniteScroll
              dataLength={photos.length}
              next={fetchNextPage}
              hasMore={hasNextPage}
              loader={<h4>Loading...</h4>}
            >
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Grid container alignItems="center" justifyItems="center" justifyContent="center">
                    {photos.map((photo) => (
                      <Grid item key={photo.imageUrl}>
                        <Photo key={photo.postId} photo={photo} userId={userId} onToggleLike={onToggleLike} />
                      </Grid>
                    ))}
                  </Grid>
                </Grid>
              </Grid>
            </InfiniteScroll>
          ) : null}
        </MDBox>
      </Card>
      <Footer />
    </DashboardLayout>
  );
}

export default Photowall;
