import Grid from '@mui/material/Grid';
import MDBox from 'components/MDBox';
import DashboardLayout from 'LayoutContainers/DashboardLayout';

import Footer from 'comps/Footer';
import { useContext } from 'react';
import Scoreboard from './scoreboard/scoreboard';
import Stages from './stages/stages';
import TotalDistance from './totalDistance/totalDistance';
import TotalDuration from './totalDuration/totalDuration';
import CO2 from './co2/co2';
import Charity from './charity/charity';
import Header from '../header';
import { Context } from '../../store/Store';

function Dashboard() {
  const { state } = useContext(Context);
  const { user } = state;
  return (
    <DashboardLayout>
      <Header challenge={user.team.challenge} />
      <MDBox mb={2}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Scoreboard user={user} />
          </Grid>
          <Grid item xs={12} sm={6}>
            { user?.team.challenge.challengeStages.length > 1 ? <Stages user={user} /> : null}
            <Charity user={user} />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TotalDistance user={user} />
            <TotalDuration user={user} />
            <CO2 />
          </Grid>
        </Grid>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}

export default Dashboard;
