import MDBox from 'components/MDBox';
import Card from '@mui/material/Card';
import { useContext, useEffect, useState } from 'react';
import Tooltip from '@mui/material/Tooltip';
import { Context } from 'store/Store';
import { fetchChallengeCO2 } from 'api';
import burgerImg from 'assets/challengize/images/achievements/burger.png';
import carImg from 'assets/challengize/images/achievements/car.png';
import flyImg from 'assets/challengize/images/achievements/fly.png';
import { useTranslation } from 'react-i18next';
import {
  Grid, Paper, Stack, Typography,
} from '@mui/material';

const Line = {
  background: '#000000',
  color: '#000000',
  border: 0,
  height: '2px',
  width: '100%',
  marginTop: '6px',
};

const ImageBackground = {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  paddingTop: '10px',
  paddingLeft: '10px',
  paddingRight: '10px',
  backgroundColor: '#DBF2FE',
};

function CO2() {
  const { t } = useTranslation();
  const { state } = useContext(Context);
  const { user } = state;
  const [data, setData] = useState([]);

  const {
    totalCo2 = 0, numFly = 0, numCar = 0, numBurger = 0,
  } = data;

  const doFetchChallengeCO2 = async (id) => {
    const response = await fetchChallengeCO2(id);
    setData(response.data);
  };

  useEffect(async () => {
    await doFetchChallengeCO2(user.team.challenge.id);
  }, [user]);

  return (
    <MDBox mb={2}>
      <Card style={{ backgroundColor: '#A1CF7A' }}>
        <MDBox p={3}>
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            spacing={2}
          >
            <Typography variant="h5" color="#FFFFFF">{t('co2.title')}</Typography>
            <Typography variant="h5" color="#FFFFFF">
              {(totalCo2).toFixed(0)}
              {' '}
              KG
            </Typography>
          </Stack>
          <Grid container spacing={1} mt={1}>
            <Grid item xs={12} md={4}>
              <Paper sx={ImageBackground}>
                <Tooltip placement="top" title={t('co2.fly')}>
                  <img src={flyImg} width="100%" alt="Number of flights" />
                </Tooltip>
                <hr style={Line} />
                <Typography variant="h4" color="#000000">{(numFly).toFixed(2)}</Typography>
              </Paper>
            </Grid>

            <Grid item xs={12} md={4}>
              <Paper sx={ImageBackground}>
                <Tooltip placement="top" title={t('co2.car')}>
                  <img src={carImg} width="100%" alt="Route 66" />
                </Tooltip>
                <hr style={Line} />
                <Typography variant="h4" color="#000000">{(numCar).toFixed(2)}</Typography>
              </Paper>
            </Grid>

            <Grid item xs={12} md={4}>
              <Paper sx={ImageBackground}>
                <Tooltip placement="top" title={t('co2.burger')}>
                  <img src={burgerImg} width="100%" alt="Burgers" />
                </Tooltip>
                <hr style={Line} />
                <Typography variant="h4" color="#000000">{numBurger}</Typography>
              </Paper>
            </Grid>

          </Grid>
        </MDBox>
      </Card>
    </MDBox>
  );
}

export default CO2;
