/* eslint-disable react/jsx-no-duplicate-props */
import Stack from '@mui/material/Stack';
import { Controller } from 'react-hook-form';
import { secondsToHours, secondsToMinutes } from 'date-fns';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import MDInput from '../../../components/MDInput';
import MDTypography from '../../../components/MDTypography';

function Duration({
  workout, control, setValue, getValues, isDisabled,
}) {
  const { t } = useTranslation();
  const handleHoursBlur = (value, onChange) => {
    if (!value) {
      return onChange(0);
    }
    if (Number.isNaN(value)) {
      return onChange(0);
    }
    const val = parseInt(value, 10);
    if (val > 48) {
      return onChange(48);
    }
    if (val < 0) {
      return onChange(0);
    }
    return onChange(val);
  };

  const handleHoursChange = (value, onChange) => {
    onChange(value);
    setValue('minutes', getValues('minutes'), {
      shouldValidate: true,
      shouldDirty: true,
    });
  };

  const handleMinutesBlur = (value, onChange) => {
    if (!value) {
      return onChange(0);
    }
    if (Number.isNaN(value)) {
      return onChange(0);
    }
    const val = parseInt(value, 10);
    if (val > 59) {
      return onChange(59);
    }
    if (val < 0) {
      return onChange(0);
    }
    return onChange(val);
  };

  return (
    <>
      <MDTypography variant="button" fontWeight="regular" color="text">{t('general.duration')}</MDTypography>
      <Stack direction="row" spacing={2}>
        <Controller
          name="hours"
          control={control}
          defaultValue={workout ? secondsToHours(workout.duration) : 0}
          render={({
            field: { onChange, value },
            fieldState: { error, invalid },
          }) => (
            <MDInput
              helperText={invalid ? error.message : null}
              value={value}
              variant="outlined"
              onBlur={() => handleHoursBlur(value, onChange)}
              onChange={(event) => {
                handleHoursChange(event.target.value, onChange);
              }}
              inputProps={{
                step: 1,
                min: 0,
                max: 48,
                type: 'number',
              }}
              label={t('hrs')}
              fullWidth
              error={invalid}
              disabled={isDisabled}
            />
          )}
        />

        <Controller
          name="minutes"
          control={control}
          defaultValue={workout ? secondsToMinutes(workout.duration % 3600) : 0}
          render={({
            field: { onChange, value },
            fieldState: { error, invalid },
          }) => (
            <MDInput
              helperText={invalid ? error.message : null}
              value={value}
              variant="outlined"
              onBlur={() => handleMinutesBlur(value, onChange)}
              onChange={(event) => {
                onChange(event.target.value);
              }}
              inputProps={{
                step: 1,
                min: 0,
                max: 59,
                type: 'number',
              }}
              label={t('min')}
              fullWidth
              error={invalid}
              disabled={isDisabled}
            />
          )}
        />

        <Controller
          name="seconds"
          control={control}
          defaultValue={workout ? (workout.duration % 60) : 0}
          render={({
            field: { onChange, value },
            fieldState: { error, invalid },
          }) => (
            <MDInput
              helperText={invalid ? error.message : null}
              value={value}
              variant="outlined"
              onBlur={() => handleMinutesBlur(value, onChange)}
              onChange={(event) => {
                onChange(event.target.value);
              }}
              inputProps={{
                step: 1,
                min: 0,
                max: 59,
                type: 'number',
              }}
              label={t('sec')}
              fullWidth
              error={invalid}
              disabled={isDisabled}
            />
          )}
        />
      </Stack>
    </>
  );
}
Duration.defaultProps = {
  workout: null,
  isDisabled: false,
};

Duration.propTypes = {
  workout: PropTypes.objectOf(
    PropTypes.oneOfType([PropTypes.array, PropTypes.object, PropTypes.number, PropTypes.string, PropTypes.bool]),
  ),
  control: PropTypes.oneOfType([PropTypes.object]).isRequired,
  setValue: PropTypes.func.isRequired,
  getValues: PropTypes.func.isRequired,
  isDisabled: PropTypes.bool,
};

export default Duration;
