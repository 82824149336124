import {
  da, de, enUS, fi, sv,
} from 'date-fns/locale';
import pxToRem from '../assets/theme/functions/pxToRem';

function stringToColor(string) {
  let hash = 0;
  let i;

  /* eslint-disable no-bitwise */
  for (i = 0; i < string.length; i += 1) {
    hash = string.charCodeAt(i) + ((hash << 5) - hash);
  }

  let color = '#';

  for (i = 0; i < 3; i += 1) {
    const value = (hash >> (i * 8)) & 0xff;
    color += `00${value.toString(16)}`.slice(-2);
  }
  /* eslint-enable no-bitwise */
  return color;
}

// eslint-disable-next-line import/prefer-default-export
export function stringAvatar(name, size) {
  // size value
  let sizeValue;

  switch (size) {
    case 'xs':
      sizeValue = {
        width: pxToRem(24),
        height: pxToRem(24),
        fontSize: pxToRem(10),
      };
      break;
    case 'sm':
      sizeValue = {
        width: pxToRem(32),
        height: pxToRem(32),
        fontSize: pxToRem(12),
      };
      break;
    case 'lg':
      sizeValue = {
        width: pxToRem(58),
        height: pxToRem(58),
        fontSize: pxToRem(22),
      };
      break;
    case 'xl':
      sizeValue = {
        width: pxToRem(74),
        height: pxToRem(74),
        fontSize: pxToRem(30),
      };
      break;
    case 'xxl':
      sizeValue = {
        width: pxToRem(110),
        height: pxToRem(110),
        fontSize: pxToRem(40),
      };
      break;
    default: {
      sizeValue = {
        width: pxToRem(48),
        height: pxToRem(48),
        fontSize: pxToRem(16),
      };
    }
  }

  if (!name) {
    return {
      sx: {
        ...sizeValue,
      },
    };
  }
  const strippedName = name.replace(/\s+/g, ' ').trim();
  let initials = strippedName.split(' ')[0][0];
  if (strippedName.includes(' ')) {
    initials += strippedName.split(' ')[1][0];
  }
  return {
    sx: {
      bgcolor: stringToColor(strippedName),
      ...sizeValue,
    },
    children: `${initials}`,
  };
}

export const getLocale = (lang) => {
  if (lang === 'sv') {
    return sv;
  }
  if (lang === 'da') {
    return da;
  }
  if (lang === 'de') {
    return de;
  }
  if (lang === 'fi') {
    return fi;
  }
  return enUS;
};

export const stripParams = (url) => url.split('?')[0];

export const findAndTranslateActivity = (activityName, activities) => {
  const activity = activities?.find((a) => a.activityName?.toLowerCase() === activityName?.toLowerCase());
  if (activity) {
    return activity.translation;
  }
  return activityName;
};
