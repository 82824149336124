/* eslint-disable react/prop-types */
import UserCell from './UserCell';
import ProgressCell from './ProgressCell';
import TeamCell from './TeamCell';
import ColumnHeader from '../../../../comps/ColumnHeader';
import TeamUnitCell from './TeamUnitCell';
import Cell from './Cell';

export const TEAMS_COLUMNS = [
  {
    Header: '#',
    accessor: 'position',
    width: '10%',
  },
  {
    Header: <ColumnHeader label="scoreboard.team" />,
    accessor: 'teamName',
    // eslint-disable-next-line react/no-unstable-nested-components
    Cell: ({ row, minTeamSize }) => (
      <TeamCell teamName={row.original.teamName} users={row.original.users} original={row.original} minTeamSize={minTeamSize} />
    ),
    width: '50%',
  },
  {
    Header: 'Challenge Points',
    accessor: 'teamPoints',
    // eslint-disable-next-line react/no-unstable-nested-components
    Cell: ({ maxPoints, row }) => (
      <ProgressCell
        totalPoints={row.original.teamPoints}
        challengePoints={row.original.challengePoints}
        bonusPoints={row.original.bonusPoints}
        color="dark"
        maxPoints={maxPoints}
      />
    ),
  },
];

export const TEAMS_WITH_UNITS_COLUMNS = [
  {
    Header: '#',
    accessor: 'position',
    width: '10%',
  },
  {
    Header: <ColumnHeader label="scoreboard.team" />,
    accessor: 'teamName',
    // eslint-disable-next-line react/no-unstable-nested-components
    Cell: ({ row, minTeamSize }) => (
      <TeamCell teamName={row.original.teamName} users={row.original.users} minTeamSize={minTeamSize} />
    ),
    width: '40%',
  },
  {
    Header: <ColumnHeader label="scoreboard.teamUnit" />,
    accessor: 'teamUnit.teamUnitName',
    Cell: ({ row }) => {
      const teamUnitName = row.original.teamUnitName ? row.original.teamUnitName : row.original.teamUnit.teamUnitName;
      return <TeamUnitCell teamUnitName={teamUnitName} />;
    },
  },
  {
    Header: 'Challenge Points',
    accessor: 'teamPoints',
    // eslint-disable-next-line react/no-unstable-nested-components
    Cell: ({ maxPoints, row }) => (
      <ProgressCell
        totalPoints={row.original.teamPoints}
        challengePoints={row.original.challengePoints}
        bonusPoints={row.original.bonusPoints}
        color="dark"
        maxPoints={maxPoints}
      />
    ),
  },
];

export const TEAM_UNITS_COLUMNS = [
  {
    Header: '#',
    accessor: 'position',
    width: '10%',
  },
  {
    Header: <ColumnHeader label="scoreboard.teamUnit" />,
    accessor: 'teamUnitName',
    // eslint-disable-next-line react/no-unstable-nested-components
    Cell: ({ row }) => (
      <TeamUnitCell teamUnitName={row.original.teamUnitName} teamNames={row.original.teamNames} />
    ),
    width: '50%',
  },
  {
    Header: 'Challenge Points',
    accessor: 'teamUnitPoints',
    // eslint-disable-next-line react/no-unstable-nested-components
    Cell: ({ maxPoints, row }) => (
      <ProgressCell
        totalPoints={row.original.teamUnitPoints}
        challengePoints={row.original.challengePoints}
        bonusPoints={row.original.bonusPoints}
        color="dark"
        maxPoints={maxPoints}
      />
    ),
  },
];

export const USERS_COLUMNS = [
  {
    Header: '#',
    accessor: 'position',
    width: '10%',
  },
  {
    Header: <ColumnHeader label="general.name" />,
    accessor: 'fullName',
    // eslint-disable-next-line react/no-unstable-nested-components
    Cell: ({ row }) => (
      <UserCell
        image={row.original.avatar}
        name={row.original.fullName}
        id={row.original.id}
        approvedFriend={row.original.approvedFriend}
        unapprovedFriend={row.original.unapprovedFriend}
        workoutsInChallenge={row.original.workoutsInChallenge}
      />
    ),
    width: '30%',
  },
  {
    Header: <ColumnHeader label="scoreboard.team" />,
    accessor: 'team.teamName',
    Cell: ({ row }) => (
      <Cell
        name={row.original.team?.teamName}
        id={row.original.id}
      />
    ),
    width: '30%',
  },
  {
    Header: 'Challenge Points',
    accessor: 'userPoints',
    // eslint-disable-next-line react/no-unstable-nested-components
    Cell: ({ maxPoints, row }) => (
      <ProgressCell
        totalPoints={row.original.userPoints}
        challengePoints={row.original.challengePoints}
        bonusPoints={row.original.bonusPoints}
        color="dark"
        maxPoints={maxPoints}
        showBonusPoints
      />
    ),
  },
];
