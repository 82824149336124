import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { FilePond, registerPlugin } from 'react-filepond';

import 'filepond/dist/filepond.min.css';
import FilePondPluginImageExifOrientation from 'filepond-plugin-image-exif-orientation';
import FilePondPluginImagePreview from 'filepond-plugin-image-preview';
import FilePondPluginFileValidateType from 'filepond-plugin-file-validate-type';
import FilePondPluginImageTransform from 'filepond-plugin-image-transform';
import FilePondPluginImageResize from 'filepond-plugin-image-resize';
import FilePondPluginFileValidateSize from 'filepond-plugin-file-validate-size';
import 'filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css';
import './filepond.css';
import AddPhotoAlternateIcon from '@mui/icons-material/AddPhotoAlternate';
import { renderToString } from 'react-dom/server';
import { deleteFile } from '../../../api';

// Register filepond the plugins
registerPlugin(
  FilePondPluginImageExifOrientation,
  FilePondPluginImagePreview,
  FilePondPluginFileValidateType,
  FilePondPluginImageTransform,
  FilePondPluginImageResize,
  FilePondPluginFileValidateSize,
);

function FileUpload({
  filePond, imageUrl, toggleButtons, hideVisibility,
}) {
  const [files, setFiles] = useState([]);

  useEffect(() => {
    if (imageUrl) {
      const myFiles = [{
        source: `${imageUrl}?t=${Date.now()}`,
        options: {
          type: 'local',
        },
      }];
      setFiles(myFiles);
    }
  }, [imageUrl]);

  const genLabel = () => renderToString(
    <AddPhotoAlternateIcon
      color="info"
      style={{
        height: '10em',
        width: '10em',
      }}
    />,
  );

  return (
    <div className="add-post">
      <FilePond
        ref={filePond}
        onprocessfilestart={toggleButtons}
        onprocessfiles={toggleButtons}
        files={files}
        onupdatefiles={setFiles}
        allowMultiple={false}
        maxFiles={1}
        acceptedFileTypes="image/jpeg, image/png, image/gif, image/jpg"
        imageResizeTargetWidth={600}
        imageResizeTargetHeight={600}
        imageResizeMode="cover"
        server={{
          url: '/api/fileUpload/post',
          headers: {
            Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
          },
          load: (uniqueFileId, load, error) => {
            fetch(uniqueFileId)
              .then((res) => res.blob())
              .then(load)
              .catch(error);
          },
          revert: (uniqueFileId, load, error) => {
            deleteFile(uniqueFileId)
              .then(() => {
                load();
                hideVisibility();
              })
              .catch(error);
          },
          remove: (source, load) => {
            hideVisibility();
            load();
          },
        }}
        name="file"
        labelIdle={genLabel()}
        credits={false}
      />
    </div>
  );
}

FileUpload.defaultProps = {
  imageUrl: null,
};

FileUpload.propTypes = {
  filePond: PropTypes.oneOfType([PropTypes.object]).isRequired,
  imageUrl: PropTypes.string,
  toggleButtons: PropTypes.func.isRequired,
  hideVisibility: PropTypes.func.isRequired,
};

export default FileUpload;
