import Grid from '@mui/material/Grid';
import { useContext, useEffect, useState } from 'react';
import Footer from 'comps/Footer';
import DashboardLayout from '../../LayoutContainers/DashboardLayout';
import Me from './me/me';
import Medals from './medals/medals';
import { fetchUserStatistics } from '../../api';
import { Context } from '../../store/Store';
import Header from '../header';
import DailyGoal from './dailyGoal/dailyGoal';
import MyActivities from './myActivities/myActivities';

function Statistics() {
  const { state } = useContext(Context);
  const { user } = state;
  const [userStats, setUserStats] = useState([]);

  const doFetchUserStatistics = async (id) => {
    const response = await fetchUserStatistics(id);
    setUserStats(response.data);
  };

  useEffect(async () => {
    await doFetchUserStatistics(user.id);
  }, [user]);

  const { numWorkouts = 0 } = userStats;

  return (
    <DashboardLayout>
      <Header challenge={user.team.challenge} />
      <Grid container spacing={2}>
        <Grid container item xs={12} direction="column">
          <MyActivities user={user} />
        </Grid>
        <Grid container item xs={12} md={12} lg={6} direction="column">
          <Me user={user} userStats={userStats} />
          <DailyGoal user={user} />
        </Grid>
        <Grid container item xs={12} md={12} lg={6} direction="column">
          <Medals numWorkouts={numWorkouts} />
        </Grid>
      </Grid>
      <Footer />
    </DashboardLayout>
  );
}

export default Statistics;
