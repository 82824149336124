import { useContext, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import MDBox from 'components/MDBox';
import Card from '@mui/material/Card';
import Grid from '@mui/material/Grid';
import { deleteWorkout, fetchWorkout } from 'api';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogActions from '@mui/material/DialogActions';
import Dialog from '@mui/material/Dialog';
import Footer from 'comps/Footer';
import { useTranslation } from 'react-i18next';
import DashboardLayout from '../../LayoutContainers/DashboardLayout';
import Workout from './addWorkout/workout';
import Header from '../header';
import { Context } from '../../store/Store';
import MDButton from '../../components/MDButton';

function EditWorkout() {
  const { t } = useTranslation();
  const { state, dispatch } = useContext(Context);
  const { user } = state;
  const [workout, setWorkout] = useState(null);
  const params = useParams();
  const navigate = useNavigate();

  const [open, setOpen] = useState(false);
  const [selectedId, setSelectedId] = useState(null);

  useEffect(async () => {
    const result = await fetchWorkout(params.id);
    setWorkout(result.data);
  }, []);

  const handleClose = () => {
    setOpen(false);
    setSelectedId(null);
  };

  const onDeleteWorkout = async (id) => {
    try {
      await deleteWorkout(id);
      dispatch({
        type: 'SET_SUCCESS',
        payload: {
          title: t('workouts.edit.delete.success'),
          content: '',
        },
      });
      return navigate('/my-workouts');
    } catch (e) {
      return null;
    }
  };

  const onDeleteConfirm = () => {
    onDeleteWorkout(selectedId);
    handleClose();
  };

  const onDelete = (id) => {
    setSelectedId(id);
    setOpen(true);
  };

  return (
    <DashboardLayout>
      <Header challenge={user.team.challenge} />
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {t('workouts.edit.delete.title')}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {t('workouts.edit.delete.question')}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <MDButton onClick={handleClose}>{t('general.cancel')}</MDButton>
          <MDButton color="error" onClick={onDeleteConfirm}>{t('general.delete')}</MDButton>
        </DialogActions>
      </Dialog>
      <MDBox mb={3}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Card sx={{ overflow: 'visible' }}>
              <Workout workout={workout} onDeleteWorkout={onDelete} editWorkout />
            </Card>
          </Grid>
        </Grid>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}

export default EditWorkout;
