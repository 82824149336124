import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { fetchPost } from '../../../api';
import AddPost from './addPost';

function EditPost() {
  const { postId } = useParams();
  const [currentPost, setCurrentPost] = useState([]);

  useEffect(async () => {
    if (postId) {
      const result = await fetchPost(postId);
      setCurrentPost(result.data);
    }
  }, [postId]);

  return (
    <AddPost post={currentPost} />
  );
}

export default EditPost;
