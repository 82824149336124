import PropTypes from 'prop-types';

import Box from '@mui/material/Box';
import ProgressLine from './ProgressLine';

function ProgressCell({
  totalPoints, challengePoints, bonusPoints, maxPoints, showBonusPoints,
}) {
  const totalPointsPercentage = ((totalPoints / maxPoints) * 100).toFixed(0);
  const challengePointsPercentage = ((challengePoints / maxPoints) * 100).toFixed(0);
  const bonusPointsPercentage = ((bonusPoints / maxPoints) * 100).toFixed(0);

  let visualParts = [
    {
      percentage: `${totalPointsPercentage}%`,
      color: 'dodgerblue',
    },
  ];

  if (showBonusPoints) {
    visualParts = [
      {
        percentage: `${challengePointsPercentage}%`,
        color: 'dodgerblue',
      },
      {
        percentage: `${bonusPointsPercentage}%`,
        color: '#10d999',
      },
    ];
  }

  return (
    <Box>
      <ProgressLine
        label={totalPoints}
        backgroundColor="#F1F2F5"
        visualParts={visualParts}
      />
    </Box>
  );
}

ProgressCell.defaultProps = {
  totalPoints: 0,
  challengePoints: 0,
  bonusPoints: 0,
  maxPoints: 0,
  showBonusPoints: false,
};

ProgressCell.propTypes = {
  totalPoints: PropTypes.number,
  challengePoints: PropTypes.number,
  bonusPoints: PropTypes.number,
  maxPoints: PropTypes.number,
  showBonusPoints: PropTypes.bool,
};

export default ProgressCell;
