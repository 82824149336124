import PropTypes from 'prop-types';

import MDBox from 'components/MDBox';
import MDTypography from 'components/MDTypography';
import { Avatar, Badge } from '@mui/material';
import { Link } from 'react-router-dom';
import FavoriteIcon from '@mui/icons-material/Favorite';
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';

import medal1 from 'assets/challengize/images/medals/medal_1.png';
import medal2 from 'assets/challengize/images/medals/medal_2.png';
import medal3 from 'assets/challengize/images/medals/medal_3.png';
import medal4 from 'assets/challengize/images/medals/medal_4.png';
import medal5 from 'assets/challengize/images/medals/medal_5.png';
import medal6 from 'assets/challengize/images/medals/medal_6.png';
import medal7 from 'assets/challengize/images/medals/medal_7.png';
import medal8 from 'assets/challengize/images/medals/medal_8.png';
import medal9 from 'assets/challengize/images/medals/medal_9.png';
import medal10 from 'assets/challengize/images/medals/medal_10.png';
import { stringAvatar } from '../../../../utils';

function getMedal(numWorkouts) {
  if (!numWorkouts || numWorkouts < 10) {
    return null;
  }
  let src;
  switch (true) {
    case (numWorkouts < 20):
      src = medal1;
      break;
    case (numWorkouts < 30):
      src = medal2;
      break;
    case (numWorkouts < 40):
      src = medal3;
      break;
    case (numWorkouts < 50):
      src = medal4;
      break;
    case (numWorkouts < 60):
      src = medal5;
      break;
    case (numWorkouts < 70):
      src = medal6;
      break;
    case (numWorkouts < 80):
      src = medal7;
      break;
    case (numWorkouts < 90):
      src = medal8;
      break;
    case (numWorkouts < 100):
      src = medal9;
      break;
    default:
      src = medal10;
  }
  return <img src={src} width={30} height={30} alt="Medal" />;
}

function UserCell({
  image, name, id, approvedFriend, unapprovedFriend, workoutsInChallenge,
}) {
  return (
    <Link to={`/profile/${id}`}>
      <MDBox display="flex" alignItems="center">
        <MDBox mr={1}>
          {unapprovedFriend ? (
            <Badge
              overlap="circular"
              anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
              badgeContent={
                <FavoriteBorderIcon color="error" sx={{ marginLeft: '10px', fontSize: '14px' }} />
              }
            >
              <Avatar {...stringAvatar(name, 'sm')} src={image} />
            </Badge>
          ) : null}
          {approvedFriend && !unapprovedFriend ? (
            <Badge
              overlap="circular"
              anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
              badgeContent={
                <FavoriteIcon color="error" sx={{ marginLeft: '10px', fontSize: '14px' }} />
            }
            >
              <Avatar {...stringAvatar(name, 'sm')} src={image} />
            </Badge>
          ) : null}
          {!approvedFriend && !unapprovedFriend ? (
            <Avatar {...stringAvatar(name, 'sm')} src={image} />
          ) : null}
        </MDBox>
        {getMedal(workoutsInChallenge)}
        <MDTypography variant="button" fontWeight="medium" color="text">
          {name}
        </MDTypography>
      </MDBox>
    </Link>
  );
}

UserCell.defaultProps = {
  image: '',
  color: 'dark',
  name: '',
  approvedFriend: false,
  unapprovedFriend: false,
  workoutsInChallenge: 0,
};

UserCell.propTypes = {
  image: PropTypes.string,
  name: PropTypes.string,
  id: PropTypes.number.isRequired,
  workoutsInChallenge: PropTypes.number,
  approvedFriend: PropTypes.bool,
  unapprovedFriend: PropTypes.bool,
  color: PropTypes.oneOf([
    'transparent',
    'primary',
    'secondary',
    'info',
    'success',
    'warning',
    'error',
    'light',
    'dark',
  ]),
};

export default UserCell;
