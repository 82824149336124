import PropTypes from 'prop-types';
import Dialog from '@mui/material/Dialog';
import { DialogTitle } from '@mui/material';
import { useTranslation } from 'react-i18next';
import FirstSurvey from './firstSurvey';
import SecondSurvey from './secondSurvey';

function SurveyDialog({
  user, onClose, open, onSurveySubmit,
}) {
  const { t } = useTranslation();
  const { surveyStatus, firstName } = user;

  const handleClose = () => {
    onClose();
  };

  return (
    <Dialog open={open} maxWidth="md" fullWidth>
      <DialogTitle>
        {t('survey.hello', { firstName })}
      </DialogTitle>
      {surveyStatus === 0 ? (
        <FirstSurvey onSurveySubmit={onSurveySubmit} onClose={handleClose} user={user} />
      ) : (
        <SecondSurvey onSurveySubmit={onSurveySubmit} onClose={handleClose} user={user} />
      )}
    </Dialog>
  );
}

SurveyDialog.propTypes = {
  onClose: PropTypes.func.isRequired,
  onSurveySubmit: PropTypes.func.isRequired,
  open: PropTypes.bool,
  user: PropTypes.objectOf(
    PropTypes.oneOfType([PropTypes.array, PropTypes.object, PropTypes.number, PropTypes.string, PropTypes.bool]),
  ).isRequired,
};

SurveyDialog.defaultProps = {
  open: false,
};

export default SurveyDialog;
