/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
 */

// Material Dashboard 2 PRO React Base Styles
import borders from "assets/theme/base/borders";

// Material Dashboard 2 PRO React Helper Functions

const { borderRadius } = borders;

const cardMedia = {
  styleOverrides: {
    // root: {
    //   borderRadius: borderRadius.xl,
    //   margin: `${pxToRem(16)} ${pxToRem(16)} 0`,
    // },

    // media: {
    //   width: "auto",
    // },
  },
};

export default cardMedia;
