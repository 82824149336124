import { Paper } from '@mui/material';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { useTranslation } from 'react-i18next';
import MDBox from '../../components/MDBox';
import RegisterTeam from './registerTeam';
import { fetchTeams, fetchTeamUnits, registerTeam } from '../../api';

function Teams({ user }) {
  const { t } = useTranslation();
  const [teams, setTeams] = useState([]);
  const [teamUnits, setTeamUnits] = useState([]);

  const { unitChallenge } = user.team.challenge;

  const doFetchTeams = async () => {
    const response = await fetchTeams(user.team.challenge.id);
    setTeams(response.data);
  };

  const doFetchTeamUnits = async () => {
    const response = await fetchTeamUnits(user.team.challenge.id);
    setTeamUnits(response.data);
  };

  useEffect(async () => {
    if (user) {
      if (unitChallenge) {
        await doFetchTeamUnits();
      }
      await doFetchTeams();
    }
  }, [user]);

  const onRegisterTeam = async (data, reset) => {
    const { teamName, teamUnit } = data;
    const { id: teamUnitId } = teamUnit || [];
    const response = await registerTeam({ teamName, teamUnitId, challengeId: user.team.challenge.id });
    setTeams((oldTeams) => [response.data, ...oldTeams]);
    if (!unitChallenge) {
      reset();
    }
  };

  return (
    <MDBox>
      <RegisterTeam
        onRegisterTeam={onRegisterTeam}
        teamUnits={teamUnits}
        showTeamUnits={unitChallenge}
      />
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>{t('register.team')}</TableCell>
              {unitChallenge
                ? <TableCell align="left">{t('register.teamUnit')}</TableCell>
                : null}
            </TableRow>
          </TableHead>
          <TableBody>
            {teams.filter((team) => (team.active)).map((team) => (
              <TableRow
                key={team.id}
                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
              >
                <TableCell component="th" scope="row">
                  {team.teamName}
                </TableCell>
                {unitChallenge
                  ? <TableCell align="left">{team.teamUnit?.teamUnitName}</TableCell>
                  : null}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </MDBox>
  );
}

Teams.propTypes = {
  user: PropTypes.objectOf(
    PropTypes.oneOfType([PropTypes.array, PropTypes.object, PropTypes.number, PropTypes.string, PropTypes.bool]),
  ).isRequired,
};

export default Teams;
