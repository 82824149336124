import { useContext, useEffect, useState } from 'react';
import Footer from 'comps/Footer';
import {
  Box,
  Card, Grid, List, ListItem, ListItemButton,
} from '@mui/material';
import {
  collection, onSnapshot, query, where,
} from 'firebase/firestore';
import { useTranslation } from 'react-i18next';
import DashboardLayout from '../../LayoutContainers/DashboardLayout';
import { Context } from '../../store/Store';
import ChatContent from './chatContent';
import { getLocale } from '../../utils';
import { db } from './firestore';
import ChatItem from './chatItem';
import MDTypography from '../../components/MDTypography';

function Chat() {
  const { t } = useTranslation();
  const { state } = useContext(Context);
  const { user } = state;
  const [chats, setChats] = useState();
  const [selectedChat, setSelectedChat] = useState(null);

  const { id, language } = user;
  const locale = getLocale(language);

  useEffect(() => {
    if (user) {
      const q = query(collection(db, 'chats'), where('userIds', 'array-contains', id));
      const unsubscribe = onSnapshot(q, (snapshot) => {
        const result = [];
        snapshot.docs.forEach(async (chat) => {
          let data = chat.data();
          const userMap = new Map();
          data.users?.forEach((u) => {
            userMap.set(u.id, u);
          });
          data = { ...data, id: chat.id, userMap };
          result.push(data);
        });
        setChats(result);
      });
      return () => {
        unsubscribe();
      };
    }
    return null;
  }, [user]);

  return (
    <DashboardLayout>
      <Grid container spacing={2}>
        <Grid item xs={12} lg="auto">
          <Card sx={{ minWidth: 420 }}>
            <Box>
              <Box pt={2} pl={2} pb={1}>
                <MDTypography variant="h5">{t('chat.title')}</MDTypography>
              </Box>
              <List>
                {chats?.map((chat) => (
                  <ListItem
                    key={chat.id}
                    onClick={() => setSelectedChat(chat)}
                    sx={{ backgroundColor: selectedChat?.id === chat.id ? '#e2e8ed' : 'grey' }}
                  >
                    <ListItemButton>
                      <ChatItem user={user} locale={locale} chat={chat} isSelected={selectedChat?.id === chat.id} />
                    </ListItemButton>
                  </ListItem>
                ))}
              </List>
            </Box>
          </Card>
        </Grid>
        {selectedChat ? (
          <Grid item xs>
            <ChatContent user={user} locale={locale} chat={selectedChat} key={selectedChat && selectedChat.id} />
          </Grid>
        ) : null}
      </Grid>

      <Footer />
    </DashboardLayout>
  );
}

export default Chat;
