import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import DialogContent from '@mui/material/DialogContent';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import {
  Box,
  Button,
  Checkbox,
  FormGroup,
  Link,
  Stack,
  Step,
  StepLabel,
  Stepper,
  TextField,
  InputAdornment,
  Typography,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import MDTypography from '../../components/MDTypography';

const steps = ['survey.step1', 'survey.step2', 'survey.step3', 'survey.step4', 'survey.step5', 'survey.step6', 'survey.step7'];

function FirstSurvey({
  user, onClose, onSurveySubmit,
}) {
  const { t } = useTranslation();
  const [activeStep, setActiveStep] = useState(0);
  const [formIsValid, setFormIsValid] = useState(false);

  const [form, setState] = useState({
    approved: false,
    skipAge: false,
    skipHeight: false,
    skipWeight: false,
    age: {
      value: '',
      error: '',
    },
    weight: {
      value: '',
      error: '',
    },
    height: {
      value: '',
      error: '',
    },
    physics: 0,
    active: 0,
    wellBeing: 0,
    stress: 0,
    sleep: 0,
  });

  useEffect(() => {
    if (user) {
      setState({
        ...form,
        age: {
          value: user.age !== null ? user.age : '',
          error: '',
        },
        weight: {
          value: user.weight !== null ? user.weight : '',
          error: '',
        },
        height: {
          value: user.height !== null ? user.height : '',
          error: '',
        },
      });
    }
  }, [user]);

  const handleClose = () => {
    onClose();
  };

  const validateForm = (step) => {
    if (step === 0) {
      return setFormIsValid(form.approved);
    }
    if (step === 1) {
      const isValid = (form.skipAge || (form.age.value > 6 && form.age.value < 121 && form.age.error === ''))
        && (form.skipWeight || (form.weight.value > 19 && form.weight.value < 301 && form.weight.error === ''))
        && (form.skipHeight || (form.height.value > 39 && form.height.value < 301 && form.height.error === ''));
      return setFormIsValid(isValid);
    }
    if (step === 2) {
      return setFormIsValid(form.physics > 0);
    }
    if (step === 3) {
      return setFormIsValid(form.active > 0);
    }
    if (step === 4) {
      return setFormIsValid(form.wellBeing > 0);
    }
    if (step === 5) {
      return setFormIsValid(form.stress > 0);
    }
    if (step === 6) {
      return setFormIsValid(form.sleep > 0);
    }
    return setFormIsValid(false);
  };

  useEffect(() => {
    validateForm(activeStep);
  }, [form, activeStep]);

  const handleNext = async () => {
    if (activeStep === 6) {
      const survey = {
        age: form.age.value,
        weight: form.weight.value,
        height: form.height.value,
        healthLevel: form.wellBeing,
        physicLevel: form.physics,
        stateLevel: form.active,
        stressLevel: form.stress,
        sleepLevel: form.sleep,
      };

      return onSurveySubmit(survey);
    }
    return setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    if (activeStep === 0) {
      return handleClose();
    }
    return setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const updateAge = (e) => {
    if (e.target.value === '' || e.target.value < 7 || e.target.value > 120) {
      return setState({
        ...form,
        age: {
          value: e.target.value,
          error: t('survey.age.invalid'),
        },
      });
    }

    return setState({
      ...form,
      age: {
        value: Number(e.target.value).toFixed(0),
        error: '',
      },
    });
  };

  const updateWeight = (e) => {
    if (e.target.value === '' || e.target.value < 20 || e.target.value > 300) {
      return setState({
        ...form,
        weight: {
          value: e.target.value,
          error: t('survey.weight.invalid'),
        },
      });
    }

    return setState({
      ...form,
      weight: {
        value: Number(e.target.value).toFixed(0),
        error: '',
      },
    });
  };

  const updateHeight = (e) => {
    if (e.target.value === '' || e.target.value < 40 || e.target.value > 300) {
      return setState({
        ...form,
        height: {
          value: e.target.value,
          error: t('survey.height.invalid'),
        },
      });
    }

    return setState({
      ...form,
      height: {
        value: Number(e.target.value).toFixed(0),
        error: '',
      },
    });
  };

  const updateRadio = (e) => {
    setState({
      ...form,
      [e.target.name]: e.target.value,
    });
  };

  const updateCheckbox = (e) => {
    setState({
      ...form,
      [e.target.name]: e.target.checked,
    });
  };

  const toggleSkipAge = (e) => {
    setState({
      ...form,
      [e.target.name]: e.target.checked,
      age: {
        value: '',
        error: '',
      },
    });
  };

  const toggleSkipWeight = (e) => {
    setState({
      ...form,
      [e.target.name]: e.target.checked,
      weight: {
        value: '',
        error: '',
      },
    });
  };

  const toggleSkipHeight = (e) => {
    setState({
      ...form,
      [e.target.name]: e.target.checked,
      height: {
        value: '',
        error: '',
      },
    });
  };

  const renderRadio = (title, name, value) => (
    <Box display="flex" alignItems="center" justifyContent="center" textAlign="center" sx={{ height: 300 }}>
      <FormControl>
        <FormLabel id={`${name}-label`}>{title}</FormLabel>
        <Typography variant="subtitle2" color="text">{t('survey.explanation')}</Typography>
        <RadioGroup
          row
          aria-labelledby={`${name}-label`}
          name={name}
          onChange={updateRadio}
          value={value}
        >
          <FormControlLabel value="1" control={<Radio />} label="1" labelPlacement="bottom" />
          <FormControlLabel value="2" control={<Radio />} label="2" labelPlacement="bottom" />
          <FormControlLabel value="3" control={<Radio />} label="3" labelPlacement="bottom" />
          <FormControlLabel value="4" control={<Radio />} label="4" labelPlacement="bottom" />
          <FormControlLabel value="5" control={<Radio />} label="5" labelPlacement="bottom" />
          <FormControlLabel value="6" control={<Radio />} label="6" labelPlacement="bottom" />
          <FormControlLabel value="7" control={<Radio />} label="7" labelPlacement="bottom" />
          <FormControlLabel value="8" control={<Radio />} label="8" labelPlacement="bottom" />
          <FormControlLabel value="9" control={<Radio />} label="9" labelPlacement="bottom" />
          <FormControlLabel value="10" control={<Radio />} label="10" labelPlacement="bottom" />
        </RadioGroup>
      </FormControl>
    </Box>
  );

  const renderInfo = () => {
    if (user && user.surveyStatus === 0) {
      return (
        <>
          <MDTypography variant="body2" gutterBottom>{t('survey.info1.1')}</MDTypography>
          <MDTypography variant="body2" gutterBottom>{t('survey.info2.1')}</MDTypography>
          <MDTypography variant="body2" gutterBottom>{t('survey.info3.1')}</MDTypography>
        </>
      );
    }
    if (user && user.surveyStatus === 1) {
      return (
        <>
          <MDTypography variant="body2" gutterBottom>{t('survey.info1.2')}</MDTypography>
          <MDTypography variant="body2" gutterBottom>{t('survey.info2.2')}</MDTypography>
        </>
      );
    }
    return (
      <>
        <MDTypography variant="body2" gutterBottom>{t('survey.info1.3')}</MDTypography>
        <MDTypography variant="body2" gutterBottom>{t('survey.info2.3')}</MDTypography>
      </>
    );
  };

  return (
    <DialogContent>
      <Stepper activeStep={activeStep} alternativeLabel sx={{ marginTop: 2 }}>
        {steps.map((label) => {
          const stepProps = {};
          const labelProps = {};
          return (
            <Step key={label} {...stepProps}>
              <StepLabel {...labelProps}>{t(label)}</StepLabel>
            </Step>
          );
        })}
      </Stepper>

      <Box sx={{ pt: 3 }}>
        {activeStep === 0 ? (
          <Box sx={{ height: 300 }}>
            {renderInfo()}
            <Stack direction="column" spacing={2} sx={{ paddingTop: 4 }}>
              <Link href="https://go.challengize.com/personal-data/" target="_blank" variant="h6">{t('survey.policy')}</Link>
              <FormGroup>
                <FormControlLabel
                  control={(
                    <Checkbox
                      name="approved"
                      checked={form.approved}
                      onChange={updateCheckbox}
                    />
              )}
                  label={t('survey.approve')}
                />
              </FormGroup>
            </Stack>
          </Box>
        ) : null}
        {activeStep === 1 ? (
          <Box display="flex" sx={{ height: 300, paddingTop: 15, justifyContent: 'space-between' }}>
            <div>
              <TextField
                name="age"
                label={t('survey.your.age')}
                value={form.age.value}
                onChange={updateAge}
                error={form.age.error !== ''}
                helperText={form.age.error !== '' ? form.age.error : null}
                disabled={form.skipAge}
                InputProps={{
                  type: 'number',
                  endAdornment:
            <InputAdornment position="end">
              <MDTypography style={{ fontSize: 16 }}>{t('survey.year')}</MDTypography>
            </InputAdornment>,
                }}
              />
              <FormGroup>
                <FormControlLabel
                  control={(
                    <Checkbox
                      name="skipAge"
                      checked={form.skipAge}
                      onChange={toggleSkipAge}
                    />
                )}
                  label={t('survey.skip')}
                />
              </FormGroup>
            </div>

            <div>
              <TextField
                name="weight"
                label={t('survey.your.weight.mandatory')}
                value={form.weight.value}
                onChange={updateWeight}
                error={form.weight.error !== ''}
                helperText={form.weight.error !== '' ? form.weight.error : null}
                disabled={form.skipWeight}
                InputProps={{
                  type: 'number',
                  endAdornment:
            <InputAdornment position="end">
              <MDTypography style={{ fontSize: 16 }}>kg</MDTypography>
            </InputAdornment>,
                }}
              />
              <FormGroup>
                <FormControlLabel
                  control={(
                    <Checkbox
                      name="skipWeight"
                      checked={form.skipWeight}
                      onChange={toggleSkipWeight}
                    />
                )}
                  label={t('survey.skip')}
                />
              </FormGroup>
            </div>

            <div>
              <TextField
                name="height"
                label={t('survey.your.height')}
                value={form.height.value}
                onChange={updateHeight}
                error={form.height.error !== ''}
                helperText={form.height.error !== '' ? form.height.error : null}
                disabled={form.skipHeight}
                InputProps={{
                  type: 'number',
                  endAdornment:
            <InputAdornment position="end">
              <MDTypography style={{ fontSize: 16 }}>cm</MDTypography>
            </InputAdornment>,
                }}
              />
              <FormGroup>
                <FormControlLabel
                  control={(
                    <Checkbox
                      name="skipHeight"
                      checked={form.skipHeight}
                      onChange={toggleSkipHeight}
                    />
                  )}
                  label={t('survey.skip')}
                />
              </FormGroup>
            </div>

          </Box>
        ) : null}
        {activeStep === 2 ? renderRadio(t('survey.question1'), 'physics', form.physics) : null}
        {activeStep === 3 ? renderRadio(t('survey.question2'), 'active', form.active) : null}
        {activeStep === 4 ? renderRadio(t('survey.question3'), 'wellBeing', form.wellBeing) : null}
        {activeStep === 5 ? renderRadio(t('survey.question4'), 'stress', form.stress) : null}
        {activeStep === 6 ? renderRadio(t('survey.question5'), 'sleep', form.sleep) : null}

      </Box>
      <Box sx={{ display: 'flex', flexDirection: 'row', pt: 3 }}>
        <Button
          color="inherit"
          onClick={handleBack}
          sx={{ mr: 1 }}
        >
          {activeStep === 0 ? t('general.cancel') : t('survey.back')}
        </Button>
        <Box sx={{ flex: '1 1 auto' }} />

        <Button
          disabled={!formIsValid}
          onClick={handleNext}
        >
          {activeStep === steps.length - 1 ? t('survey.finish') : t('survey.next')}
        </Button>
      </Box>
    </DialogContent>
  );
}

FirstSurvey.propTypes = {
  onClose: PropTypes.func.isRequired,
  onSurveySubmit: PropTypes.func.isRequired,
  user: PropTypes.objectOf(
    PropTypes.oneOfType([PropTypes.array, PropTypes.object, PropTypes.number, PropTypes.string, PropTypes.bool]),
  ).isRequired,
};

export default FirstSurvey;
