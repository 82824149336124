/* eslint-disable react/prop-types */
/* eslint-disable react/no-unstable-nested-components */

import {
  useContext, useEffect, useState, useMemo,
} from 'react';
import MDBox from 'components/MDBox';
import Card from '@mui/material/Card';
import { useNavigate } from 'react-router-dom';
import Footer from 'comps/Footer';
import { useTranslation } from 'react-i18next';
import Box from '@mui/material/Box';
import { format } from 'date-fns';
import { utcToZonedTime } from 'date-fns-tz';
import IconButton from '@mui/material/IconButton';
import EditIcon from '@mui/icons-material/Edit';
import Icon from '@mui/material/Icon';
import Tooltip from '@mui/material/Tooltip';
import { useQuery } from 'react-query';
import { STALE_TIME } from 'config';
import MDTypography from '../../components/MDTypography';
import DataTable from './DataTable/DataTable';
import { fetchActivities, fetchWorkouts } from '../../api';
import { Context } from '../../store/Store';
import Header from '../header';
import DashboardLayout from '../../LayoutContainers/DashboardLayout';
import ColumnHeader from '../../comps/ColumnHeader';
import { findAndTranslateActivity } from '../../utils';

const formatDuration = (seconds) => {
  const hours = Math.floor(seconds / 3600);
  const minutes = Math.floor((seconds / 60) - (hours * 60));
  return `${hours}h ${minutes}m`;
};

const renderStatus = (valid, invalidReason) => {
  if (valid) {
    return (
      <Icon fontSize="small" sx={{ mt: -0.25, color: '#4CAF50' }}>
        checkCircleOutline
      </Icon>
    );
  }
  return (
    <Tooltip title={invalidReason}>
      <Icon fontSize="small" sx={{ mt: -0.25, color: '#F44335' }}>
        errorOutline
      </Icon>
    </Tooltip>
  );
};

function MyWorkouts() {
  const { t } = useTranslation();
  const { state } = useContext(Context);
  const { user } = state;
  const [workouts, setWorkouts] = useState([]);
  const navigate = useNavigate();
  const { data: activityData } = useQuery(
    ['activities', user.language],
    () => fetchActivities(),
    {
      staleTime: STALE_TIME.ONE_HOUR,
    },
  );
  const { activities } = activityData || [];
  const cols = useMemo(() => [
    {
      Header: <ColumnHeader label="general.activity" />,
      accessor: 'activity',
      Cell: ({ row }) => (
        <>
          <Box sx={{ textTransform: 'uppercase', whiteSpace: 'nowrap' }}>
            {findAndTranslateActivity(row.original.activity, activities)}
          </Box>
          {(row.original.bonusActivity ? (
            <Box sx={{ textTransform: 'uppercase', whiteSpace: 'nowrap' }}>
              {t('social.bonus_activity')}
            </Box>
          ) : null)}
        </>
      ),
    },
    {
      Header: 'CPS',
      accessor: 'challengePoints',
      width: 160,
      align: 'right',
    },
    {
      Header: <ColumnHeader label="general.distance" />,
      accessor: 'distance',
      width: 120,
      align: 'right',
      Cell: ({ row }) => (
        <Box sx={{ whiteSpace: 'nowrap' }}>
          {row.original.distance === 0 ? 'N/A' : `${(row.original.distance / 1000).toFixed(2)} km`}
        </Box>
      ),
    },
    {
      Header: <ColumnHeader label="general.duration" />,
      accessor: 'duration',
      width: 120,
      Cell: ({ row }) => (
        <Box sx={{ whiteSpace: 'nowrap' }}>
          {formatDuration(row.original.duration)}
        </Box>
      ),
    },
    {
      Header: <ColumnHeader label="general.startTime" />,
      accessor: 'startTime',
      Cell: ({ timezone, row }) => (
        <Box sx={{ whiteSpace: 'nowrap' }}>
          {format(utcToZonedTime(row.original.startTime, timezone), 'yyyy-MM-dd HH:mm')}
        </Box>
      ),
    },
    {
      Header: <ColumnHeader label="general.endTime" />,
      accessor: 'endTime',
      Cell: ({ timezone, row }) => (
        <Box sx={{ whiteSpace: 'nowrap' }}>
          {format(utcToZonedTime(row.original.endTime, timezone), 'yyyy-MM-dd HH:mm')}
        </Box>
      ),
    },
    {
      Header: 'Tracker',
      accessor: 'tracker',
      Cell: ({ row }) => (
        <Box sx={{ whiteSpace: 'nowrap', textTransform: 'uppercase' }}>
          {row.original.tracker}
        </Box>
      ),
    },
    {
      Header: 'Status',
      accessor: 'valid',
      align: 'center',
      Cell: ({ row }) => (
        <Box sx={{ whiteSpace: 'nowrap' }}>
          {renderStatus(row.original.valid, row.original.invalidReason)}
        </Box>
      ),
    },
    {
      Header: <ColumnHeader label="general.edit" />,
      accessor: 'action',
      align: 'center',
      Cell: ({ onEdit, row }) => {
        const { id } = row.original;
        return (
          <IconButton
            color="info"
            disabled={!row.original.deletable && (!row.original.editable || row.original.tracker !== 'challengize')}
            onClick={() => onEdit(id)}
          >
            <EditIcon />
          </IconButton>
        );
      },
    },
  ], [activities]);

  const table = { columns: cols, rows: workouts };

  const doFetchWorkouts = async () => {
    const response = await fetchWorkouts(user.id);
    setWorkouts(response);
  };

  useEffect(async () => {
    await doFetchWorkouts();
  }, []);

  const onEdit = (id) => {
    navigate(`/workouts/editWorkout/${id}`);
  };

  return (
    <DashboardLayout>
      <Header challenge={user.team.challenge} />
      <MDBox mb={3}>
        <Card sx={{ overflow: 'visible' }}>
          <MDBox p={3}>
            <MDTypography variant="h5">{t('workouts.my.title')}</MDTypography>
          </MDBox>
          <MDBox pb={1}>
            <DataTable
              table={table}
              onEdit={onEdit}
              timezone={user.timezone}
            />
          </MDBox>
        </Card>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}

export default MyWorkouts;
